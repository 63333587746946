import { type } from "@testing-library/user-event/dist/type";
import HotelActionTypes from "./hotel.types";

export const getFullHotelDetailsRequest = (hotelData) => ({
  type: HotelActionTypes.GET_FULL_HOTELS_DETAILS_REQUEST,
  payload: hotelData,
});

export const getFullHotelDetailsSuccess = (hotelData) => ({
  type: HotelActionTypes.GET_FULL_HOTELS_DETAILS_SUCCESS,
  payload: hotelData,
});

export const getFullHotelDetailsFailure = (error) => ({
  type: HotelActionTypes.GET_FULL_HOTELS_DETAILS_FAILED,
  payload: error,
});

export const getHotelListRequest = (hotelData) => ({
  type: HotelActionTypes.GET_HOTEL_LIST_SEARCH_START,
  payload: hotelData,
});

export const getHotelListByPagination = (hotelData) => ({
  type: HotelActionTypes.GET_HOTEL_PAGINATION_LIST_SEARCH_START,
  payload: hotelData,
});

export const getFullHotelListSuccess = (hotelList) => ({
  type: HotelActionTypes.GET_HOTEL_LIST_SEARCH_SUCCESS,
  payload: hotelList,
});

export const getTotalHotelListCount = (hotelCount) => ({
  type: HotelActionTypes.HOTEL_TOTAL_LIST_COUNT,
  payload: hotelCount,
});

export const getFullHotelListFailure = (error) => ({
  type: HotelActionTypes.GET_HOTEL_LIST_SEARCH_FAILURE,
  payload: error,
});

export const getHotelAnimityStart = (data) => ({
  type: HotelActionTypes.GET_HOTEL_ANIMITY_LIST_START,
  payload: data,
});

export const getHotelAnimitySuccess = (data) => ({
  type: HotelActionTypes.GET_HOTEL_ANIMITY_LIST_SUCCESS,
  payload: data,
});

export const getHotelAnimityError = (error) => ({
  type: HotelActionTypes.GET_HOTEL_ANIMITY_LIST_FAILURE,
  payload: error,
});

export const addFavouriteHotelRequest = (favouriteHotel) => ({
  type: HotelActionTypes.ADD_TO_FAVOURITE_HOTEL_REQUEST,
  payload: favouriteHotel,
});

export const addFavouriteHotelSuccess = (favouriteHotel) => ({
  type: HotelActionTypes.ADD_TO_FAVOURITE_HOTEL_SUCCESS,
  payload: favouriteHotel,
});

export const addFavouriteHotelFailure = (error) => ({
  type: HotelActionTypes.ADD_TO_FAVOURITE_HOTEL_FAILED,
  payload: error,
});

export const removeFavouriteHotelRequest = (favouriteHotel) => ({
  type: HotelActionTypes.REMOVE_TO_FAVOURITE_HOTEL_REQUEST,
  payload: favouriteHotel,
});

export const removeFavouriteHotelSuccess = (favouriteHotel) => ({
  type: HotelActionTypes.REMOVE_TO_FAVOURITE_HOTEL_SUCCESS,
  payload: favouriteHotel,
});

export const removeFavouriteHotelFailure = (error) => ({
  type: HotelActionTypes.REMOVE_TO_FAVOURITE_HOTEL_FAILED,
  payload: error,
});

export const getAllFavouriteHotelListRequest = (favouriteHotel) => ({
  type: HotelActionTypes.GET_ALL_FAVOURITE_HOTEL_LIST_REQUEST,
  payload: favouriteHotel,
});

export const getAllFavouriteHotelListSuccess = (favouriteHotel) => ({
  type: HotelActionTypes.GET_ALL_FAVOURITE_HOTEL_LIST_SUCCESS,
  payload: favouriteHotel,
});

export const getAllFavouriteHotelListFailure = (error) => ({
  type: HotelActionTypes.GET_ALL_FAVOURITE_HOTEL_LIST_SUCCESS,
  payload: error,
});

export const searchDataSave = (data) => ({
  type: HotelActionTypes.STATE_AFTER_HOTEL_SEARCH_DATA_SAVE,
  payload: data,
});

export const searchDataRemove = (data) => ({
  type: HotelActionTypes.STATE_AFTER_HOTEL_SEARCH_DATA_REMOVE,
});

export const searchFilterDataSave = (data) => ({
  type: HotelActionTypes.STATE_SEARCH_ALL_SAVE_FILTER_DATA,
  payload: data,
});

/************ Bid Checkout Details     *************** */
export const getBidCheckoutDetailsRequest = (bidCheckoutDetails) => ({
  type: HotelActionTypes.GET_BID_CHECKOUT_DETAILS_REQUEST,
  payload: bidCheckoutDetails,
});

export const getBidCheckoutDetailsSuccess = (bidCheckoutDetails) => ({
  type: HotelActionTypes.GET_BID_CHECKOUT_DETAILS_SUCCESS,
  payload: bidCheckoutDetails,
});

export const getBidCheckoutDetailsFailure = (error) => ({
  type: HotelActionTypes.GET_BID_CHECKOUT_DETAILS_FAILED,
  payload: error,
});

/************ Hourly Checkout Details     *************** */
export const getHourlyCheckoutDetailsRequest = (hourlyCheckoutDetails) => ({
  type: HotelActionTypes.GET_HOURLY_CHECKOUT_DETAILS_REQUEST,
  payload: hourlyCheckoutDetails,
});

export const getHourlyCheckoutDetailsSuccess = (hourlyCheckoutDetails) => ({
  type: HotelActionTypes.GET_HOURLY_CHECKOUT_DETAILS_SUCCESS,
  payload: hourlyCheckoutDetails,
});

export const getHourlyCheckoutDetailsFailure = (error) => ({
  type: HotelActionTypes.GET_HOURLY_CHECKOUT_DETAILS_FAILED,
  payload: error,
});

export const stateClearAfterAdd = () => ({
  type: HotelActionTypes.STATE_CLEAR_AFTER_HOTEL_LIST_FAVRITE_MSG,
});

export const startsearchStartafterHittingSameRoute = () => ({
  type: HotelActionTypes.STATE_SEARCH_START_AFTER_CLICK_ON_BUTTON,
});

export const startsearchStopafterHittingSameRoute = () => ({
  type: HotelActionTypes.STATE_SEARCH_STOP_AFTER_CLICK_ON_BUTTON,
});

/************ Hotel Booking     *************** */
export const hotelBookingRequest = (hotelBooking) => ({
  type: HotelActionTypes.HOTEL_BOOKING_REQUEST,
  payload: hotelBooking,
});

export const hotelBookingSuccess = (hotelBooking) => ({
  type: HotelActionTypes.HOTEL_BOOKING_SUCCESS,
  payload: hotelBooking,
});

export const hotelBookingFailure = (error) => ({
  type: HotelActionTypes.HOTEL_BOOKING_FAILED,
  payload: error,
});

/************ Apply Wallet     *************** */
export const applyWalletRequest = (applyWallet) => ({
  type: HotelActionTypes.APPLY_WALLET_REQUEST,
  payload: applyWallet,
});

export const applyWalletSuccess = (applyWallet) => ({
  type: HotelActionTypes.APPLY_WALLET_SUCCESS,
  payload: applyWallet,
});

export const applyWalletFailure = (error) => ({
  type: HotelActionTypes.APPLY_WALLET_FAILED,
  payload: error,
});

/************ Apply Promo Code     *************** */
export const applyPromoCodeRequest = (applyPromoCode) => ({
  type: HotelActionTypes.APPLY_PROMO_CODE_REQUEST,
  payload: applyPromoCode,
});

export const applyPromoCodeSuccess = (applyPromoCode) => ({
  type: HotelActionTypes.APPLY_PROMO_CODE_SUCCESS,
  payload: applyPromoCode,
});

export const applyPromoCodeFailure = (error) => ({
  type: HotelActionTypes.APPLY_PROMO_CODE_FAILED,
  payload: error,
});

/************ Bid Your Amount    *************** */
export const bidYourAmountRequest = (bidYourAmount) => ({
  type: HotelActionTypes.BID_YOUR_AMOUNT_REQUEST,
  payload: bidYourAmount,
});

export const bidYourAmountSuccess = (bidYourAmount) => ({
  type: HotelActionTypes.BID_YOUR_AMOUNT_SUCCESS,
  payload: bidYourAmount,
});

export const bidYourAmountFailure = (error) => ({
  type: HotelActionTypes.BID_YOUR_AMOUNT_FAILED,
  payload: error,
});

/************ Apply Hotel Offers    *************** */
export const applyHotelOffersRequest = (applyHotelOffers) => ({
  type: HotelActionTypes.APPLY_HOTEL_OFFERS_REQUEST,
  payload: applyHotelOffers,
});

export const applyHotelOffersSuccess = (applyHotelOffers) => ({
  type: HotelActionTypes.APPLY_HOTEL_OFFERS_SUCCESS,
  payload: applyHotelOffers,
});

export const applyHotelOffersFailure = (error) => ({
  type: HotelActionTypes.APPLY_HOTEL_OFFERS_FAILED,
  payload: error,
});

/********************** Hotel Booking List *****************/

export const hotelBookingHistoryRequest = (data) => ({
  type: HotelActionTypes.HOTEL_BOOKING_LIST_DATA_FETCH,
  payload: data,
});

export const hotelBookingHistorySuccess = (data) => ({
  type: HotelActionTypes.HOTEL_BOOKING_LIST_DATA_SUCCESS,
  payload: data,
});

export const hotelBookingHistoryFailure = (data) => ({
  type: HotelActionTypes.HOTEL_BOOKING_LIST_DATA_FAILURE,
  payload: data,
});

/* PLACE WEATHER API REDUX WORKING  */
export const hotelWheatherApiRequest = (data) => ({
  type: HotelActionTypes.HOTEL_WHEATHER_API_REQUEST,
  payload: data,
});

export const hotelWheatherApiSuccess = (data) => ({
  type: HotelActionTypes.HOTEL_WHEATHER_API_SUCCESS,
  payload: data,
});

export const hotelWheatherApiFailure = (data) => ({
  type: HotelActionTypes.HOTEL_WHEATHER_API_FAILURE,
  payload: data,
});

export const hotelWheatherApiDataRemove = (data) => ({
  type: HotelActionTypes.HOTEL_WHEATHER_API_DATA_REMOVE,
  payload: data,
});


/* PLACE WEATHER API REDUX WORKING  */
/******** Toggle Filter add or not ********/
export const hotelToggleAddOrNot = (data) => ({
  type: HotelActionTypes.HOTEL_FILTER_ADDED_OR_NOT_HANDLE,
  payload: data,
});

/******** Toggle Filter add or not ********/

/* PLACE WEATHER API REDUX WORKING  */

export const stateClearAfterTask = () => ({
  type: HotelActionTypes.STATE_CLEAR_AFTER_TASK,
});

/* PLACE WEATHER API REDUX WORKING  */

export const placeStateDataSave = (data) => ({
  type: HotelActionTypes.HOTEL_SEARCH_STATE_SAVE,
  payload: data,
});

/***** Save checkout guest User Form Data   ***/
export const saveGuestUserCheckoutDataRequest = (
  saveGuestUserCheckoutData
) => ({
  type: HotelActionTypes.SAVE_GUEST_USER_CHECKOUT_DATA,
  payload: saveGuestUserCheckoutData,
});

/************ HOTEL RE-SEND CONFIRMATION    *************** */
export const hotelResendConfirmationRequest = (hotelResendConfirmation) => ({
  type: HotelActionTypes.HOTEL_RESEND_CONFIRMATION_REQUEST,
  payload: hotelResendConfirmation,
});

export const hotelResendConfirmationSuccess = (hotelResendConfirmation) => ({
  type: HotelActionTypes.HOTEL_RESEND_CONFIRMATION_SUCCESS,
  payload: hotelResendConfirmation,
});

export const hotelResendConfirmationFailure = (error) => ({
  type: HotelActionTypes.HOTEL_RESEND_CONFIRMATION_FAILED,
  payload: error,
});

/*********** HOTEL ROUTE STATE HANDLER *****************/

export const hotelRouteStateHandler = (request) => ({
  type: HotelActionTypes.HOTEL_ROUTE_STATE_HANDLE_REQUEST,
  payload: request,
});

/*********** HOTEL ROUTE STATE HANDLER *****************/

export const hotelRouteDetailsStateHandler = (request) => ({
  type: HotelActionTypes.HOTEL_ROUTE_STATE_HANDLE_REQUEST,
  payload: request,
});

export const hotelPlaceDataHandle = (request) => ({
  type: HotelActionTypes.HOTEL_PLACE_DATA_STATE_HANDLE,
  payload: request,
});
export const hotelOfferHandlerDataList = (request) => ({
  type: HotelActionTypes.HOTEL_OFFERS_HANDLER_STATE,
  payload: request,
});
export const hotelOfferremoveHandlerDataList = (request) => ({
  type: HotelActionTypes.HOTEL_OFFERS_REMOVE_HANDLER_STATE,
  payload: request,
});
  /************ Hotel Offers Handler ***************/

  /************ Hotel Offers Handler ***************/


  /*********** GET HOTEL PERFERENCE LIST  **********/
  export const hotelPerferenceListHandler = (request) => ({
    type: HotelActionTypes.HOTEL_PERFERENCE_API_REQUEST,
    payload: request,
  });
  export const hotelPerferenceListHandleSuccess = (data) => ({
    type: HotelActionTypes.HOTEL_PERFERENCE_API_SUCCESS,
    payload: data,
  });
  export const hotelPerferenceListDataListFailure = (error) => ({
    type: HotelActionTypes.HOTEL_PERFERENCE_API_FAILURE,
    payload: error,
  });
  /*********** GET HOTEL PERFERENCE LIST  **********/
  
  /*********** GET HOTEL GEOLOCATION ADDED  **********/
  export const hotelGeoLocationAdded = (data) => ({
    type: HotelActionTypes.HOTEL_GETLOCATION_UPLOADED,
    payload: data,
  });
  /*********** GET HOTEL GEOLOCATION ADDED  **********/

  /********** HOTEL LIST UPDATE AFTER ADD FAVORITE OR REMOVE FAVORITE *********/
  export const hotelListUpdateAfterAddandRemove = (data) => ({
    type: HotelActionTypes.GET_HOTEL_LIST_UPDATE_AFTER_ADD_OR_REMOVE_FAVORITE,
    payload: data,
  });

  /********** HOTEL LIST UPDATE AFTER ADD FAVORITE OR REMOVE FAVORITE *********/
  /******* HOTEL RATING AND REVIEW REQUEST SUBMIT  *******/
  export const hotelBookingListAddReview = (data) => ({
    type: HotelActionTypes.POST_HOTEL_RATING_REVIEW_REQUEST,
    payload: data,
  });
  /******* HOTEL RATING AND REVIEW REQUEST SUBMIT  *******/
  /******* HOTEL RATING AND REVIEW REQUEST SUCCESS  *******/
  export const hotelBookingListAddReviewSuccess = (data) => ({
    type: HotelActionTypes.POST_HOTEL_RATING_REVIEW_SUCCESS,
    payload: data,
  });
  /******* HOTEL RATING AND REVIEW REQUEST SUCCESS  *******/
  /******* HOTEL RATING AND REVIEW REQUEST FAILURE  *******/
  export const hotelBookingListAddReviewFailure = (data) => ({
    type: HotelActionTypes.POST_HOTEL_RATING_REVIEW_FAILURE,
    payload: data,
  });
  /******* HOTEL RATING AND REVIEW REQUEST FAILURE  *******/
 
  /******* HOTEL Booking Details REQUEST  *******/
  export const hotelBookingListRequest = (data) => ({
    type: HotelActionTypes.HOTEL_BOOKING_DETAILS_REQUEST,
    payload: data,
  });
  /******* HOTEL Booking Details REQUEST Success *******/
  export const hotelBookingListSuccess = (data) => ({
    type: HotelActionTypes.HOTEL_BOOKING_DETAILS_SUCCESS,
    payload: data,
  });
  /******* HOTEL Booking Details REQUEST Success *******/


 
  /********* Cancel Bidding Request **********/
  export const hotelBiddingCancelationRequest = (data) => ({
  type:HotelActionTypes.HOTEL_BIDDING_CENCEL_REQUEST,
  payload:data  
  });
  /********* Cancel Bidding Request **********/
  /********* Cancel Bidding Success **********/
  export const hotelBiddingCancelationSuccess = (data) => ({
  type:HotelActionTypes.HOTEL_BIDDING_CENCEL_SUCCESS,
  payload:data  
  });
  /********* Cancel Bidding Success **********/
  /********* Cancel Bidding Success **********/
  export const hotelBiddingCancelationFailure = (data) => ({
  type:HotelActionTypes.HOTEL_BIDDING_CENCEL_SUCCESS,
  payload:data  
  });
  /********* Cancel Bidding Success **********/
  /************** HOTEL STAR API CALL REQUEST **************/
  export const hotelStarFilterApiCallRequest = (data) => ({
  type:HotelActionTypes.HOTEL_STAR_API_FILTER_REQUEST,
  payload:data  
  });
  /************** HOTEL STAR API CALL REQUEST **************/
  /************** HOTEL STAR API CALL REQUEST **************/
  export const hotelStarFilterApiCallSuccess = (data) => ({
  type:HotelActionTypes.HOTEL_STAR_API_FILTER_SUCCESS,
  payload:data  
  });
  /************** HOTEL STAR API CALL REQUEST **************/
  /************** HOTEL STAR API CALL REQUEST **************/
  export const hotelStarFilterApiCallFailure = (data) => ({
  type:HotelActionTypes.HOTEL_STAR_API_FILTER_FAILURE,
  payload:data  
  });
  /************** HOTEL STAR API CALL REQUEST **************/

