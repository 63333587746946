import React from "react";
import { Dropdown, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { GrFacebookOption } from "react-icons/gr";
import { GrTwitter } from "react-icons/gr";
import { GrLinkedinOption } from "react-icons/gr";
import { GrInstagram } from "react-icons/gr";
import { GrYoutube } from "react-icons/gr";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  getMyReferralsDetailsStart,
  getMyReferralsListStart,
} from "../../redux/useraccount/useraccount.actions";
import {
  selectMyReferralsDetails,
  selectMyReferralsList,
} from "../../redux/useraccount/useraccount.selectors";
import {
  selectUserLoginData,
  selectSocialLoginUser,
} from "../../redux/user/user.selectors";
import { selectlanguageToShow } from "../../redux/language/language.selectors";
import ShareModal from "../../utils/ShareModal";
import TablePagination from "@mui/material/TablePagination";
import { Button } from "react-bootstrap";
import Pagination from "@mui/material/Pagination";
import { useTranslation } from "react-i18next";
import MyAccountLeftMenu from "../../components/MyAccount/MyAccountLeftMenu";
import { Helmet } from "react-helmet";

const MyreferralsPage = ({
  myReferralsDetails,
  myReferralsList,
  getMyReferralsDetailsStart,
  getMyReferralsListStart,
  languageToShow,
  userAuthData,
}) => {
  const [t] = useTranslation("common");
  const [openSharePopup, setOpenSharePopup] = React.useState(false);
  const [isCopied, setIsCopied] = React.useState(false);
  const [shareCode, setShareCode] = React.useState();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const perPageLimit = 5;

  const data = {
    languageToShow: languageToShow,
    token: userAuthData != null ? userAuthData.token : "",
    skip: page,
    limit: perPageLimit,
  };



  React.useEffect(() => {
    getMyReferralsListStart(data);
    setPage(1);
    setRowsPerPage(perPageLimit);
  }, [languageToShow]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const data = {
      languageToShow: languageToShow,
      token: userAuthData != null ? userAuthData.token : "",
      skip: newPage,
      limit: perPageLimit,
    };
    getMyReferralsListStart(data);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSharePopup = (shareData) => {
    if (shareData) {
      setShareCode(

        shareData
      );
      setOpenSharePopup(!openSharePopup);
    } else {
    }
  };

  const onCopy = React.useCallback((value) => {
    setIsCopied(value);
    setOpenSharePopup(false);
  }, []);

  return (
    <>

    {/* for SEO purpose */}
    <Helmet>
        <title>My Referrals - Expand Your Network with Wfrlee</title>
        <meta name="description" content="Invite friends to join Wfrlee and earn rewards. Track your referrals and enjoy benefits as your network grows. Start referring today!" />
      </Helmet>

      {/* //////////Mypoints page Section/////////// */}
      <div className="myaccountpage">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="accounttotalsection">
                <div className="leftmenu">
                  <div className="account-titlearea">
                    <h2>{t("MyAccount.heading")}</h2>
                    <p>{t("MyAccount.subHeading")}</p>
                  </div>
                  <MyAccountLeftMenu />
                </div>

                <div className="rightsection">
                  <h1>{t("MyAccount.MyReferralsPage.heading")}</h1>
                  <p className="refernaltxt">{t("MyAccount.MyReferralsPage.headerShareDetails")}</p>
                  <div className="accountreferral">
                    <div className="referralcodearea">
                      <div className="referralcode">
                        <label>
                          {t("MyAccount.MyReferralsPage.YourReferralCode")}
                        </label>
                        <span>
                          {myReferralsList != null
                            ? myReferralsList?.data?.referral_code
                            : ""}
                        </span>

                        <Button
                          className="onClickBtn"
                          onClick={() =>
                            handleSharePopup(
                              myReferralsList?.data?.referral_code
                            )
                          }
                        >
                          <img src="./img/shareicon.svg" alt="" />
                        </Button>
                      </div>

                      <div className="referralcode referrallink">
                        <label>
                          {t("MyAccount.MyReferralsPage.YourReferralLink")}
                        </label>
                        <span>
                          {myReferralsList != null
                            ? myReferralsList?.data.referral_link
                            : ""}
                        </span>
                        <Button
                          className="onClickBtn"
                          onClick={() =>
                            handleSharePopup(
                              myReferralsList?.data.referral_link
                            )
                          }
                        >
                          <img src="./img/shareicon.svg" alt="" />
                        </Button>

                        <ShareModal
                          setOpenSharePopup={setOpenSharePopup}
                          openSharePopup={openSharePopup}
                          shareCode={shareCode}
                          setShareCode={setShareCode}
                          onCopy={onCopy}
                          isCopied={isCopied}
                          setIsCopied={setIsCopied}
                          referral_code={myReferralsList?.data?.referral_code}
                          refferelpage="refferelpage"
                        />
                      </div>
                    </div>

                    <div className="accounttable">
                      <Table>
                        <thead>
                          <tr>
                            <th>{t("MyAccount.MyReferralsPage.FirstName")}</th>
                            <th>{t("MyAccount.MyReferralsPage.LastName")}</th>
                            <th>{t("MyAccount.MyReferralsPage.Email")}</th>
                            <th>{t("MyAccount.MyReferralsPage.Points")}</th>
                            <th>{t("MyAccount.MyReferralsPage.Active")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {myReferralsList != null &&
                            (myReferralsList?.data// rowsPerPage > 0

                              .referred_transactions)
                              .map((data, index) => (
                                <tr key={index}>
                                  <td>{data.first_name}</td>
                                  <td>{data.last_name}</td>
                                  <td>{data.email}</td>
                                  <td>{data.points}</td>
                                  <td>
                                    {data.active == true
                                      ? t("MyAccount.MyReferralsPage.Yes")
                                      : t("MyAccount.MyReferralsPage.No")}
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </Table>
                      <Pagination
                        boundaryCount={1}
                        count={
                          myReferralsList != null
                            ? Math.ceil(
                              myReferralsList?.total_count / perPageLimit
                            )
                            : 0
                        }
                        defaultPage={1}
                        onChange={handleChangePage}
                        page={page}
                        showFirstButton={true}
                        showLastButton={true}
                      />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* //////////Mypoints page Section/////////// */}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  languageToShow: selectlanguageToShow,
  myReferralsDetails: selectMyReferralsDetails,
  myReferralsList: selectMyReferralsList,
  userAuthData: selectUserLoginData,
  userSocialAuthData: selectSocialLoginUser,
});
const mapDispatchToProps = (dispatch) => ({
  getMyReferralsDetailsStart: (data) =>
    dispatch(getMyReferralsDetailsStart(data)),
  getMyReferralsListStart: (data) => dispatch(getMyReferralsListStart(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MyreferralsPage);
