import { makeStyles } from "@mui/styles";

export const useBookingDetailsDialogStyles = makeStyles(
  {
    dialogPopup: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "& .MuiDialog-paper": {
        borderRadius: "10px",
        overflowX: "hidden",
        maxWidth: "900",

      },
      "& .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop": {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: "-1",
      },

   
    },
    dialogContent: {
      alignItems: "center",
      padding: "20px 40px",
      borderTop: "1px solid lightgrey",
      "&:focus-visible": {
        outline: "none !important",
      },
    },
    dialogText: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",

    },

    buttonBox: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
      padding: "20px 40px !important",
    },

    yesBtn: {
      background: "linear-gradient(180deg, #75a0c3 0%, #5287b3 100%)",
      color: "#fff !important",
      borderRadius: "8px",
      fontSize: "16px",
      fontWeight: "500",
      width: "100%",
      outline: "none",
      boxShadow: "none",
      textTransform: "capitalize !important",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "12px",
      transition: ".5s all",
      "&:hover": {
        background: "#42588b !important",
        color: "#fff",
      },
    },
    noBtn: {
      background: "linear-gradient(180deg, #75a0c3 0%, #5287b3 100%)",
      borderRadius: "8px",
      fontSize: "16px",
      fontWeight: "500",
      color: "#fff !important",
      width: "100%",
      outline: "none",
      boxShadow: "none",
      textTransform: "capitalize !important",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "12px",
      transition: ".5s all",
      "&:hover": {
        background: "#42588b !important",
        color: "#fff !important",
      },
    },
    heading: {
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "140%",
      textAlign: "center",
      visibility: "hidden"
    },
    text: {
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "140%",
      textAlign: "center",
      padding: "10px 0",
    },
    notifyText: {
      fontWeight: "400",
      fontSize: "12px",
    },

    "& .css-hlj6pa-MuiDialogActions-root>:not(:first-of-type)": {
      transition: "none",
    },
    subText: {
      "& p:first-child": {
        fontWeight: 400,
        fontSize: "13px",
        paddingBottom: "5px",
      },
      "& p:last-child": {
        fontWeight: 500,
      },
      "& .WL_check_txt": {
        textTransform: "initial",
      },
    },
    roomTilte: {
      fontWeight: 600,
      alignItems: "center",
    },
    bookingBox: {
      display: "flex",
      columnGap: "20px",
      marginBottom: "10px",
    },

    hotelDetailsContents: {
      marginTop: "10px",

      "& .hotel-details-card": {
        borderRadius: "8px",
        boxShadow: "0px 0px 5px rgb(0 0 0 / 15%)",
        flexWrap: "wrap",
        margin: "0 0 35px 0",
        background: "#ffffff",
        justifyContent: "space-between",
        "& .address": {
          fontWeight: 400,
          fontSize: "13px",
          lineHeight: "20px",
          textTransform: "capitalize",
          color: "#283555",
          position: "relative",
          padding: "0 0 0 25px",
          "& .icon": {
            position: "absolute",
            left: 0,
            top: "2px",
          },
        },
        "& .middle-content": {
          borderRight: "1px solid lightgrey",
          paddingRight: "10px",
          width: "40%",
        },
      },

      "& .ps_top_header": {
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid lightgrey",
        marginBottom: "12px",
        "& p": {
          fontSize: "16px",
          fontWeight: "600",
          lineHeight: "32px",
          color: "#000",
        },
      },

      "& .boldText": {
        fontWeight: "600",
        "&:last-child": {
          borderTop: "1px solid lightgrey",
          paddingTop: "12px",
        },
      },
      "& .WL_payment_inerwrp": {
        "& .WL_payment_iner_left": {
          fontSize: "14px",
        },
        "& .WL_payment_iner_right": {
          fontSize: "14px",
        },
      },
      "& .reviewstar img": {
        width: "14px",
      },
      "& .address": {
        display: "flex",
        columnGap: "5px",
        fontSize: "13px",
        padding: "0 0 0 25px",
        "& .icon img": {
          width: "14px",
          height: "14px",
        },
      },
    },
    checkoutDetails: {
      display: "flex",
      columnGap: "20px",
      paddingBottom: "20px",
      "& .CDBox": {
        display: "flex",
        justifyContent: "space-between",
        columnGap: "40px",

        paddingRight: "20px",
        "& .CDSubBox": {
          "&:first-child": {
            "& p:last-child": {
              borderRight: "1px solid lightGrey",
              paddingRight: "20px",
            },
          },
          "& p:first-child": {
            color: "#989494",
            paddingBottom: "10px",
          },
          "& p:last-child": {
            color: "#000",
            fontWeight: "600",
          },
        },
      },
    },
    bookingID: {
      fontSize: "18px",
      color: "#000",
      fontWeight: "600",
      lineHeight: "48px",
    },
    roomDetailsContent: {
      textAlign: "start",
      "& .middle-content": {
        borderRight: "1px solid lightgrey",
        paddingRight: "10px",
        width: "40%",
      },
      "& .cancellation_policy": {
        margin: "0 auto",
      },
    },
    headingText: {
      fontSize: "18px",
      fontWeight: "600",
      lineHeight: "48px",
      color: "#000",
    },
    hotelDetailsContentsSlider: {
      display: "flex",
      columnGap: "15px",
      padding: "10px",
      "& .hotel-content": {
        flexWrap: "wrap",
        "& .textBold": {
          fontWeight: "600",
          fontSize: "20px",
        },
        "& sub": {
          textTransform: "lowercase",
          fontWeight: "400",
        },
      },
    },
    roomDesc: {
      fontSize: "12px",
      textTransform: "capitalize",
    },

    /**** New popup styles  **** */

    brandBox: {
      background: "#374a77",
      color: "#fff",
      display: "flex",
      justifyContent: "space-between",
    }
  },
  { index: 1 }
);
