import React from "react";
import { Modal } from "react-bootstrap";

const LoginModal = ({ show, handleClose, children }) => {
  return (
    <Modal
      dialogClassName="modal-lg gen-modal infoModal loginModal"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="pop-info-content">{children}</div>
      </Modal.Body>
    </Modal>
  );
};
export default LoginModal;
