import React from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
  InfoWindow
} from "react-google-maps";
import { compose, withProps, withStateHandlers, withHandlers } from "recompose";

const MapWithPlaces = compose(

  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=" + process.env.REACT_APP_GoogleAPIKEY + "&language=en&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: "100vh", width: "100%" }} />,
    mapElement: <div style={{ height: "90%", top: '0' }} />
  }),
  withHandlers({


  }),
  withStateHandlers(
    props => ({
      infoWindows: props.places.map(p => {
        return { isOpen: true, places: props.places, searchdata: props.searchData };
      }),
    }),

    {
      onToggleOpen: ({ infoWindows }) => selectedIndex => (

        {

          infoWindows: infoWindows.map((iw, i) => {
            iw.isOpen = selectedIndex === i;

            window.open(`https://wfrlee.com/hotel-details/${iw.places[selectedIndex].slug}?search_type=${iw.searchdata.searchType}&book_for=3&city=${iw.searchdata.place}&check_in_date=${iw.searchdata.checkInTime.split("/").join('%2F')}&check_out_date=${iw.searchdata.checkOutTime == null ? "null" : iw.searchdata.checkOutTime.split("/").join('%2F')}&adults=1&children=0&rooms=1`, '_self')
            return iw;
          })
        })
    }
  ),
  withScriptjs,
  withGoogleMap
)(props => (
  <div id="map">
    <GoogleMap defaultZoom={props.zoom} defaultCenter={props.center} >
      {
        props.places &&
        props.places.map((place, i) => {
          let lat = parseFloat(place.location.coordinates[1], 10);
          let lng = parseFloat(place.location.coordinates[0], 10);
          return (
            <div  >
              <Marker
                id={place._id}
                key={place._id}
                position={{ lat: lat, lng: lng }}
                title="Click to zoom"
                onClick={props.onToggleOpen.bind(this, i)}

              >
                {props.infoWindows[i].isOpen && (
                  <InfoWindow >
                    <div>{`${place.min_price} ${place.currency}`}</div>
                  </InfoWindow>
                )}
              </Marker>
            </div>

          );
        }

        )


      }
    </GoogleMap>
  </div>
));

export default MapWithPlaces;