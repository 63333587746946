import React from "react";
import { Link } from "react-router-dom";
import PartnersWithWfrleeForm from "../../components/PartnerForm/PartnersWithWfrleeForm";
import { useLocation } from "react-router-dom";
import CMSLeftMenu from "../../components/cms/CMSLeftMenu";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const PartnersWithWfrleePage = () => {
  const location = useLocation();
  const [t] = useTranslation("common");

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* for SEO purpose */}
      <Helmet>
        <title>Partner with Wfrlee: Collaborate for Success</title>
        <meta name="description" content="Explore partnership opportunities with Wfrlee. Join us in driving mutual growth and innovation. Become a partner today!" />
      </Helmet>

      {/* //////////Common page Section/////////// */}
      <div className="commonpage">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <CMSLeftMenu location={location} />
            </div>
            <div className="col-md-9">
              <div className="WL_contactmwrp customform rightFormWidth">
                <h1>{t("PartnerswithwfrleePage.partnerHeading")}</h1>

                <PartnersWithWfrleeForm />
                {/* //////////call text Section start /////////// */}
                <div className="WL_contacttextwrp">
                  <div className="WL_innercontacttext">
                    <p className="WL_innercontacttextinner">
                      {t("PartnerswithwfrleePage.CallUsat")}
                      <span>
                        <Link to="tel:+971 589-706-050">+971 589-706-050</Link>
                      </span>
                    </p>
                  </div>

                  <div className="WL_innercontacttext">
                    <p className="WL_innercontacttextinner">
                      {t("PartnerswithwfrleePage.TextUsat")}
                      <span>
                        <a href="mailto:info@wfrlee.com">
                          {/* info@wfrlee.com */}
                          {t("PartnerswithwfrleePage.companyMail")}
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
                {/* //////////call text Section end /////////// */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* //////////Common page Section/////////// */}
    </>
  );
};

export default PartnersWithWfrleePage;
