import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import {
  ResetPasswordAfterForgotStart,
  stateClearAfterTask,
} from "./../../redux/user/user.actions";
import {
  selectUserForgotPasswordMsg,
  selectSignInError,
  selectUserDataLoading,
} from "../../redux/user/user.selectors";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

const ResetPasswordAfterForgot = ({
  forpassmsg,
  ResetPasswordAfterForgotStart,
  signinerror,
  stateClear,
  loading,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const [eye, seteye] = useState({
    firstEye: false,
    secondEye: false,
  });
  const [userData, setUserData] = useState({
    password: "",
    confirmPassowrd: "",
  });
  const [userDataError, setuserDataError] = useState({
    passwordErr: "",
    confirmPassowrdErr: "",
  });

  useEffect(() => {
    if (forpassmsg != null) {
      stateClear();
      navigate("/login");
    }
    if (signinerror != null) {
      if (!signinerror.success) {
        stateClear();
        navigate("/login");
      }
    }
  }, [forpassmsg, signinerror]);

  const handleChange = (e) => {
    if (e.target.name == "password") {
      setuserDataError({
        ...userDataError,
        passwordErr: "",
      });
    } else if (e.target.name == "confirmPassowrd") {
      setuserDataError({
        ...userDataError,
        confirmPassowrdErr: "",
      });
    }
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userData.password == "") {
      setuserDataError({
        ...userDataError,
        passwordErr: "Please Enter Your Password",
      });
      return;
    } else if (userData.confirmPassowrd == "") {
      setuserDataError({
        ...userDataError,
        confirmPassowrdErr: "Please Enter Your Confirm Password",
      });
      return;
    } else if (userData.password.length < 8) {
      setuserDataError({
        ...userDataError,
        passwordErr: "Please Enter a password which is greater than 7 digits",
      });
      return;
    } else if (userData.confirmPassowrd.length < 8) {
      setuserDataError({
        ...userDataError,
        confirmPassowrdErr:
          "Please Enter a confirm password which is greater than 7 digits",
      });
      return; 
    } else if (userData.confirmPassowrd != userData.password) {
      setuserDataError({
        ...userDataError,
        confirmPassowrdErr: "Password and Confirm Password not same",
      });
      return;
    } else {
      var data = {
        password: userData.password,
        confirm_password: userData.confirmPassowrd,
        token: params.token,
      };
      ResetPasswordAfterForgotStart(data);
    }
  };
  const handlepasswordhideshow = (type) => {
    if (type == "second") {
      seteye((eye) => ({
        ...eye,
        secondEye: !eye.secondEye,
      }));
    } else {
      seteye((eye) => ({
        ...eye,
        firstEye: !eye.firstEye,
      }));
    }

  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Password *</Form.Label>
          <div className="passwordsec">
            <Form.Control
              type={eye.firstEye ? "text" : "password"}
              min="8"
              placeholder="Password"
              onChange={handleChange}
              name="password"
              value={userData.password}
            />
            {eye.firstEye ? (
              <AiOutlineEye
                onClick={() => {
                  handlepasswordhideshow("first");
                }}
              />
            ) : (
              <AiOutlineEyeInvisible
                onClick={() => {
                  handlepasswordhideshow("first");
                }}
              />
            )}
          </div>
          
          <Form.Text className="text-muted errorformmessage">
            {userDataError.passwordErr}
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>Confirm Password *</Form.Label>
          <div className="passwordsec">
            <Form.Control
              type={eye.secondEye ? "text" : "password"}
              min="8"
              placeholder="Confirm passowrd"
              onChange={handleChange}
              name="confirmPassowrd"
              value={userData.confirmPassowrd}
            />
            {eye.secondEye ? (
              <AiOutlineEye
                onClick={() => {
                  handlepasswordhideshow("second");
                }}
              />
            ) : (
              <AiOutlineEyeInvisible
                onClick={() => {
                  handlepasswordhideshow("second");
                }}
              />
            )}
          </div>
          <Form.Text className="text-muted errorformmessage">
            {userDataError.confirmPassowrdErr}
          </Form.Text>
        </Form.Group>
        <Button variant="primary" type="submit" className="formsubmit">
          {loading ? <Spinner animation="border" /> : "Reset Password"}
        </Button>
      </Form>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  forpassmsg: selectUserForgotPasswordMsg,
  signinerror: selectSignInError,
  loading: selectUserDataLoading,
});
const mapDispatchToProps = (dispatch) => ({
  ResetPasswordAfterForgotStart: (data) =>
    dispatch(ResetPasswordAfterForgotStart(data)),
  stateClear: () => dispatch(stateClearAfterTask()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordAfterForgot);
