import React from "react";
import { Accordion } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { selectlanguageToShow } from "./../../redux/language/language.selectors";
import { faqGetDataLoad } from "./../../redux/faq/faq.actions";
import { selectfaqData } from "./../../redux/faq/faq.selectors";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import CMSLeftMenu from "../../components/cms/CMSLeftMenu";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const FaqPage = ({ selectfaqData, languageToShow, faqGetDataLoad }) => {
  const location = useLocation();
  const [t] = useTranslation("common");

  React.useEffect(() => {
    var data = {
      language: languageToShow,
    };
    faqGetDataLoad(data);
    window.scroll(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageToShow]);
  return (
    <>

      {/* for SEO purpose */}
      <Helmet>
        <title>Wfrlee FAQ: Answers to Your Common Questions</title>
        <meta name="description" content="Find quick answers to frequently asked questions at Wfrlee. Get the information you need about our services and support options." />
      </Helmet>

      {/* //////////Common page Section/////////// */}
      <div className="commonpage">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <CMSLeftMenu location={location} />
            </div>
            <div className="col-md-9">

              <div className="">
                <h1>{t("FAQPage.FAQHeading")}</h1>
                <Accordion defaultActiveKey="1" className="customaccordian">
                  {selectfaqData
                    ? selectfaqData.map((eachFaq, index) => {
                      return (
                        <Accordion.Item eventKey={index}>
                          <Accordion.Header>
                            {eachFaq.question}
                          </Accordion.Header>
                          <Accordion.Body dangerouslySetInnerHTML={{ __html: eachFaq.answer }}></Accordion.Body>
                        </Accordion.Item>
                      );
                    })
                    : ""}
                </Accordion>

              </div>
            </div>
          </div>
        </div>
      </div>

      {/* //////////Common page Section/////////// */}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  languageToShow: selectlanguageToShow,
  selectfaqData: selectfaqData,
});

const mapDispatchToProps = (dispatch) => ({
  faqGetDataLoad: (data) => dispatch(faqGetDataLoad(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FaqPage);
