import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Slider from "react-slick";
import { createStructuredSelector } from "reselect";
import { selectcurrencyToShow } from "../../../redux/currency/currency.selectors";
import { selectlanguageToShow } from "../../../redux/language/language.selectors";
import { selectCurrentUser } from "../../../redux/user/user.selectors";
import {
  addFavouriteHotelRequest,
  removeFavouriteHotelRequest,
} from "./../../../redux/hotels/hotel.actions";
import {
  selectFavouriteHotelSearchData,
  selectHotelDataList,
} from "./../../../redux/hotels/hotel.selectors";

var listSlide = {
  arrows: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
};

const HotelList = ({
  hotelList,
  addFavouriteHotelRequest,
  selectCurrentUser,
  selectlanguageToShow,
  searchsavedData,
  removeFavouriteHotelRequest,
  currencyToShow,
  selectHotelDataList,
  languageToShow,
}) => {
  // eslint-disable-next-line
  const [buttonDisable, setButtonDisable] = useState(false); // Button Disable
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [aminityDetailsShow, setAminityDetailsShow] = useState(true);
  const [t] = useTranslation("common");
  const englishAlphabetsRegex = /^[A-Za-z\s]+$/;
  const gotoHotelDetails = (slug, searchedType, hourSlot) => {
    if (searchsavedData != null) {
      var searchData = {
        search_type:
          searchedType != null
            ? searchedType
            : searchsavedData.searchType !== "null"
            ? searchsavedData.searchType
            : searchParams != null
            ? searchParams?.get("search_type")
            : searchedType,
        book_for:
          hourSlot != null
            ? hourSlot
            : searchsavedData.getHours !== "null"
            ? searchsavedData.getHours
            : searchParams != null
            ? searchParams?.get("book_for")
            : "",
        city:
          searchsavedData.place != null
            ? searchsavedData.place
            : searchParams != null
            ? searchParams?.get("city")
            : "",
        check_in_date:
          searchsavedData.checkInTime !== "Invalid date"
            ? searchsavedData.checkInTime
            : searchParams != null
            ? searchParams?.get("check_in_date")
            : "",
        check_out_date:
          searchsavedData.checkOutTime !== "Invalid date"
            ? searchsavedData.checkOutTime
            : searchParams != null
            ? searchParams?.get("check_out_date")
            : "",
        adults:
          searchsavedData.roomdata != null
            ? searchsavedData.roomdata.adults
            : searchParams != null
            ? searchParams?.get("adults")
            : "",
        children:
          searchsavedData.roomdata != null
            ? searchsavedData.roomdata.child
            : searchParams != null
            ? searchParams?.get("children")
            : "",
        rooms:
          searchsavedData.roomdata != null
            ? searchsavedData.roomdata.rooms
            : searchParams != null
            ? searchParams?.get("rooms")
            : "",
      };
    } else {
      // eslint-disable-next-line
      var searchData = {
        search_type:
          searchedType != null
            ? searchedType
            : searchParams != null
            ? searchParams?.get("search_type")
            : "",
        book_for:
          hourSlot != null
            ? hourSlot
            : searchParams != null
            ? searchParams?.get("book_for")
            : "",
        city: searchParams != null ? searchParams?.get("city") : "",
        check_in_date:
          searchParams != null ? searchParams?.get("check_in_date") : "",
        check_out_date:
          searchParams != null ? searchParams?.get("check_out_date") : "",
        adults: searchParams != null ? searchParams?.get("adults") : "",
        children: searchParams != null ? searchParams?.get("children") : "",
        rooms: searchParams != null ? searchParams?.get("rooms") : "",
      };
    }

    if (location.pathname === `/hotel-details/${slug}`) {
      navigate(
        {
          pathname: location.pathname,
          search: createSearchParams(searchData).toString(),
        },
        { state: searchData }
      );
    } else {
      navigate(
        {
          pathname: `/hotel-details/${slug}`,
          search: createSearchParams(searchData).toString(),
        },
        { state: searchData },
        { replace: true }
      );
    }
  };

  const handleShow = () => {
    setAminityDetailsShow(!aminityDetailsShow);
  };

  const addfavrites = (hotelList) => {
    const postData = {
      hotel_id: hotelList._id,
    };
    const data = {
      postData,
      languageToShow: selectlanguageToShow,
      token: selectCurrentUser != null ? selectCurrentUser.token : "",
    };

    addFavouriteHotelRequest(data);
  };
  const removefavrites = (hotelList) => {
    const postData = {
      hotel_id: hotelList._id,
    };
    const data = {
      postData,
      languageToShow: selectlanguageToShow,
      token: selectCurrentUser != null ? selectCurrentUser.token : "",
    };
    removeFavouriteHotelRequest(data);
  };

  return (
    <div className="px-3 pt-1 small-screens-hotel-list">
      <div className="box">
        <div
          className="d-flex hotel-title-and-star-container"
          style={{ flexWrap: "wrap" }}
        >
          <div
            className={
              selectlanguageToShow === "en"
                ? "hotel-title hotel-titel-direction-en"
                : "hotel-title"
            }
            onClick={() => {
              gotoHotelDetails(hotelList.slug);
            }}
          >
            {hotelList.name}
          </div>
          <span className="star-rating">
            {[...Array(hotelList.star)].map((e, i) => (
              <img src="./img/icon-review-star.svg" key={i} alt="" />
            ))}
          </span>
        </div>
        <div className="listing-box">
          <div className="slide-box">
            <Slider {...listSlide}>
              {hotelList.images.length > 0 ? (
                hotelList.images.map((hotels, index) => (
                  <div className="slide-item" key={index}>
                    <img src={hotels} alt="" />

                    <span
                      className={`favourite ${
                        hotelList.is_favourite ? "likefavourite" : ""
                      } ${
                        selectlanguageToShow === "en"
                          ? "favourite-en"
                          : "favourite-ar"
                      }`}
                    >
                      <img
                        src="./img/icon-favourite.svg"
                        onClick={() => {
                          hotelList.is_favourite
                            ? removefavrites(hotelList)
                            : addfavrites(hotelList);
                        }}
                        alt=""
                      />
                    </span>
                  </div>
                ))
              ) : (
                <div className="slide-item">
                  <img src="./img/list-slide-3.jpg" alt="" />
                  <span
                    className={`favourite okokokk ${
                      selectlanguageToShow === "en"
                        ? "favourite-en"
                        : "favourite-ar"
                    }`}
                  >
                    <img src="./img/icon-favourite.svg" alt="" />
                  </span>
                </div>
              )}
            </Slider>
          </div>
          <div>
            {hotelList.coupon.length > 0 && (
              <span
                className={
                  selectlanguageToShow === "en"
                    ? "Promo__Image Promo__Image-dir_en"
                    : "Promo__Image Promo__Image-dir_ar"
                }
              >
                <div
                  className={
                    selectlanguageToShow === "en"
                      ? "badge-container-en"
                      : "badge-container-ar"
                  }
                >
                  {" "}
                  {t("hotelDetails.hotelDetail.RoomDetails.promoCode")}
                </div>
              </span>
            )}
          </div>

          <div className="right">
            <div className="top-bar">
              <div className="left-txt d-block">
                <div className="d-flex" style={{ flexWrap: "wrap" }}>
                  {hotelList.recomended === true && (
                    <div className="recommendedTagBox">
                      <p className="recommendedTag">
                        <span>{t("hotelList.sortBy.Recommended")}</span>
                      </p>
                    </div>
                  )}

                  {hotelList?.lastRoom == 1 ? (
                    <div className="recommendedTagBox">
                      <p className="lastAvaliable">
                        <span>
                          {t(
                            "hotelDetails.hotelDetail.RoomDetails.LastRoomAvailable"
                          )}
                        </span>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  {hotelList?.lastRoom == 2 ? (
                    <div className="lastAvaliable">
                      <span>
                        {t(
                          "hotelDetails.hotelDetail.RoomDetails.Last2RoomsAvailable"
                        )}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="rating-content">
                <div className="comment">
                  <p
                    className={`feedback${
                      languageToShow === "ar" ? " feedback-ar" : ""
                    }`}
                  >
                    {hotelList.review.feedback_type}
                  </p>
                  <p
                    className={`reviews${
                      languageToShow === "ar" ? " reviews-ar" : ""
                    }`}
                  >
                    {hotelList.review.guests} {t("hotelList.reviews")}
                  </p>
                </div>
                <div className="rating-txt"> {hotelList.review.rating}</div>
              </div>
            </div>

            <div className="amenities">
              <div className="address-amenities-container">
                <div className="address">
                  <div className="icon">
                    <img src="./img/icon-address.svg" alt="" />
                  </div>
                  {hotelList.address.address_line1},
                  {hotelList.address.city_village},{hotelList.address.country},
                  {hotelList.address.state}
                  {hotelList.address.post_office}
                </div>
                <div className="amenities-item">
                  <ul>
                    {aminityDetailsShow
                      ? hotelList.hotel_amenity.length > 0
                        ? hotelList.hotel_amenity
                            .slice(0, 8)
                            .map((item, index) => {
                              return (
                                <li key={index}>
                                  <span className="icon">
                                    <img src={item.icon} alt="" />
                                  </span>
                                  {item.name}
                                </li>
                              );
                            })
                        : null
                      : hotelList.hotel_amenity.length > 0
                      ? hotelList.hotel_amenity.map((item, index) => {
                          return (
                            <li key={index}>
                              <span className="icon">
                                <img src={item.icon} alt="" />
                              </span>
                              {item.name}
                            </li>
                          );
                        })
                      : null}
                  </ul>
                  {hotelList.hotel_amenity.length > 8 ? (
                    <p
                      className="link-more"
                      onClick={handleShow}
                      style={{ cursor: "pointer" }}
                    >
                      {aminityDetailsShow
                        ? t("hotelList.seeMoreAmenities")
                        : t("hotelList.less")}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="price-content" translate="no">
                <ul>
                  {hotelList.slots.length > 0
                    ? hotelList.slots
                        .filter((item) => item.actual_price !== 0)
                        .map((item, index) => {
                          return (
                            <li
                              onClick={() => {
                                gotoHotelDetails(
                                  hotelList.slug,
                                  "hour",
                                  selectFavouriteHotelSearchData.getHours
                                );
                              }}
                              style={{
                                cursor: "pointer",
                                pointerEvents: buttonDisable ? "none" : "auto",
                              }}
                              key={index}
                            >
                              {/* Button loader based on time  */}
                              {buttonDisable ? (
                                <span className="rates">
                                  <Spinner animation="border" role="status">
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </Spinner>
                                </span>
                              ) : (
                                <>
                                  <span className="hrs">
                                    {item.hour}{" "}
                                    {item.hour === 12
                                      ? t("hotelList.hour")
                                      : t("hotelList.hours")}
                                  </span>
                                  <span className="rates">
                                    {currencyToShow.current}{" "}
                                    {parseInt(
                                      item.actual_price *
                                        currencyToShow.convertedRates
                                    )}
                                  </span>
                                </>
                              )}
                              {/* Button loader based on time  */}
                            </li>
                          );
                        })
                    : null}
                </ul>
                {searchsavedData.searchButtonShow === "bid" ? (
                  <button
                    className="bid-now"
                    style={{
                      cursor: "pointer",
                      pointerEvents: buttonDisable ? "none" : "auto",
                    }}
                    onClick={() => {
                      gotoHotelDetails(hotelList.slug, "bid");
                    }}
                  >
                    {/* Button loader based on time  */}
                    {buttonDisable ? (
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      t("hotelList.BIDnow")
                    )}
                  </button>
                ) : null}
                {/* Button loader based on time  */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  selectCurrentUser: selectCurrentUser,
  selectlanguageToShow: selectlanguageToShow,
  searchsavedData: selectFavouriteHotelSearchData,
  currencyToShow: selectcurrencyToShow,
  // eslint-disable-next-line
  selectHotelDataList,
  selectHotelDataList,
  languageToShow: selectlanguageToShow,
});
const mapDispatchToProps = (dispatch) => ({
  addFavouriteHotelRequest: (data) => dispatch(addFavouriteHotelRequest(data)),
  removeFavouriteHotelRequest: (data) =>
    dispatch(removeFavouriteHotelRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HotelList);
