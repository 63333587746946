import React, { useState } from "react";
import validator from "validator";
import { connect } from 'react-redux';
import { signUpStart } from "./../redux/user/user.actions";


const LoginRegistration = ({ signUpStart }) => {
  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    phoneno: "",
    email: "",
    password: "",
    confirmpassword: "",
    referralcode: ""
  });




  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    })
  }




  const handleSubmit = (e) => {
    e.preventDefault();
    if (userData.firstname === "") {
      return;
    } else if (userData.lastname === "") {
      return;
    } else if ((userData.password.length < 8)) {

      return;
    }
    else if (userData.password !== userData.confirmpassword) {
      return;
    }
    else if (!validator.isEmail(userData.email)) {
      return;
    } else {
      const data = {
        "first_name": userData.firstname,
        "last_name": userData.lastname,
        "email": userData.email,
        "contact_no": userData.phoneno,
        "password": userData.password,
      }
      signUpStart(data);
    }


  }


  return (
    <>
      <h3>Using CSS to style an HTML Form</h3>

      <div  >
        <form onSubmit={handleSubmit}>
          <label for="fname">First Name</label>
          <input type="text" onChange={handleChange} value={userData.firstname} name="firstname" placeholder="Your name.." />
          <label for="lname">Last Name</label>
          <input type="text" name="lastname" onChange={handleChange} value={userData.lastname} placeholder="Your last name.." />
          <label for="lname">Phone No</label>
          <input type="tel" id="lname" name="phoneno" placeholder="98765-25678" value={userData.phoneno} required onChange={handleChange} pattern="[0-9]{5}-[0-9]{5}" />
          <label for="lname">Email</label>
          <input type="text" id="lname" name="email" placeholder="Your last Email" value={userData.email} onChange={handleChange} />
          <label for="lname">Password</label>
          <input type="password" id="lname" name="password" placeholder="Your last Password" onChange={handleChange} />
          <label for="lname">Conform Password</label>
          <input type="password" id="lname" name="confirmpassword" value={userData.confirmpassword} placeholder="Your last Email" onChange={handleChange} />
          <label for="lname">Refferel Code</label>
          <input type="text" id="lname" name="referralcode" placeholder="Your last Email" onChange={handleChange} />
          <input type="submit" value="Submit" />
        </form>
      </div>
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  signUpStart: data => dispatch(signUpStart(data))
});





export default connect(null, mapDispatchToProps)(LoginRegistration);