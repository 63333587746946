import UserAccountActionTypes from "./useraccount.types";

export const userDataGetProfileDetails = (UserData) => ({
  type: UserAccountActionTypes.SET_MY_PROFILE_CURRENT_USER_DATA_START,
  payload: UserData,
});

export const userDataGetProfileDetailsSuccess = (UserData) => ({
  type: UserAccountActionTypes.SET_MY_PROFILE_CURRENT_USER_DATA_SUCCESS,
  payload: UserData,
});

export const userDataGetProfileDetailsFailure = (UserData) => ({
  type: UserAccountActionTypes.SET_MY_PROFILE_CURRENT_USER_DATA_FAILURE,
  payload: UserData,
});

export const bidingGetDataStart = (userCredentials) => ({
  type: UserAccountActionTypes.BIDING_GET_DATA_REQUEST,
  payload: userCredentials,
});

export const bidingGetDataSuccess = (bidingData) => ({
  type: UserAccountActionTypes.BIDING_GET_DATA_SUCCESS,
  payload: bidingData,
});

export const bidingGetDataFailure = (error) => ({
  type: UserAccountActionTypes.BIDING_GET_DATA_FAILED,
  payload: error,
});

/*** Career actions ****/
export function addCareerRequest(careerData) {
  return {
    type: UserAccountActionTypes.ADD_CAREER_REQUEST,
    payload: careerData,
  };
}
export function addCareerSuccess(careerData) {
  return {
    type: UserAccountActionTypes.ADD_CAREER_SUCCESS,
    payload: careerData,
  };
}

export function addCareerFailed(error) {
  return {
    type: UserAccountActionTypes.ADD_CAREER_FAILED,
    payload: error,
  };
}

/*** Partner actions ****/
export function addPartnerRequest(partnerData) {
  return {
    type: UserAccountActionTypes.ADD_PARTNER_REQUEST,
    payload: partnerData,
  };
}
export function addPartnerSuccess(partnerData) {
  return {
    type: UserAccountActionTypes.ADD_PARTNER_SUCCESS,
    payload: partnerData,
  };
}

export function addPartnerFailed(error) {
  return {
    type: UserAccountActionTypes.ADD_PARTNER_FAILED,
    payload: error,
  };
}

export const stateClearAfterTask = () => ({
  type: UserAccountActionTypes.STATE_CLEAR_AFTER_TASK,
});

/**  Get MY POINTS  */
export const getMyWalletPointsDataStart = (myWalletPointsData) => ({
  type: UserAccountActionTypes.GET_MY_WALLET_POINTS_REQUEST,
  payload: myWalletPointsData,
});

export const getMyWalletPointsDataSuccess = (myWalletPointsData) => ({
  type: UserAccountActionTypes.GET_MY_WALLET_POINTS_SUCCESS,
  payload: myWalletPointsData,
});

export const getMyWalletPointsDataFailure = (error) => ({
  type: UserAccountActionTypes.GET_MY_WALLET_POINTS_FAILED,
  payload: error,
});

/**  Get MY WALLET  */
export const getMyWalletListDataStart = (myWalletListData) => ({
  type: UserAccountActionTypes.GET_MY_WALLET_LIST_REQUEST,
  payload: myWalletListData,
});

export const getMyWalletListDataSuccess = (myWalletListData) => ({
  type: UserAccountActionTypes.GET_MY_WALLET_LIST_SUCCESS,
  payload: myWalletListData,
});

export const getMyWalletListDataFailure = (error) => ({
  type: UserAccountActionTypes.GET_MY_WALLET_LIST_FAILED,
  payload: error,
});

/**  Get ALL LOYALTY POINTS  */
export const getAllLoyaltyPointsRequest = (loyaltyPointsData) => ({
  type: UserAccountActionTypes.GET_ALL_LOYALTY_POINTS_REQUEST,
  payload: loyaltyPointsData,
});

export const getAllLoyaltyPointsSuccess = (loyaltyPointsData) => ({
  type: UserAccountActionTypes.GET_ALL_LOYALTY_POINTS_SUCCESS,
  payload: loyaltyPointsData,
});

export const getAllLoyaltyPointsFailure = (error) => ({
  type: UserAccountActionTypes.GET_ALL_LOYALTY_POINTS_FAILED,
  payload: error,
});

/**   Buy WALLET POINTS */
export const buyWalletPointsRequest = (buyWalletPointsData) => ({
  type: UserAccountActionTypes.BUY_WALLET_POINTS_REQUEST,
  payload: buyWalletPointsData,
});

export const buyWalletPointsSuccess = (buyWalletPointsData) => ({
  type: UserAccountActionTypes.BUY_WALLET_POINTS_SUCCESS,
  payload: buyWalletPointsData,
});

export const buyWalletPointsFailure = (error) => ({
  type: UserAccountActionTypes.BUY_WALLET_POINTS_FAILED,
  payload: error,
});

/**  Get MY REFERRALS DETAILS */
export const getMyReferralsDetailsStart = (myReferralsDetails) => ({
  type: UserAccountActionTypes.GET_MY_REFERRALS_DETAILS_REQUEST,
  payload: myReferralsDetails,
});

export const getMyReferralsDetailsSuccess = (myReferralsDetails) => ({
  type: UserAccountActionTypes.GET_MY_REFERRALS_DETAILS_SUCCESS,
  payload: myReferralsDetails,
});

export const getMyReferralsDetailsFailure = (error) => ({
  type: UserAccountActionTypes.GET_MY_REFERRALS_DETAILS_FAILED,
  payload: error,
});

/**  Get MY REFERRALS LIST  */
export const getMyReferralsListStart = (myReferralsList) => ({
  type: UserAccountActionTypes.GET_MY_REFERRALS_LIST_REQUEST,
  payload: myReferralsList,
});

export const getMyReferralsListSuccess = (myReferralsList) => ({
  type: UserAccountActionTypes.GET_MY_REFERRALS_LIST_SUCCESS,
  payload: myReferralsList,
});

export const getMyReferralsListFailure = (error) => ({
  type: UserAccountActionTypes.GET_MY_REFERRALS_LIST_FAILED,
  payload: error,
});

/**  Get ALL Notifications  */
export const getAllNotificationsRequest = (notificationsData) => ({
  type: UserAccountActionTypes.GET_ALL_NOTIFICATIONS_REQUEST,
  payload: notificationsData,
});

export const getAllNotificationsSuccess = (notificationsData) => ({
  type: UserAccountActionTypes.GET_ALL_NOTIFICATIONS_SUCCESS,
  payload: notificationsData,
});

export const getAllNotificationsFailure = (error) => ({
  type: UserAccountActionTypes.GET_ALL_NOTIFICATIONS_FAILED,
  payload: error,
});

/*********** CANCEL BOOKING **********/
export const cancelBookingRequest = (cancelBooking) => ({
  type: UserAccountActionTypes.CANCEL_BOOKING_REQUEST,
  payload: cancelBooking,
});

export const cancelBookingSuccess = (cancelBooking) => ({
  type: UserAccountActionTypes.CANCEL_BOOKING_SUCCESS,
  payload: cancelBooking,
});

export const cancelBookingFailure = (error) => ({
  type: UserAccountActionTypes.CANCEL_BOOKING_FAILED,
  payload: error,
});

/*** Contact-us actions ****/
export function addContactUsRequest(contactusData) {
  return {
    type: UserAccountActionTypes.ADD_CONTACT_US_REQUEST,
    payload: contactusData,
  };
}
export function addContactUsSuccess(contactusData) {
  return {
    type: UserAccountActionTypes.ADD_CONTACT_US_SUCCESS,
    payload: contactusData,
  };
}

export function addContactUsFailed(error) {
  return {
    type: UserAccountActionTypes.ADD_CONTACT_US_FAILED,
    payload: error,
  };
}
