import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const InfoModal = ({ show, handleClose, biddingRule }) => {
  const [t] = useTranslation("common");
  return (
    <Modal
      dialogClassName="modal-lg gen-modal infoModal"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <span>{t("checkoutPage.InfoModalPopup.BiddingRules")}</span>
      </Modal.Header>
      <Modal.Body>
        <div className="pop-info-content">
          {biddingRule != null
            ? biddingRule.length > 0
              ? biddingRule.map((rule, i) => (
                  <p key={i}>
                    {i + 1}. {rule}
                  </p>
                ))
              : t("checkoutPage.InfoModalPopup.NoDataFound")
            : t("checkoutPage.InfoModalPopup.NoDataFound")}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default InfoModal;
