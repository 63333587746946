import React from "react";
import { Navigate } from "react-router-dom";
import {
  selectUserLoginData,
  selectSocialLoginUser,
} from "../redux/user/user.selectors";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectlanguageToShow } from "../redux/language/language.selectors";

const AuthGuard = ({ children, userAuthData }) => {
  if (userAuthData == null) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

const mapStateToProps = createStructuredSelector({
  languageToShow: selectlanguageToShow,
  userAuthData: selectUserLoginData,
  userSocialAuthData: selectSocialLoginUser,
});

export default connect(mapStateToProps)(AuthGuard);
