import React, { Suspense, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./ReservationForms.css";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box, TextField, Typography } from "@mui/material";
import { Button, Form } from "react-bootstrap";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { BsPlusCircle } from "react-icons/bs";

const AutocompleteReact = React.lazy(() =>
  import("../rectInput-auto-complete/reactInput")
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const HourlyStayMobileForm = ({
  checkInOutValue,
  setCheckInOutValue,
  setIsOpen,
  handleOpen,
  guestRoomData,
  guestAndRoom,
  handleDecrease,
  handleIncrease,
  guestRomsPopup,
  handleHourscollect,
  getHours,
  open,
}) => {
  const [t] = useTranslation("common");
  const ref = useRef(null);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     // Check if the click is outside the referenced div
  //     if (ref.current && !ref.current.contains(event.target)) {
  //       closeGuestPopup();
  //     }
  //   };
  //   // Add event listener to the document
  //   document.addEventListener("mousedown", handleClickOutside);
  //
  //   // Cleanup the event listener on unmount
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  return (
    <div className="mobile__reservationForm__container">
      <div className="mobile__reservationForm__input_container">
        <label htmlFor="where" className="mobile__reservationForm__input_label">
          {t("home.search.WHERE")}
        </label>
        <Suspense fallback={<div>{t("home.search.Loading")}</div>}>
          <AutocompleteReact />
        </Suspense>
      </div>
      <div className="d-flex gap-2">
        <div className="mobile__reservationForm__input_container">
          <label
            htmlFor="checkIn"
            className="mobile__reservationForm__input_label"
          >
            {t("home.search.CheckinLabel")}
          </label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              //   label={`${t("home.search.CheckinLabel")}`}
              value={checkInOutValue.checkInTime}
              onChange={(newValue) => {
                setCheckInOutValue((checkInOutValue) => ({
                  ...checkInOutValue,
                  checkInTime: newValue,
                }));
              }}
              onClose={() => {
                setIsOpen(false);
                handleOpen();
              }}
              disablePast
              renderInput={(params) => <TextField {...params} />}
              inputFormat="DD MMM YYYY"
              disableMaskedInput={true}
              className="mobile__reservationForm__date__picker"
            />
          </LocalizationProvider>
        </div>

        <div className="mobile__reservationForm__input_container">
          <label
            htmlFor="select_hours"
            className="mobile__reservationForm__input_label"
          >
            {t("home.search.SelectHours")}
          </label>
          {getHours !== null && getHours > 0 ? (
            <span
              className="mobile__reservationForm__guests_input select_hours"
              onClick={handleOpen}
            >
              {getHours === "3"
                ? t("home.search.Hour3")
                : getHours === "6"
                ? t("home.search.Hours6")
                : t("home.search.Hours12")}
              {/* {getHours} Hours */}
            </span>
          ) : null}
          <div
            className="pophours"
            style={open ? { display: "block" } : { display: "none" }}
          >
            <Box sx={style}>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2 }}
                className="hourlybook"
                onChange={handleHourscollect}
              >
                {t("home.search.BookFor")}
                <Form.Select aria-label="Default select example">
                  <option disabled>
                    {/* Hours */}
                    {t("home.search.Hours")}
                  </option>

                  <option value="3">{t("home.search.Hour3")}</option>
                  <option value="6">{t("home.search.Hours6")}</option>
                  <option value="12">{t("home.search.Hours12")}</option>
                </Form.Select>
              </Typography>
            </Box>
          </div>
        </div>
      </div>
      <div className="mobile__reservationForm__input_container">
        <label
          htmlFor="roomguest"
          className="mobile__reservationForm__input_label"
        >
          {t("home.search.RoomGuest")}
        </label>
        <input
          name="roomguest"
          type="text"
          className="mobile__reservationForm__guests_input"
          value={`${guestRoomData.adults + guestRoomData.child} ${
            guestRoomData.adults + guestRoomData.child > 1
              ? `${t("home.search.Guests")}`
              : `${t("home.search.Guest")}`
          }  / ${guestRoomData.rooms} ${
            guestRoomData.rooms > 1
              ? `${t("home.search.Rooms")}`
              : `${t("home.search.Room")}`
          }`}
          onClick={guestAndRoom}
        />
        <div
          className="guestpop"
          ref={ref}
          style={guestRomsPopup ? { display: "block" } : { display: "none" }}
        >
          <h3>
            {/* Add Guest(s) And Room(s) */}
            {t("home.search.AddGuest(s)andRoom(s)")}
          </h3>
          <ul>
            <li key={24}>
              <h4>
                {/* Room (s) */}
                {t("home.search.Room(s)")}
              </h4>
              <div className="counting">
                <Button
                  type="button"
                  onClick={() => {
                    handleDecrease("room");
                  }}
                >
                  <AiOutlineMinusCircle />
                </Button>
                {guestRoomData.rooms}
                <Button
                  type="button"
                  onClick={() => {
                    handleIncrease("room");
                  }}
                >
                  <BsPlusCircle />
                </Button>
              </div>
            </li>
            <li key={25}>
              <h4>
                {/* Adult (s) */}
                {t("home.search.Adult(s)")}
              </h4>
              <div className="counting">
                <Button
                  onClick={() => {
                    handleDecrease("adult");
                  }}
                >
                  <AiOutlineMinusCircle />
                </Button>
                {guestRoomData.adults}
                <Button
                  onClick={() => {
                    handleIncrease("adult");
                  }}
                >
                  <BsPlusCircle />
                </Button>
              </div>
            </li>
            <li key={26}>
              <h4>
                {/* Children (s)  */}
                {t("home.search.Children(s)")}
                <span>
                  {/* Max 11 years */}
                  {t("home.search.MaxYears11")}
                </span>
              </h4>
              <div className="counting">
                <Button
                  onClick={() => {
                    handleDecrease("children");
                  }}
                >
                  <AiOutlineMinusCircle />
                </Button>
                {guestRoomData.child}
                <Button
                  onClick={() => {
                    handleIncrease("children");
                  }}
                >
                  <BsPlusCircle />
                </Button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HourlyStayMobileForm;
