import { takeLatest , put , all , call } from 'redux-saga/effects';
import axios from 'axios';

import FAQActionTypes from './faq.types';

import {   faqGetDataSuccess , faqGetDataFailure  }  from "./faq.actions";
var backendUrlEndPoint = process.env.REACT_APP_BACKEND_API_ENDPOINT;
export function* faqDataFetch({ payload : data }) {
  try {
  const faqdata = yield axios.get(''+backendUrlEndPoint+'/faq/get-all-faq',{
    headers: {
      'mobile': 'true',
      'Accept-Language':`${data.language}`
    }
  })
  yield put(faqGetDataSuccess(faqdata.data.data)) 
  } catch (error) {
    yield put(faqGetDataFailure(error))  
  }
}


export function* onFaqDataFetchLoad() {
yield takeLatest(FAQActionTypes.FAQ_GET_DATA_LOAD,faqDataFetch)
} 

export  function*  faqSagas() {
yield  all([call(onFaqDataFetchLoad)]) 
    
} 