import UserAccountActionTypes from "./useraccount.types";
const INITIAL_STATE = {
  bidingData: null,
  bidingError: null,
  loading: false,
  UserDetails: null,
  careerData: null,
  partnerData: null,
  myWalletPointsData: null,
  myWalletListData: null,
  myReferralsDetails: null,
  myReferralsList: null,
  buyWalletPointsData: null,
  loyaltyPointsData: null,
  notificationsData: null,
  error: null,
  cancelBooking: null,
  contactusData: null,
  careerLoading: false,
  cancelBookingLoader: false,
};

const useraccountReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserAccountActionTypes.SET_CURRENT_USER_DATA:
      return {
        ...state,
        UserDetails: action.payload,
      };
    case UserAccountActionTypes.BIDING_GET_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UserAccountActionTypes.BIDING_GET_DATA_SUCCESS:
      return {
        ...state,
        bidingData: action.payload,
      };
    case UserAccountActionTypes.BIDING_GET_DATA_FAILED:
      return {
        ...state,
        bidnigError: action.payload,
      };
    /**** Career Reducer */
    case UserAccountActionTypes.ADD_CAREER_REQUEST:
      return {
        ...state,
        careerLoading: true,
      };
    case UserAccountActionTypes.ADD_CAREER_SUCCESS:
      return {
        ...state,
        careerData: action.payload,
        careerLoading: false,
      };
    case UserAccountActionTypes.ADD_CAREER_FAILED:
      return {
        ...state,
        error: action.payload,
        careerLoading: false,
        careerData: action.payload,
      };

    /**** Parner Reducer */
    case UserAccountActionTypes.ADD_PARTNER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UserAccountActionTypes.ADD_PARTNER_SUCCESS:
      return {
        ...state,
        partnerData: action.payload,
      };
    case UserAccountActionTypes.ADD_PARTNER_FAILED:
      return {
        ...state,
        error: action.payload,
      };

    case UserAccountActionTypes.STATE_CLEAR_AFTER_TASK:
      return {
        ...state,
        error: null,
        careerData: null,
        partnerData: null,
        contactusData: null,
        careerLoading: false,
      };

    /**** MY POINTS Reducer */
    case UserAccountActionTypes.GET_MY_WALLET_POINTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UserAccountActionTypes.GET_MY_WALLET_POINTS_SUCCESS:
      return {
        ...state,
        loading: false,
        myWalletPointsData: action.payload,
      };
    case UserAccountActionTypes.GET_MY_WALLET_POINTS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    /**** MY Wallet Reducer */
    case UserAccountActionTypes.GET_MY_WALLET_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UserAccountActionTypes.GET_MY_WALLET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        myWalletListData: action.payload,
      };
    case UserAccountActionTypes.GET_MY_WALLET_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    /**** MY REFERRALS DETAILS Reducer */
    case UserAccountActionTypes.GET_MY_REFERRALS_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UserAccountActionTypes.GET_MY_REFERRALS_DETAILS_SUCCESS:
      return {
        ...state,
        myReferralsDetails: action.payload,
      };
    case UserAccountActionTypes.GET_MY_REFERRALS_DETAILS_FAILED:
      return {
        ...state,
        error: action.payload,
      };

    /**** MY REFERRALS LIST Reducer */
    case UserAccountActionTypes.GET_MY_REFERRALS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UserAccountActionTypes.GET_MY_REFERRALS_LIST_SUCCESS:
      return {
        ...state,
        myReferralsList: action.payload,
      };
    case UserAccountActionTypes.GET_MY_REFERRALS_LIST_FAILED:
      return {
        ...state,
        error: action.payload,
      };

    /**** GET ALL LOYALTY POINTS Reducer */
    case UserAccountActionTypes.GET_ALL_LOYALTY_POINTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UserAccountActionTypes.GET_ALL_LOYALTY_POINTS_SUCCESS:
      return {
        ...state,
        loyaltyPointsData: action.payload,
      };
    case UserAccountActionTypes.GET_ALL_LOYALTY_POINTS_FAILED:
      return {
        ...state,
        error: action.payload,
      };

    /**** BUY WALLET POINTS Reducer */
    case UserAccountActionTypes.BUY_WALLET_POINTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UserAccountActionTypes.BUY_WALLET_POINTS_SUCCESS:
      return {
        ...state,
        buyWalletPointsData: action.payload,
      };
    case UserAccountActionTypes.BUY_WALLET_POINTS_FAILED:
      return {
        ...state,
        error: action.payload,
      };

    /**** GET ALL NOTIFICATIONS Reducer */
    case UserAccountActionTypes.GET_ALL_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UserAccountActionTypes.GET_ALL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationsData: action.payload,
      };
    case UserAccountActionTypes.GET_ALL_NOTIFICATIONS_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    /*********** CANCEL BOOKING **********/
    case UserAccountActionTypes.CANCEL_BOOKING_REQUEST:
      return {
        ...state,
        cancelBookingLoader: true,
      };
    case UserAccountActionTypes.CANCEL_BOOKING_SUCCESS:
      return {
        ...state,
        cancelBooking: action.payload,
        cancelBookingLoader: false,
      };
    case UserAccountActionTypes.CANCEL_BOOKING_FAILED:
      return {
        ...state,
        error: action.payload,
        cancelBookingLoader: false,
      };

        /**** Contact Reducer */
    case UserAccountActionTypes.ADD_CONTACT_US_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UserAccountActionTypes.ADD_CONTACT_US_SUCCESS:
      return {
        ...state,
        contactusData: action.payload,
      };
    case UserAccountActionTypes.ADD_CONTACT_US_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default useraccountReducer;
