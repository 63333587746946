import React, { useEffect, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import AutocompleteReact from "../../../rectInput-auto-complete/reactInput";
import { isMobile } from "react-device-detect";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, TextField, Typography } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { BsPlusCircle } from "react-icons/bs";
import HourlyStayMobileForm from "../../../ReservationForms/HourlyStayMobileForm";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const HourlyForm = ({
  longaddress,
  checkInOutValue,
  setCheckInOutValue,
  setIsOpen,
  handleOpen,
  isOpen,
  handleHourscollect,
  open,
  getHours,
  guestRoomData,
  guestAndRoom,
  guestRomsPopup,
  handleDecrease,
  handleIncrease,
  setGuestRoomsPopup,
  handleClose,
}) => {
  const ref1 = useRef(null);
  const ref3 = useRef(null);

  const [t] = useTranslation("common");

  const handleClickOutside = (event) => {
    if (ref1.current && !ref1.current.contains(event.target)) {
      setGuestRoomsPopup(false);
    }
    if (ref3.current && !ref3.current.contains(event.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  if (window.innerWidth <= 768) {
    return (
      <div className="form-content">
        <HourlyStayMobileForm
          checkInOutValue={checkInOutValue}
          setCheckInOutValue={setCheckInOutValue}
          setIsOpen={setIsOpen}
          handleOpen={handleOpen}
          guestRoomData={guestRoomData}
          guestAndRoom={guestAndRoom}
          handleDecrease={handleDecrease}
          handleIncrease={handleIncrease}
          guestRomsPopup={guestRomsPopup}
          getHours={getHours}
          handleHourscollect={handleHourscollect}
          open={open}
        />
      </div>
    );
  }

  return (
    <div className="form-content">
      <div className="left">
        <Form.Label className={`${longaddress == "" ? "add-width" : ""}`}>
          {longaddress == "" ? `${t("headerSearch.search.WHERE")}` : null}
        </Form.Label>
        <Form.Group className="form-box location-drop">
          <AutocompleteReact />
        </Form.Group>
        <Form.Group className="form-box checkin">
          {!isMobile ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="datepickercheck-in"
                label={`${t("headerSearch.search.CheckinLabel")}`}
                value={checkInOutValue.checkInTime}
                onChange={(newValue) => {
                  setCheckInOutValue((checkInOutValue) => ({
                    ...checkInOutValue,
                    checkInTime: newValue,
                  }));
                }}
                disablePast
                renderInput={(params) => <TextField {...params} />}
                onClose={() => {
                  setIsOpen(false);
                  handleOpen();
                }}
                KeyboardButtonProps={{
                  onClick: (e) => {
                    setIsOpen(true);
                  },
                }}
                InputProps={{
                  onClick: () => {
                    setIsOpen(true);
                  },
                }}
                open={isOpen}
                inputFormat="DD MMM YYYY"
                disableMaskedInput={true}
              />
            </LocalizationProvider>
          ) : (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                label={`${t("home.search.CheckinLabel")}`}
                value={checkInOutValue.checkInTime}
                onChange={(newValue) => {
                  setCheckInOutValue((checkInOutValue) => ({
                    ...checkInOutValue,
                    checkInTime: newValue,
                  }));
                }}
                onClose={() => {
                  setIsOpen(false);
                  handleOpen();
                }}
                disablePast
                renderInput={(params) => <TextField {...params} />}
                inputFormat="DD MMM YYYY"
                disableMaskedInput={true}
              />
            </LocalizationProvider>
          )}

          {!isMobile ? (
            <div
              className="pophours"
              ref={ref3}
              style={open ? { display: "block" } : { display: "none" }}
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2 }}
                  className="hourlybook"
                  onChange={handleHourscollect}
                >
                  {t("headerSearch.search.BookFor")}
                  <Form.Select aria-label="Default select example">
                    <option>{t("headerSearch.search.Hours")}</option>
                    <option value="3"> {t("headerSearch.search.Hour3")}</option>
                    <option value="6">{t("headerSearch.search.Hours6")}</option>
                    <option value="12">
                      {t("headerSearch.search.Hours12")}
                    </option>
                  </Form.Select>
                </Typography>
              </Box>
            </div>
          ) : (
            <div
              className="pophours"
              style={open ? { display: "block" } : { display: "none" }}
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2 }}
                  className="hourlybook"
                  onChange={handleHourscollect}
                >
                  {t("home.search.BookFor")}
                  <Form.Select aria-label="Default select example">
                    <option>
                      {/* Hours */}
                      {t("home.search.Hours")}
                    </option>
                    <option value="3">{t("home.search.Hour3")}</option>
                    <option value="6">{t("home.search.Hours6")}</option>
                    <option value="12">{t("home.search.Hours12")}</option>
                  </Form.Select>
                </Typography>
              </Box>
            </div>
          )}

          {getHours != null && getHours > 0 ? (
            <span className="hourlable">
              {getHours == 3
                ? t("headerSearch.search.Hour3")
                : getHours == 6
                ? t("headerSearch.search.Hours6")
                : t("headerSearch.search.Hours12")}
            </span>
          ) : null}
        </Form.Group>
        <Form.Group className="form-box pop-form">
          <Form.Control
            type="text"
            className="room"
            placeholder={`${guestRoomData.adults + guestRoomData.child} ${
              guestRoomData.adults + guestRoomData.child > 1
                ? `${t("headerSearch.search.Guests")}`
                : `${t("headerSearch.search.Guest")}`
            }  / ${guestRoomData.rooms} ${
              guestRoomData.rooms > 1
                ? `${t("headerSearch.search.Rooms")}`
                : `${t("headerSearch.search.Room")}`
            }
            
            `}
            onClick={guestAndRoom}
          />
          <div
            className="guestpop"
            ref={ref1}
            style={guestRomsPopup ? { display: "block" } : { display: "none" }}
          >
            <h3>
              {/* Add Guest(s) And Room(s) */}
              {t("headerSearch.search.AddGuest(s)andRoom(s)")}
            </h3>
            <ul>
              <li>
                <h4>
                  {/* Room (s) */}
                  {t("headerSearch.search.Room(s)")}
                </h4>
                <div className="counting">
                  <Button
                    onClick={() => {
                      handleDecrease("room");
                    }}
                  >
                    <AiOutlineMinusCircle />
                  </Button>
                  {guestRoomData.rooms}
                  <Button
                    onClick={() => {
                      handleIncrease("room");
                    }}
                  >
                    <BsPlusCircle />
                  </Button>
                </div>
              </li>
              <li>
                <h4>
                  {/* Adult (s)  */}
                  {t("headerSearch.search.Adult(s)")}
                </h4>
                <div className="counting">
                  <Button
                    onClick={() => {
                      handleDecrease("adult");
                    }}
                  >
                    <AiOutlineMinusCircle />
                  </Button>
                  {guestRoomData.adults}
                  <Button
                    onClick={() => {
                      handleIncrease("adult");
                    }}
                  >
                    <BsPlusCircle />
                  </Button>
                </div>
              </li>
              <li>
                <h4>
                  {/* Children (s)  */}
                  {t("headerSearch.search.Children(s)")}
                  <span>
                    {/* Max 11 years */}
                    {t("headerSearch.search.MaxYears11")}
                  </span>
                </h4>
                <div className="counting">
                  <Button
                    onClick={() => {
                      handleDecrease("children");
                    }}
                  >
                    <AiOutlineMinusCircle />
                  </Button>
                  {guestRoomData.child}
                  <Button
                    onClick={() => {
                      handleIncrease("children");
                    }}
                  >
                    <BsPlusCircle />
                  </Button>
                </div>
              </li>
            </ul>
          </div>
        </Form.Group>
      </div>
    </div>
  );
};

export default HourlyForm;
