import React, { useEffect, useState, useRef } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { createStructuredSelector } from "reselect";
import {
  searchDataSave,
  getHotelListRequest,
  placeStateDataSave,
  hotelPlaceDataHandle,
  hotelWheatherApiRequest,
} from "./../../redux/hotels/hotel.actions";
import usePlacesAutocomplete from "use-places-autocomplete";
import { selectlanguageToShow } from "./../../redux/language/language.selectors";
import { connect } from "react-redux";
import useOnclickOutside from "react-cool-onclickoutside";
import {
  useNavigate,
  createSearchParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import {
  searchFilterData,
  searchFilterAddOrNot,
  selectFavouriteHotelSearchData,
  selectHotelBidNowPageRouteData,
  selectHotelDetailsPageRouteData,
  selectPlaceDataHandleData,
} from "./../../redux/hotels/hotel.selectors";
import "@reach/combobox/styles.css";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import HourlyForm from "./ReservationForms/HourlyForm/HourlyForm";
import BidNowForm from "./ReservationForms/BidNowForm/BidNowForm";
import moment from "moment";

const HotelSearchForOthers = ({
  searchDataSave,
  getHotelListRequest,
  searchFilterData,
  languageToShow,
  searchFilterAddOrNot,
  placeStateDataSave,
  selectFavouriteHotelSearchData,
  selectHotelBidNowPageRouteData,
  selectHotelDetailsPageRouteData,
  selectPlaceDataHandleData,
  hotelWheatherApiRequest,
}) => {
  const MySwal = withReactContent(Swal);
  const [t] = useTranslation("common");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const firstRender = useRef(true);
  let location = useLocation();
  const [searchType, setSearchType] = useState(
    selectHotelBidNowPageRouteData == "BiddingDetails"
      ? selectFavouriteHotelSearchData.searchType
      : searchParams.get("search_type") == null
      ? null
      : searchParams.get("search_type")
  );
  const [getHours, setGetHours] = useState(
    searchParams.get("book_for") == null ? 3 : searchParams.get("book_for")
  );
  const [longaddress, setLongaddress] = useState(
    selectPlaceDataHandleData?.place != null
      ? selectPlaceDataHandleData?.place
      : searchParams.get("city") == null
      ? null
      : searchParams.get("city")
  );
  const [placeSearchError, setPlaceSearchError] = useState("");
  const [searchtypeShowButton, setSearchtypeShowButton] = useState(
    searchParams.get("search_type") == null
      ? null
      : searchParams.get("search_type")
  );
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [guestRomsPopup, setGuestRoomsPopup] = useState(false);
  const [guestRoomData, setGuestRoomData] = useState({
    rooms: parseInt(
      searchParams.get("rooms") == null ? 1 : searchParams.get("rooms")
    ),
    child: parseInt(
      searchParams.get("children") == null ? 0 : searchParams.get("children")
    ),
    adults: parseInt(
      searchParams.get("adults") == null ? 1 : searchParams.get("adults")
    ),
  });
  const [place, setPlace] = useState(
    searchParams.get("city") == null ? null : searchParams.get("city")
  );
  if (searchParams.get("check_in_date") != null) {
    var dateHandle1 = searchParams.get("check_in_date").split("/");
  }
  if (searchParams.get("check_out_date") != null) {
    var dateHandle2 = searchParams.get("check_out_date").split("/");
  }
  const [checkInOutValue, setCheckInOutValue] = useState({
    checkInTime:
      searchParams.get("check_in_date") == null
        ? null
        : `${dateHandle1[2]}-${dateHandle1[1]}-${dateHandle1[0]}`,
    checkOutTime:
      searchParams.get("check_out_date") == null
        ? searchParams.get("search_type") == "hour"
          ? moment().add(1, "days")
          : null
        : `${dateHandle2[2]}-${dateHandle2[1]}-${dateHandle2[0]}`,
  });

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({ callbackName: "initMap" });

  const populartoleasting = (citynamedetails) => {
    let dateObj = new Date();
    let month = dateObj.getUTCMonth() + 1; //months from 1-12
    let day = dateObj.getUTCDate();
    let year = dateObj.getUTCFullYear();
    window.scroll(0, 0);

    navigate({
      pathname: "listingbidnow",
      search: createSearchParams({
        search_type: "bid",
        city: citynamedetails,
        check_in_date: "" + year + "-" + month + "-" + day + "",
        check_out_date: "" + year + "-" + month + "-" + (day + 1) + "",
        adults: "1",
        children: "0",
        rooms: "1",
      }).toString(),
    });
  };

  // DatePicker

  const reference = useOnclickOutside(() => {
    // When user clicks outside of the component, call it to clear and reset the suggestions data
    clearSuggestions();
  });
  const guestAndRoom = () => {
    setGuestRoomsPopup(!guestRomsPopup);
  };

  const handleHourscollect = (e) => {
    setGetHours(e.target.value);
    handleClose();
  };

  const handleIncrease = (type) => {
    if (type == "room") {
      setGuestRoomData((existingValues) => ({
        ...existingValues,
        rooms: guestRoomData.rooms + 1,
      }));
    } else if (type == "adult") {
      setGuestRoomData((existingValues) => ({
        ...existingValues,
        adults: guestRoomData.adults + 1,
      }));
    } else {
      setGuestRoomData((existingValues) => ({
        ...existingValues,
        child: guestRoomData.child + 1,
      }));
    }
  };

  const handleDecrease = (type) => {
    if (type == "room" && guestRoomData.rooms >= 1) {
      setGuestRoomData((existingValues) => ({
        ...existingValues,
        rooms: guestRoomData.rooms - 1,
      }));
    } else if (type == "adult" && guestRoomData.adults >= 1) {
      setGuestRoomData((existingValues) => ({
        ...existingValues,
        adults: guestRoomData.adults - 1,
      }));
    } else if (type == "children" && guestRoomData.child >= 1) {
      setGuestRoomData((existingValues) => ({
        ...existingValues,
        child: guestRoomData.child - 1,
      }));
    } else {
    }
  };

  useEffect(() => {
    if (selectPlaceDataHandleData != null) {
      setPlace(selectPlaceDataHandleData.place);
    }
  }, [selectPlaceDataHandleData]);

  useEffect(() => {
    hotelPlaceDataHandle({ place, place });
  }, []);

  useEffect(() => {
    if (selectPlaceDataHandleData?.place != undefined) {
      setPlace(selectPlaceDataHandleData?.place);
      setLongaddress(selectPlaceDataHandleData?.place);
    }
  }, [selectPlaceDataHandleData]);

  const searchsubmit = (event) => {
    event.preventDefault();

    if (place.length == 0) {
      setPlaceSearchError(t("headerSearch.search.PlaceValidationMsg"));
      return;
    } else {
      if (location.pathname == "/listingbidnow") {
        const filters = {
          price: [
            parseInt(searchFilterData.price[0]),
            parseInt(searchFilterData.price[1]),
          ],
          amenity: searchFilterData.amenity,
          star: searchFilterData.star,
        };
        const data = {
          language: languageToShow,
          filter: searchFilterAddOrNot,
          search_type: searchType,
          city: place != null ? place : "",
          book_for: getHours,
          check_in_date: moment(
            new Date(checkInOutValue.checkInTime.toString())
          ).format("DD/MM/YYYY"),
          check_out_date:
            checkInOutValue.checkOutTime == null
              ? null
              : moment(
                  new Date(checkInOutValue.checkOutTime.toString())
                ).format("DD/MM/YYYY"),
          adults: guestRoomData.adults,
          children: guestRoomData.child,
          rooms: guestRoomData.rooms,
          sort_by: searchFilterData.shortby,
          filters: encodeURIComponent(JSON.stringify(filters)),
        };
        const dataSearch = {
          place: place,
          roomdata: guestRoomData,
          getHours: getHours,
          searchType: searchType,
          searchButtonShow: searchType,
          checkInTime: moment(
            new Date(checkInOutValue.checkInTime.toString())
          ).format("DD/MM/YYYY"),
          checkOutTime:
            checkInOutValue.checkOutTime != null
              ? moment(
                  new Date(checkInOutValue.checkOutTime.toString())
                ).format("DD/MM/YYYY")
              : null,
        };

        placeStateDataSave(place);
        searchDataSave(dataSearch);
        getHotelListRequest(data);
      } else {
        navigate(
          {
            pathname: "/listingbidnow",
            search: createSearchParams({
              search_type: "hour",
              city: place != "" ? place : "",
              book_for: getHours,
              check_in_date: moment(
                new Date(checkInOutValue.checkInTime.toString())
              ).format("DD/MM/YYYY"),
              adults: guestRoomData.adults,
              children: guestRoomData.child,
              rooms: guestRoomData.rooms,
            }).toString(),
          },
          {
            state: {
              search_type: "hour",
              city: place != "" ? place : "",
              book_for: getHours,
              check_in_date: moment(
                new Date(checkInOutValue.checkInTime.toString())
              ).format("DD/MM/YYYY"),
              adults: guestRoomData.adults,
              children: guestRoomData.child,
              rooms: guestRoomData.rooms,
            },
          },
          { replace: true }
        );
      }
    }
  };

  const bidsearchnow = (event) => {
    event.preventDefault();
    var startDate = moment(
      new Date(selectFavouriteHotelSearchData.checkInTime)
    );
    if (place.length == 0) {
      setPlaceSearchError(t("headerSearch.search.PlaceValidationMsg"));
      return;
    }
    if (
      selectFavouriteHotelSearchData.checkOutTime == null ||
      selectFavouriteHotelSearchData.checkOutTime == "Invalid date"
    ) {
      MySwal.fire({
        confirmButtonText: "OK",
        confirmButtonColor: "#296da9",
        text: t("headerSearch.search.checkoutdateValidationMsg"),
      });

      return;
    } else {
      var endDate = moment(
        new Date(selectFavouriteHotelSearchData.checkOutTime)
      );
    }

    if (endDate == null) {
    } else if (endDate <= startDate) {
      MySwal.fire({
        confirmButtonText: "OK",
        confirmButtonColor: "#5287b3",
        text: t("headerSearch.search.dateValidationMsg"),
      });
      //  errorToast(t("headerSearch.search.dateValidationMsg"));
      return;
    }

    if (location.pathname == "/listingbidnow") {
      const filters = {
        price: [
          parseInt(searchFilterData.price[0]),
          parseInt(searchFilterData.price[1]),
        ],
        amenity: searchFilterData.amenity,
        star: searchFilterData.star,
      };
      const data = {
        language: languageToShow,
        filter: searchFilterAddOrNot,
        search_type: searchType,
        city: place != null ? place : "",
        book_for: getHours,
        check_in_date: moment(
          new Date(checkInOutValue.checkInTime.toString())
        ).format("DD/MM/YYYY"),
        check_out_date: moment(
          checkInOutValue.checkOutTime == null
            ? null
            : new Date(checkInOutValue.checkOutTime).toString()
        ).format("DD/MM/YYYY"),
        adults: guestRoomData.adults,
        children: guestRoomData.child,
        rooms: guestRoomData.rooms,
        sort_by: searchFilterData.shortby,

        filters: encodeURIComponent(JSON.stringify(filters)),
      };
      const dataSearch = {
        place: place,
        roomdata: guestRoomData,
        getHours: getHours,
        searchType: searchType,
        searchButtonShow: searchType,
        checkInTime: moment(
          new Date(checkInOutValue.checkInTime.toString())
        ).format("DD/MM/YYYY"),
        checkOutTime:
          checkInOutValue.checkOutTime != null
            ? moment(new Date(checkInOutValue.checkOutTime.toString())).format(
                "DD/MM/YYYY"
              )
            : null,
      };
      placeStateDataSave(place);
      searchDataSave(dataSearch);
      getHotelListRequest(data);
    } else {
      navigate(
        {
          pathname: "/listingbidnow",
          search: createSearchParams({
            search_type: "bid",
            city: place != "" ? place : "",
            check_in_date: moment(
              new Date(checkInOutValue.checkInTime.toString())
            ).format("DD/MM/YYYY"),
            check_out_date: moment(
              checkInOutValue.checkOutTime == null
                ? null
                : new Date(checkInOutValue.checkOutTime).toString()
            ).format("DD/MM/YYYY"),
            adults: guestRoomData.adults,
            children: guestRoomData.child,
            rooms: guestRoomData.rooms,
          }).toString(),
        },
        {
          state: {
            search_type: "bid",
            city: place != "" ? place : "",
            check_in_date: moment(
              new Date(checkInOutValue.checkInTime.toString())
            ).format("DD/MM/YYYY"),
            check_out_date: moment(
              checkInOutValue.checkOutTime == null
                ? null
                : new Date(checkInOutValue.checkOutTime).toString()
            ).format("DD/MM/YYYY"),
            adults: guestRoomData.adults,
            children: guestRoomData.child,
            rooms: guestRoomData.rooms,
          },
        },
        { replace: true }
      );
    }
  };
  const dateFormat = "YYYY-MM-DD";
  const handleSelectt = (key) => {
    if (key === "first") {
      setSearchType("hour");
    } else {
      setSearchType("bid");
    }
  };
  useEffect(() => {
    if (selectHotelBidNowPageRouteData == "BiddingDetails") {
    } else {
      const dataSearch = {
        place: place,
        roomdata: guestRoomData,
        getHours: getHours,
        searchType: searchType,
        searchButtonShow: searchtypeShowButton,
        checkInTime: moment(
          new Date(checkInOutValue.checkInTime.toString())
        ).format("DD/MM/YYYY"),
        checkOutTime:
          checkInOutValue.checkOutTime != null
            ? moment(new Date(checkInOutValue.checkOutTime.toString())).format(
                "DD/MM/YYYY"
              )
            : null,
      };
      searchDataSave(dataSearch);
    }
  }, [
    guestRoomData,
    getHours,
    searchType,
    checkInOutValue,
    value,
    longaddress,
    selectHotelBidNowPageRouteData,
  ]);

  return (
    <>
      <Tabs
        defaultActiveKey={
          searchParams.get("search_type") == "hour" ? "first" : "second"
        }
        onSelect={handleSelectt}
      >
        <Tab eventKey="first" title={t("headerSearch.search.HourStay")}>
          <div className="tab-container hourly-stay">
            <form className="form-content" onSubmit={searchsubmit}>
              <HourlyForm
                checkInOutValue={checkInOutValue}
                getHours={getHours}
                guestAndRoom={guestAndRoom}
                guestRomsPopup={guestRomsPopup}
                guestRoomData={guestRoomData}
                handleDecrease={handleDecrease}
                handleHourscollect={handleHourscollect}
                handleIncrease={handleIncrease}
                handleOpen={handleOpen}
                isOpen={isOpen}
                longaddress={longaddress}
                open={open}
                searchsubmit={searchsubmit}
                setCheckInOutValue={setCheckInOutValue}
                setIsOpen={setIsOpen}
                handleClose={handleClose}
                setGuestRoomsPopup={setGuestRoomsPopup}
              />
              <div
                className="right"
                style={{ cursor: "pointer" }}
                onClick={() => document.getElementById("magnifier-btn").click()}
              >
                <button id="magnifier-btn" type="submit"></button>
              </div>
            </form>
          </div>
        </Tab>
        <Tab
          eventKey="second"
          onClick={() => {
            setSearchType("bid");
          }}
          title={t("headerSearch.search.BidNow")}
        >
          <div className="tab-container bid-now">
            <form onSubmit={bidsearchnow}>
              <div className="form-content">
                <BidNowForm
                  checkInOutValue={checkInOutValue}
                  guestAndRoom={guestAndRoom}
                  guestRomsPopup={guestRomsPopup}
                  guestRoomData={guestRoomData}
                  handleDecrease={handleDecrease}
                  handleIncrease={handleIncrease}
                  handleOpen={handleOpen}
                  longaddress={longaddress}
                  setCheckInOutValue={setCheckInOutValue}
                  setIsOpen={setIsOpen}
                />
                <div
                  className="right"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    document.getElementById("magnifier-btn").click()
                  }
                >
                  <button id="magnifier-btn" type="submit"></button>
                  {/* {t("headerSearch.search.searchBtn")} */}
                </div>
              </div>
            </form>
          </div>
        </Tab>
      </Tabs>
      <p style={{ color: "red1" }}>{placeSearchError}</p>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  languageToShow: selectlanguageToShow,
  searchFilterData: searchFilterData,
  searchFilterAddOrNot: searchFilterAddOrNot,
  selectFavouriteHotelSearchData: selectFavouriteHotelSearchData,
  selectHotelDetailsPageRouteData: selectHotelDetailsPageRouteData,
  selectHotelBidNowPageRouteData: selectHotelBidNowPageRouteData,
  selectPlaceDataHandleData: selectPlaceDataHandleData,
});

const mapDispatchToProps = (dispatch) => ({
  searchDataSave: (data) => dispatch(searchDataSave(data)),
  getHotelListRequest: (data) => dispatch(getHotelListRequest(data)),
  placeStateDataSave: (data) => dispatch(placeStateDataSave(data)),
  hotelPlaceDataHandle: (data) => dispatch(hotelPlaceDataHandle(data)),
  hotelWheatherApiRequest: (data) => dispatch(hotelWheatherApiRequest(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HotelSearchForOthers);
