import React from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import Hotellist from "../Hotels/HotelList/hotellist";

const BidNowList = ({
  hotelListDataLoading,
  selectHotelDataList,
  fetchData,
  TotalHotelPageCount,
}) => {
  const [t] = useTranslation("common");

  const isLoading = hotelListDataLoading;
  const isDataNotFound =
    selectHotelDataList != null && selectHotelDataList.length === 0;
  const hasData =
    selectHotelDataList != null && selectHotelDataList.hotels.length > 0;

  return (
    <>
      {isLoading && <Skeleton count={10} />}

      {hasData && (
        <InfiniteScroll
          dataLength={selectHotelDataList.hotels.length}
          next={fetchData}
          hasMore={TotalHotelPageCount > selectHotelDataList.hotels.length}
          loader={<Skeleton count={10} />}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>{t("hotelList.YayYouhaveseenitall")}</b>
            </p>
          }
        >
          {selectHotelDataList.hotels.map((hotelList, index) => (
            <Hotellist key={index} hotelList={hotelList} />
          ))}
        </InfiniteScroll>
      )}

      {!isLoading &&
        !isDataNotFound &&
        !hasData &&
        !selectHotelDataList?.hotels?.length && (
          <div className="datanotfound">
            <h2>
              {t("hotelList.Sorry")} <span>{t("hotelList.noDataFound")}</span>
            </h2>
          </div>
        )}
    </>
  );
};

export default BidNowList;
