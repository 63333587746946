import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useBookingDetailsDialogStyles } from "./BookingDetailsDialogStyles";
import DialogTitle from "@mui/material/DialogTitle";
import { GrClose } from "react-icons/gr";
import Slider from "react-slick";
import moment from "moment";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectlanguageToShow } from "./../../redux/language/language.selectors";
import { selectcurrencyToShow } from "./../../redux/currency/currency.selectors";

const BookingDetailsDialog = ({
  setShowDialog,
  isOpen,
  bookingData,
  cancelButtonLabel,
  cancelDialogHandler,
  currencyToShow,
  languageToShow,
}) => {
  const classes = useBookingDetailsDialogStyles();
  const [open, setOpen] = React.useState(isOpen);
  const [scroll, setScroll] = React.useState("paper");

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));


  var collecSlider = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const renderBookingDetails = () => {
    return (
      <Box className={classes.checkoutDetails}>
        <Box sx={{ borderRight: "1px solid lightGrey" }} className="CDBox">
          <Box className="CDSubBox">
            <p>Check-in</p>
            <p>
              {" "}
              {moment(new Date(`${bookingData?.checkin_date}`)).format("DD MMM, YYYY")},
              {moment(new Date(`${bookingData?.checkin_time}`), ["HH:mm"]).format(
                "hh:mm A"
              )}
            </p>
          </Box>
          {bookingData?.checkout_date && (
            <Box className="CDSubBox">
              <p>Check-out</p>
              <p>
                {moment(new Date(`${bookingData?.checkout_date}`)).format("DD MMM, YYYY")}
                ,
                {moment(new Date(`${bookingData?.checkout_time}`), ["HH:mm"]).format(
                  "hh:mm A"
                )}
              </p>
            </Box>
          )}
        </Box>
        <Box className="CDBox">
          <Box className="CDSubBox">
            <p>Guests</p>
            <p>
              {bookingData?.no_of_guests}{" "}
              {bookingData?.no_of_guests > 1 ? "Guests" : "Guest"}
            </p>
          </Box>
          <Box className="CDSubBox">
            <p>Rooms</p>
            <p
              style={{
                borderRight: "1px solid lightGrey",
                paddingRight: "20px",
              }}
            >
              {bookingData?.no_of_rooms}{" "}
              {bookingData?.no_of_rooms > 1 ? "Rooms" : "Room"}
            </p>
          </Box>
          <Box className="CDSubBox">
            <p>Stay</p>{" "}
            <>
              {bookingData?.no_of_nights > 0 && (
                <p>
                  {bookingData?.no_of_nights}{" "}
                  {bookingData?.no_of_nights > 1 ? "Nights" : "Night"}
                </p>
              )}
              {bookingData?.hours > 0 && (
                <p>
                  {bookingData?.hours}{" "}
                  {bookingData?.hours > 1 ? "Hours" : "Hour"}
                </p>
              )}
            </>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderHotelDetails = () => {
    return (
      <Box className={classes.hotelDetailsContents}>
        <Box className={classes.bookingIDBox}>
          <p className={classes.bookingID}>
            <span>Booking Confirmation ID: </span>#{bookingData?.booking_number}
          </p>
        </Box>
        <div className="WL_right_rivewbox d-block listing-box hotel-details-card">
          <Box className={classes.hotelDetailsContentsSlider}>
            <Box className="WL_coll_slider myFavColl_Slider hotel-content">
              <Slider {...collecSlider}>
                {bookingData?.hotel_details?.images.length > 0 ? (
                  bookingData?.hotel_details?.images.map((image, index) => (
                    <img
                      src={image}
                      alt=""
                      className="WL_coll_slider_img"
                      key={index}
                    />
                  ))
                ) : (
                  <img
                    src="./img/collec_img1.png"
                    alt=""
                    className="WL_coll_slider_img"
                  />
                )}
              </Slider>
            </Box>
            <Box className="middle-content hotel-content">
              <Box className={`${classes.roomTilte} gap-2 d-flex `}>
                {bookingData?.hotel_details?.name}
                <Box className="reviewstar mb-0">
                  {[...Array(bookingData?.hotel_details?.star)].map((e, i) => (
                    <img src="./img/star.svg" key={i} alt="" />
                  ))}
                </Box>
              </Box>
              <Box
                className="title-sm"
                contentEditable="false"
                dangerouslySetInnerHTML={{
                  __html: `${
                    bookingData != null
                      ? bookingData?.hotel_details?.description
                      : null
                  }`,
                }}
              ></Box>
              <Box className="address">
                <Box className="icon">
                  <img src="./img/icon-address.svg" alt="" />
                </Box>
                {/* 22nd St, PO Box 39 39 71, Dubai, Dubai PO Box 39 */}
                <p>
                  <span>
                    {bookingData?.hotel_details?.address.address_line1}
                  </span>{" "}
                  <br />
                  {bookingData?.hotel_details?.address.address_line2 && (
                    <span>
                      {bookingData?.hotel_details?.address.address_line2},{" "}
                    </span>
                  )}{" "}
                  {bookingData?.hotel_details?.address.city_village && (
                    <span>
                      {bookingData?.hotel_details?.address.city_village}{" "}
                    </span>
                  )}
                  {bookingData?.hotel_details?.address.pincode}
                  
                  {bookingData?.hotel_details?.address.country}
                </p>
              </Box>
            </Box>
            <Box className="right-content hotel-content m-auto">
              <div className="WL_payment_iner_left textBold">
                {bookingData?.price_per_hour > 0 && (
                  <>
                    {parseInt(
                      bookingData?.price_per_hour *
                        currencyToShow.convertedRates
                    ) +
                      " " +
                      currencyToShow?.current}
                    <sub>/hour</sub>{" "}
                  </>
                )}
                {bookingData?.price_per_night > 0 && (
                  <>
                    {parseInt(
                      bookingData?.price_per_night *
                        currencyToShow.convertedRates
                    ) +
                      " " +
                      currencyToShow?.current}
                    <sub>/Night</sub>{" "}
                  </>
                )}
              </div>

            </Box>
          </Box>
        </div>
        {renderBookingDetails()}
      </Box>
    );
  };

  const renderRoomDetails = () => {
    return (
      <Box className={classes.roomDetailsContent}>
        <p className={classes.headingText}>Room Details</p>
        <Box className={`${classes.hotelDetailsContentsSlider} roomDetailsBox`}>
          <Box className="WL_coll_slider myFavColl_Slider">
            <Slider {...collecSlider}>
              {bookingData?.room_type_details?.images.length > 0 ? (
                bookingData?.room_type_details?.images.map((image, index) => (
                  <img
                    src={image}
                    alt=""
                    className="WL_coll_slider_img"
                    key={index}
                  />
                ))
              ) : (
                <img
                  src="./img/collec_img1.png"
                  alt=""
                  className="WL_coll_slider_img"
                />
              )}
            </Slider>
          </Box>
          <Box sx={{ textAlign: "left" }} className="middle-content">
            <Box className={classes.roomTilte}>
              {bookingData?.room_type_details?.name}
            </Box>
            <Box
              className={classes.roomDesc}
              contentEditable="false"
              dangerouslySetInnerHTML={{
                __html: `${
                  bookingData != null
                    ? bookingData?.room_type_details?.description
                    : null
                }`,
              }}
            ></Box>
          </Box>
          <Box>
            <Box className="cancellation_policy mb-4">
              <Box className={classes.roomTilte}>Booking Date</Box>
              <Box className={classes.roomDesc}>
                {moment(new Date(`${bookingData?.booking_date}`)).format("DD MMM, YYYY")}
              </Box>
            </Box>
            <Box className="cancellation_policy ">
              <Box className={classes.roomTilte}>
                Cancellation Policy Details
              </Box>
              <Box className={classes.roomDesc}>
                {/* Cancellation Time:{" "} */}
                <p>
                  No Cancellation Fee within{" "}
                  {bookingData?.cancellation_policy_details?.cancellation_time}{" "}
                  {bookingData?.cancellation_policy_details?.cancellation_time >
                  1
                    ? "Hours"
                    : "Hour"}
                </p>
                <>
                  Cancellation Fees :{" "}
                  {
                    parseInt(
                      bookingData?.cancellation_policy_details
                        ?.fees_in_percentage * currencyToShow.convertedRates
                    )
                    
                  }{" "}
                  %
                </>
                
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderPriceBreakup = () => {
    return (
      <Box className={classes.hotelDetailsContents}>
        <p className={classes.headingText}>Payment Summary</p>
        <Box className="WL_paymentwrp hotel-details-card p-2">
          <Box className="ps_top_header">
            <p>Invoice</p>
            <p>Price</p>
            <p>Total</p>
          </Box>
          {bookingData?.price_breakup != null &&
            bookingData?.price_breakup.map((item, index) =>
              item.fee_type !== "service_fee" && item.amount > 0 ? (
                <Box
                  
                  key={index}
                  className={
                    item.fee_type === "amount" ||
                    item.fee_type === "amount_to_be_paid_now"
                      ? "boldText WL_payment_inerwrp"
                      : "WL_payment_inerwrp"
                  }
                >
                  <div className="WL_payment_iner_left">
                    {item.fee_type === "taxes" ? "Vat (15%)" : item.fee_name}:
                  </div>
                  {item.fee_type === "net_rate" && (
                    <div className="WL_payment_iner_left">
                      {Number(
                        item.amount * currencyToShow.convertedRates
                      ).toFixed(2)}
                      {" " + currencyToShow?.current}
                    </div>
                  )}

                  <div className="WL_payment_iner_right">
                    {Number(
                      item.amount * currencyToShow.convertedRates
                    ).toFixed(2)}
                    {" " + currencyToShow?.current}
                    
                  </div>
                </Box>
              ) : (
                ""
              )
            )}
        </Box>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px", // Set your width here
            },
          },
        }}
        open={open}
        onClose={() => {
          setShowDialog(false);
          setOpen(false);
        }}
        aria-labelledby="responsive-dialog-title"
        className={classes.dialogPopup}
        scroll={scroll}
      >
        <DialogTitle>
          <Typography className={classes.heading}>Booking Details</Typography>
          <Box sx={{ position: "absolute", right: 0, left: "auto", top: 0 }}>
            <Button
              className={"popclose onClickBtn"}
              onClick={cancelDialogHandler}
            >
              <GrClose />
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.dialogContent} tabIndex={0}>
          <div className="container">
            <Box className={classes.dialogText}>
              
              <Box>{renderHotelDetails()}</Box>
              <hr />
              <Box className={classes.text}>{renderRoomDetails()}</Box>
              <hr />
              <Box>{renderPriceBreakup()}</Box>
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  currencyToShow: selectcurrencyToShow,
  languageToShow: selectlanguageToShow,
});
export default connect(mapStateToProps)(BookingDetailsDialog);
