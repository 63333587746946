import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import moment from "moment";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { BsPlusCircle } from "react-icons/bs";
import AutocompleteReact from "../../../rectInput-auto-complete/reactInput";
import BidNowMobileForm from "../../../ReservationForms/BidNowMobileForm";

const BidNowForm = ({
  longaddress,
  checkInOutValue,
  setCheckInOutValue,
  guestRoomData,
  handleDecrease,
  handleIncrease,
  guestAndRoom,
  guestRomsPopup,
  handleOpen,
  setIsOpen,
}) => {
  const [t] = useTranslation("common");

  const ref2 = useRef(null);

  const [checkoutTime, setCheckoutTime] = useState(false);
  const [checkIn, setCheckIn] = useState(false);
  const [guestRomsPopupsecond, setGuestRoomsPopupsecond] = useState(false);

  const hendleDatePickercheckInClose = () => {
    setCheckIn(false);
    setCheckoutTime(true);
  };

  const guestAndRoomsecond = () => {
    setGuestRoomsPopupsecond(!guestRomsPopupsecond);
  };

  const handleClickOutside = (event) => {
    if (ref2.current && !ref2.current.contains(event.target)) {
      setGuestRoomsPopupsecond(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  if (window.innerWidth <= 768) {
    return (
      <BidNowMobileForm
        checkInOutValue={checkInOutValue}
        guestAndRoom={guestAndRoom}
        guestRomsPopup={guestRomsPopup}
        guestRoomData={guestRoomData}
        handleDecrease={handleDecrease}
        handleIncrease={handleIncrease}
        handleOpen={handleOpen}
        setCheckInOutValue={setCheckInOutValue}
        setIsOpen={setIsOpen}
      />
    );
  }

  return (
    <div className="left">
      <Form.Label className={`${longaddress == "" ? "add-width" : ""}`}>
        {longaddress == "" ? `${t("headerSearch.search.WHERE")}` : null}
      </Form.Label>
      <Form.Group className="form-box location-drop">
        <AutocompleteReact />
      </Form.Group>
      <div className="bidchecking">
        <Form.Group className="form-box">
          {!isMobile ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="datepickercheck-in check-in"
                label={t("headerSearch.search.CheckinLabel")}
                value={checkInOutValue.checkInTime}
                onChange={(newValue) => {
                  // setValue();
                  setCheckInOutValue((checkInOutValue) => ({
                    ...checkInOutValue,
                    checkInTime: newValue,
                  }));
                }}
                disablePast
                renderInput={(params) => <TextField {...params} />}
                onClose={() => {
                  hendleDatePickercheckInClose();
                }}
                KeyboardButtonProps={{
                  onClick: (e) => {
                    setCheckIn(true);
                  },
                }}
                InputProps={{
                  onClick: () => {
                    setCheckIn(true);
                  },
                }}
                open={checkIn}
                inputFormat="DD MMM YYYY"
                disableMaskedInput={true}
              />
            </LocalizationProvider>
          ) : (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                className="datepickercheck-in check-in"
                label={`${t("home.search.CheckinLabel")}`}
                value={checkInOutValue.checkInTime}
                onChange={(newValue) => {
                  // setValue();
                  moment(new Date(newValue)).format("YYYY-MM-DD HH:mm:ss");
                  setCheckInOutValue((checkInOutValue) => ({
                    ...checkInOutValue,
                    checkInTime: newValue,
                  }));
                }}
                disablePast
                renderInput={(params) => <TextField {...params} />}
                inputFormat="DD MMM YYYY"
                disableMaskedInput={true}
              />
            </LocalizationProvider>
          )}
        </Form.Group>
        <Form.Group className="form-box check-out">
          {!isMobile ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="datepickercheck-in"
                label={t("headerSearch.search.CheckoutLabel")}
                value={checkInOutValue.checkOutTime}
                onChange={(newValue) => {
                  //setValue(moment(newValue).format("DD/MM/YYYY"));
                  setCheckInOutValue((checkInOutValue) => ({
                    ...checkInOutValue,
                    checkOutTime: newValue,
                  }));
                }}
                renderInput={(params) => <TextField {...params} />}
                onClose={() => setCheckoutTime(false)}
                KeyboardButtonProps={{
                  onClick: (e) => {
                    setCheckoutTime(true);
                  },
                }}
                disablePast
                minDate={new Date(checkInOutValue.checkInTime).setDate(
                  new Date(checkInOutValue.checkInTime).getDate() + 1
                )}
                InputProps={{
                  onClick: () => {
                    setCheckoutTime(true);
                  },
                }}
                open={checkoutTime}
                inputFormat="DD MMM YYYY"
                disableMaskedInput={true}
              />
            </LocalizationProvider>
          ) : (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                className="datepickercheck-in"
                label={`${t("home.search.CheckoutLabel")}`}
                value={checkInOutValue.checkOutTime}
                onChange={(newValue) => {
                  //setValue(moment(newValue).format("DD/MM/YYYY"));
                  setCheckInOutValue((checkInOutValue) => ({
                    ...checkInOutValue,
                    checkOutTime: newValue,
                  }));
                }}
                minDate={new Date(checkInOutValue.checkInTime).setDate(
                  new Date(checkInOutValue.checkInTime).getDate() + 1
                )}
                renderInput={(params) => <TextField {...params} />}
                disablePast
                inputFormat="DD MMM YYYY"
                disableMaskedInput={true}
              />
            </LocalizationProvider>
          )}
        </Form.Group>
      </div>
      <Form.Group className="form-box pop-form">
        <Form.Control
          type="text"
          className="room"
          placeholder={`${guestRoomData.adults + guestRoomData.child} ${
            guestRoomData.adults + guestRoomData.child > 1
              ? t("headerSearch.search.Guests")
              : t("headerSearch.search.Guest")
          }  / ${guestRoomData.rooms} ${
            guestRoomData.rooms > 1
              ? t("headerSearch.search.Rooms")
              : t("headerSearch.search.Room")
          }`}
          onClick={guestAndRoomsecond}
        />
        <div
          className="guestpop"
          ref={ref2}
          style={
            guestRomsPopupsecond ? { display: "block" } : { display: "none" }
          }
        >
          <h3>
            {/* Add Guest (s) And Room (s) */}
            {t("headerSearch.search.AddGuest(s)andRoom(s)")}
          </h3>
          <ul>
            <li>
              <h4>
                {/* Room (s) */}
                {t("headerSearch.search.Room(s)")}
              </h4>
              <div className="counting">
                <Button
                  onClick={() => {
                    handleDecrease("room");
                  }}
                >
                  <AiOutlineMinusCircle />
                </Button>
                {guestRoomData.rooms}
                <Button
                  onClick={() => {
                    handleIncrease("room");
                  }}
                >
                  <BsPlusCircle />
                </Button>
              </div>
            </li>
            <li>
              <h4>
                {/* Adult (s)  */}
                {t("headerSearch.search.Adult(s)")}
              </h4>
              <div className="counting">
                <Button
                  onClick={() => {
                    handleDecrease("adult");
                  }}
                >
                  <AiOutlineMinusCircle />
                </Button>
                {guestRoomData.adults}
                <Button
                  onClick={() => {
                    handleIncrease("adult");
                  }}
                >
                  <BsPlusCircle />
                </Button>
              </div>
            </li>
            <li>
              <h4>
                {/* Children (s)  */}
                {t("headerSearch.search.Children(s)")}

                <span>
                  {/* Max 11 years */}
                  {t("headerSearch.search.MaxYears11")}
                </span>
              </h4>
              <div className="counting">
                <Button
                  onClick={() => {
                    handleDecrease("children");
                  }}
                >
                  <AiOutlineMinusCircle />
                </Button>
                {guestRoomData.child}
                <Button
                  onClick={() => {
                    handleIncrease("children");
                  }}
                >
                  <BsPlusCircle />
                </Button>
              </div>
            </li>
          </ul>
        </div>
      </Form.Group>
    </div>
  );
};

export default BidNowForm;
