import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const NotFoundScreen = () => {
  const navigate = useNavigate();
  return (
    <div className="notFoundWrapper text-center"
      style={{
        minHeight: "70vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <div>
        <h1 style={{ fontSize: "100px", marginBottom: "60px", opacity: '.5' }}>404</h1>
        <p className="headerTitle mt-2">
          Sorry, we couldn’t find the page you’re looking for
        </p>
        <p className="mt-2 mb-4 paragraph-text">
          You might want to check the URL again or go back.
        </p>
        <Button
          variant="contained"
          className={"backBtn"}
          onClick={() => {
            navigate(-1);
          }}
          style={{
            border: "none",
            outline: "none",
            backgroundColor: "midnightblue",
            color: "#fff",
            borderRadius: "5px",
            padding: "10px",
            width: "80px",
          }}
        >
          Back
        </Button>
      </div>
    </div>
  );
};

export default NotFoundScreen;
