import React from "react";
import Pagination from "@mui/material/Pagination";

const PaginationWithDisplay = (props) => {
  return (
    <div className="col-md-12" >
      <div className="pagination-div">
        {props.displayShow ? (
          <div className="display-div-p-right">
            <label>Display</label> &nbsp;&nbsp;
            <select
              className="display-select-height"
              value={props.page.per_page_limit}
              onChange={(e) => {
                props.handleChangePage(e, props.page.page_no, e.target.value)
              }}
            >
              <option value={3}>3</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
            </select>
          </div>
        ) : (
          ""
        )}

        {props.page.total > props.page.per_page_limit ? (
          <div>
            <Pagination
              boundaryCount={1}
              count={Math.ceil(props.page.total / props.page.per_page_limit)}
              defaultPage={1}
              onChange={(e, newPage) => props.handleChangePage(e, newPage, props.page.per_page_limit)}
              page={props.page.page_no}
              showFirstButton={true}
              showLastButton={true}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default PaginationWithDisplay;
