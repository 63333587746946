import React from "react";
import { Button, Dropdown, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  getMyWalletListDataStart,
  buyWalletPointsRequest,
  getMyWalletPointsDataStart,
  getAllLoyaltyPointsRequest,
} from "../../redux/useraccount/useraccount.actions";
import {
  selectMyWalletPointsData,
  selectMyWalletListData,
  selectBuyWalletPointsData,
  selectAllLoyaltyPointsData,
} from "../../redux/useraccount/useraccount.selectors";
import {
  selectUserLoginData,
  selectSocialLoginUser,
} from "../../redux/user/user.selectors";
import { selectlanguageToShow } from "../../redux/language/language.selectors";
import moment from "moment";
import { errorToast } from "../../utils/toastHelper";
import TablePagination from "@mui/material/TablePagination";
import Pagination from "@mui/material/Pagination";
import { useTranslation } from "react-i18next";
import MyAccountLeftMenu from "../../components/MyAccount/MyAccountLeftMenu";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Helmet } from "react-helmet";
const MywalletPage = ({
  myWalletPointsData,
  myWalletListData,
  buyWalletPointsData,
  loyaltyPointsData,
  getMyWalletPointsDataStart,
  getMyWalletListDataStart,
  buyWalletPointsRequest,
  getAllLoyaltyPointsRequest,
  languageToShow,
  userAuthData,
}) => {
  const [t] = useTranslation("common");
  const [showBuyPointsPopup, setShowBuyPointsPopup] = React.useState(false);
  const [pointSelected, setPointSelected] = React.useState(false);
  const [loyaltyId, setLoyaltyId] = React.useState();
  const [loyaltyPointSelected, setLoyaltyPointSelected] = React.useState({});

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const perPageLimit = 6;
  const MySwal = withReactContent(Swal);
  const data = {
    languageToShow: languageToShow,
    token: userAuthData != null ? userAuthData.token : "",
  };

  const popupRef = React.useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowBuyPointsPopup(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  React.useEffect(() => {
    var data = {
      languageToShow: languageToShow,
      token: userAuthData != null ? userAuthData.token : "",
      type: "all",
      skip: page,
      limit: perPageLimit,
    };

    getMyWalletListDataStart(data);
    setPage(1);
    setRowsPerPage(perPageLimit);
  }, [languageToShow]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const data = {
      languageToShow: languageToShow,
      token: userAuthData != null ? userAuthData.token : "",
      skip: newPage,
      limit: perPageLimit,
    };
    getMyWalletListDataStart(data);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    getAllLoyaltyPointsRequest(data);
  }, [languageToShow]);

  const handlePopupBox = () => {
    setShowBuyPointsPopup(true);
  };
  const buyPointsHandler = () => {
    const postData = {
      loyalty_point_id: loyaltyPointSelected.id,
      "device_type": "web"
    };
    const data = {
      postData,
      languageToShow: languageToShow,
      token: userAuthData != null ? userAuthData.token : "",
    };

    if (userAuthData != null) {
      buyWalletPointsRequest(data);
      setShowBuyPointsPopup(false);
      setLoyaltyId();
    } else {
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
        text: t("MyAccount.MyWalletPage.Pleaseloginfirst")
      });

    }
  };

  const getPoints = (data, index) => {
    setLoyaltyId(index);
    setPointSelected(!pointSelected);
    setLoyaltyPointSelected(data);
  };

  React.useEffect(() => {
    const data = {
      languageToShow: languageToShow,
      token: userAuthData != null ? userAuthData.token : "",
      type: "all",
      skip: page,
      limit: perPageLimit,
    };
    if (buyWalletPointsData != null) {
      if (buyWalletPointsData.success == true) {
        window.open(buyWalletPointsData.data.url, "mozillaTab");
        getMyWalletListDataStart(data);
        setPage(1);
        setRowsPerPage(perPageLimit);
      } else {
      }
    }
  }, [buyWalletPointsData, languageToShow]);

  return (
    <>
      {/* for SEO purpose */}
      <Helmet>
        <title>Manage Your Wallet - Wfrlee Secure Access</title>
        <meta name="description" content="Access and manage your wallet on Wfrlee securely. Keep track of your transactions and balance in one convenient place." />
      </Helmet>

      {/* //////////Mypoints page Section/////////// */}
      <div className="myaccountpage">
        <div className="container">
          <div className="row">
            <div className="col-md-12"></div>
            <div className="col-md-12">
              <div className="accounttotalsection">
                <div className="leftmenu">
                  <div className="account-titlearea">
                    <h2>{t("MyAccount.heading")}</h2>
                    <p>{t("MyAccount.subHeading")}</p>
                  </div>
                  <MyAccountLeftMenu />
                </div>
                <div className="rightsection">
                  <h1>{t("MyAccount.MyWalletPage.heading")}</h1>
                  <div className="accountpoint accountwallet">
                    <div className="pointsarea">
                      <span>
                        {t("MyAccount.MyWalletPage.MyWalletvalue")}{" "}
                        <label>
                          {" "}
                          {myWalletListData != null
                            ? myWalletListData?.data.total_points
                            : 200}{" "}
                          {t("MyAccount.MyWalletPage.Points")}
                        </label>
                      </span>
                      <div className="walletdropdown">
                        <Button
                          onClick={handlePopupBox}
                          className={"buyPointBtn"}
                        >
                          {t("MyAccount.MyWalletPage.BuyPoints")}
                        </Button>
                        <div
                          className={
                            showBuyPointsPopup == true
                              ? " popbox popupFadeIn"
                              : " popbox popupFadeOut"
                          }
                          ref={popupRef}
                        >
                          <h3>
                            <Button
                              className="popclose onClickBtn p-0"
                              onClick={() => {
                                setShowBuyPointsPopup(false);
                                setLoyaltyId();
                              }}
                            >
                              <img src="./img/popclose.svg" alt="" />
                            </Button>
                            {t("MyAccount.MyWalletPage.BuyPoints")}{" "}
                            <span>
                              {t(
                                "MyAccount.MyWalletPage.GetPointsinYourwallet"
                              )}
                            </span>
                          </h3>
                          <div className="pointboxarea">
                            {loyaltyPointsData != null
                              ? loyaltyPointsData?.data.map((data, index) => (
                                <div
                                  key={index}
                                  className={
                                    loyaltyId === index
                                      ? "selectedPointsmallbox pointsmallbox"
                                      : " pointsmallbox"
                                  }
                                  onClick={() => getPoints(data, index)}
                                >
                                  {data.type} <span>{data.point_value}</span>{" "}
                                  {t("MyAccount.MyWalletPage.Points")}
                                  <Link to="#">
                                    {data.currency} {data.price}
                                  </Link>
                                </div>
                              ))
                              : t("MyAccount.MyWalletPage.noPointAvailable")}
                          </div>

                          <Button
                            className="gen-btn submitBuyPointsBtn"
                            onClick={buyPointsHandler}
                          >
                            {t("MyAccount.MyWalletPage.BuyPoints")}
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="accounttable">
                      {myWalletListData != null ? (
                        <Table>
                          <thead>
                            <tr>
                              <th>{t("MyAccount.MyWalletPage.Date")}</th>
                              <th>{t("MyAccount.MyWalletPage.WalletValue")}</th>
                              <th>
                                {t("MyAccount.MyWalletPage.TransactionID")}
                              </th>
                              <th>{t("MyAccount.MyPointsPage.expire_date")}</th>
                              <th>{t("MyAccount.MyWalletPage.Status")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(myWalletListData?.data
                              .wallet_transactions)
                              .map((data, index) => (
                                <tr key={index}>
                                  <td> {data.date !=null?moment(new Date(data.date)).format("ll"):''}</td>
                                  <td>
                                    {data.points}{" "}
                                    {t("MyAccount.MyWalletPage.Points")}
                                  </td>
                                  <td>{data.transaction_id}</td>
                                  <td> {data.expire_date !=null?moment(new Date(data.expire_date)).format("ll"):t(
                                          "MyAccount.MyPointsPage.no_expire_date"
                                        )}</td>
                                  <td className="text-capitalize">
                                    {data.status}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      ) : (
                        <div className="accounttable">
                          <div className="bookingbox">
                            <div className="bookingboxlft">
                              <h3>{t("MyAccount.MyWalletPage.NoDataFound")}</h3>
                            </div>
                          </div>
                        </div>
                      )}
                      <Pagination
                        boundaryCount={1}
                        count={
                          myWalletListData != null
                            ? Math.ceil(
                              myWalletListData?.total_count / perPageLimit
                            )
                            : 0
                        }
                        defaultPage={1}
                        onChange={handleChangePage}
                        page={page}
                        showFirstButton={true}
                        showLastButton={true}
                      />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* //////////Mypoints page Section/////////// */}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  myWalletPointsData: selectMyWalletPointsData,
  myWalletListData: selectMyWalletListData,
  buyWalletPointsData: selectBuyWalletPointsData,
  loyaltyPointsData: selectAllLoyaltyPointsData,
  languageToShow: selectlanguageToShow,
  userAuthData: selectUserLoginData,
  userSocialAuthData: selectSocialLoginUser,
});
const mapDispatchToProps = (dispatch) => ({
  getMyWalletPointsDataStart: (data) =>
    dispatch(getMyWalletPointsDataStart(data)),
  getMyWalletListDataStart: (data) => dispatch(getMyWalletListDataStart(data)),
  buyWalletPointsRequest: (data) => dispatch(buyWalletPointsRequest(data)),
  getAllLoyaltyPointsRequest: (data) =>
    dispatch(getAllLoyaltyPointsRequest(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MywalletPage);
