import { createSelector } from "reselect";

const selectUserAccount = (state) => state.userAccount;

export const selectUserAccountBidingData = createSelector(
  [selectUserAccount],
  (useraccount) => useraccount.bidingData
);

export const selectAddCareerData = createSelector(
  [selectUserAccount],
  (useraccount) => useraccount.careerData
);

export const selectAddPartnerData = createSelector(
  [selectUserAccount],
  (useraccount) => useraccount.partnerData
);

export const selectMyWalletPointsData = createSelector(
  [selectUserAccount],
  (useraccount) => useraccount.myWalletPointsData
);

export const selectMyWalletListData = createSelector(
  [selectUserAccount],
  (useraccount) => useraccount.myWalletListData
);

export const selectLoading = createSelector(
  [selectUserAccount],
  (useraccount) => useraccount.loading
);

export const selectMyReferralsDetails = createSelector(
  [selectUserAccount],
  (useraccount) => useraccount.myReferralsDetails
);
export const selectMyReferralsList = createSelector(
  [selectUserAccount],
  (useraccount) => useraccount.myReferralsList
);

export const selectAllLoyaltyPointsData = createSelector(
  [selectUserAccount],
  (useraccount) => useraccount.loyaltyPointsData
);

export const selectBuyWalletPointsData = createSelector(
  [selectUserAccount],
  (useraccount) => useraccount.buyWalletPointsData
);

export const selectAllNotificationsData = createSelector(
  [selectUserAccount],
  (useraccount) => useraccount.notificationsData
);

/*********** CANCEL BOOKING **********/
export const selectCancelBookingData = createSelector(
  [selectUserAccount],
  (useraccount) => useraccount.cancelBooking
);

/****** CONTACT-US ****** */
export const selectAddContactData = createSelector(
  [selectUserAccount],
  (useraccount) => useraccount.contactusData
);

export const selectCareerLoading = createSelector(
  [selectUserAccount],
  (useraccount) => useraccount.careerLoading
);

/**** Cancel Booking Loader **** */
export const selectCancelBookingLoader = createSelector(
  [selectUserAccount],
  (useraccount) => useraccount.cancelBookingLoader
);
