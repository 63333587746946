import * as React from "react";

function SamsungPayIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={697.18665}
      height={130.97572}
      viewBox="0 0 697.18665 130.97572"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <clipPath clipPathUnits="userSpaceOnUse" id="a">
          <path d="M0 98.232h522.89V0H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)" transform="matrix(1.33333 0 0 -1.33333 0 130.976)">
        <path
          d="M0 0v-17.712h6.848c5.334 0 8.955 3.951 8.955 8.889C15.803-3.951 12.182 0 6.848 0zm-8.954 8.296H7.507c9.942 0 17.316-7.637 17.316-17.119 0-9.547-7.374-17.187-17.383-17.187H0v-13.76h-8.954z"
          fill="#000"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(387.025 64.808)"
        />
        <path
          d="M0 0c0 6.52-4.938 11.853-11.522 11.853-6.584 0-11.655-5.201-11.655-11.853 0-6.715 5.071-11.981 11.655-11.981S0-6.649 0 0m-31.934-.131c0 12.775 9.35 20.083 19.094 20.083 5.07 0 9.613-2.107 12.379-5.399v4.609h8.889v-38.518H-.461v5.003c-2.831-3.555-7.44-5.794-12.51-5.794-9.284 0-18.963 7.44-18.963 20.016"
          fill="#000"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(446.158 44.395)"
        />
        <path
          d="M0 0l-15.802 36.609h9.481L4.411 10.667l10.14 25.942h9.35L.922-19.687h-9.02z"
          fill="#000"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(473.95 26.948)"
        />
        <path
          d="M0 0l.611-35.291H.363L-9.969 0h-16.687v-44.495H-15.6l-.621 36.508h.248l11.098-36.508h16.01V0z"
          fill="#000"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(284.813 71.76)"
        />
        <path
          d="M0 0l-8.334-44.957H3.818L9.966-4.166l.252.004 5.992-40.795h12.079L20.002 0z"
          fill="#000"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(73.175 71.76)"
        />
        <path
          d="M0 0l-5.545-34.377H-5.8L-11.338 0h-18.337l-.992-44.957h11.253l.282 40.415h.248l7.509-40.415H.034L7.547-4.546l.247.004.282-40.415h11.245L18.33 0z"
          fill="#000"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(141.106 71.76)"
        />
        <path
          d="M0 0c.44-1.088.3-2.482.089-3.325-.366-1.499-1.384-3.027-4.38-3.027-2.812 0-4.517 1.628-4.517 4.077l-.011 4.358h-12.056l-.003-3.467c0-10.028 7.89-13.058 16.339-13.058 8.135 0 14.823 2.774 15.892 10.28.547 3.881.145 6.422-.048 7.369C9.407 12.622-7.65 15.429-8.922 20.693c-.215.914-.163 1.861-.049 2.364.319 1.443 1.295 3.019 4.106 3.019 2.634 0 4.177-1.624 4.177-4.074v-2.785h11.22v3.167c0 9.792-8.797 11.323-15.16 11.323-7.99 0-14.527-2.648-15.718-9.98-.325-2.005-.37-3.8.104-6.063C-18.292 8.475-2.316 5.818 0-.001"
          fill="#000"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(45.918 39.485)"
        />
        <path
          d="M0 0c.429-1.08.296-2.452.085-3.292-.362-1.479-1.369-2.989-4.335-2.989-2.786 0-4.477 1.605-4.477 4.036l-.007 4.309h-11.937l-.004-3.432c0-9.929 7.816-12.926 16.18-12.926 8.054 0 14.675 2.745 15.734 10.177.54 3.84.143 6.355-.049 7.299-1.883 9.322-18.77 12.1-20.024 17.312-.218.899-.163 1.835-.056 2.334.319 1.428 1.288 2.989 4.066 2.989 2.616 0 4.139-1.602 4.139-4.032v-2.752h11.109v3.129c0 9.696-8.712 11.209-15.011 11.209-7.906 0-14.379-2.615-15.556-9.877-.325-1.986-.369-3.762.104-6C-18.115 8.39-2.293 5.764 0 0"
          fill="#000"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(192.09 39.57)"
        />
        <path
          d="M0 0c3.125 0 4.087 2.153 4.31 3.259.095.485.11 1.139.106 1.717v33.26h11.379V6c.016-.828-.07-2.519-.114-2.959C14.896-5.349 8.264-8.067-.001-8.067c-8.268 0-14.9 2.718-15.688 11.108-.04.44-.126 2.131-.104 2.959v32.236h11.371V4.976c-.011-.578.012-1.232.108-1.717C-4.096 2.153-3.131 0-.001 0"
          fill="#000"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(229.832 33.532)"
        />
        <path
          d="M0 0c3.256 0 4.392 2.061 4.595 3.263.092.507.114 1.135.107 1.705v6.529H.093v6.555h15.925V6c-.007-.847-.026-1.465-.163-2.959C15.111-5.149 8.013-8.071.045-8.071c-7.973 0-15.064 2.922-15.815 11.112-.129 1.494-.152 2.112-.155 2.959l.003 18.915c0 .798.097 2.208.19 2.959 1.002 8.404 7.804 11.109 15.78 11.109 7.973 0 14.953-2.679 15.781-11.109.145-1.432.097-2.959.104-2.959v-1.499H4.595v2.52c.004-.004-.008 1.065-.141 1.712-.204.992-1.058 3.263-4.495 3.263-3.27 0-4.231-2.153-4.48-3.263-.129-.591-.181-1.395-.181-2.127V4.968c-.003-.57.018-1.198.103-1.705C-4.388 2.061-3.252 0 0 0"
          fill="#000"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          transform="translate(323.603 34)"
        />
      </g>
    </svg>
  );
}

export default React.memo(SamsungPayIcon);
