import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/material.css";
import { FiLogIn } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import ExpectedTimeData from "./../../assets/Ecpected-time-data/expctedTimedata.json";
import axios from "axios";
import store from "../../redux/store";
const CheckoutUserForm = ({
  userData,
  userDataError,
  handlePersonalDetailsChange,
  handleCountryCodeChange,
  countryCode,
  countryCodeErr,
  firstnameRef,
  lastNameRef,
  confirmEmailRef,
  emailRef,
  countryCodeRef,
  mobileNoRef,
  chandleChangeExpectedTime,
  expectedTimeArrRef,
  handleCheckExistingUser,
  isEmailExits,
  handleShowLoginPopup,
  userAuthData,
  userDataArrival,
}) => {
  const [t] = useTranslation("common");
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  useEffect(() => {
    const insureVerification = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/user/get`,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              mobile: true,
              "Accept-Language": `${store.getState().language.language}`,
              Authorization:
                store.getState().user.currentUser != null
                  ? "Bearer " + store.getState().user.currentUser.token + ""
                  : "",
            },
          },
        );
        setIsEmailVerified(data?.data?.is_email_verified);
      } catch (e) {
        console.log(e);
        setIsEmailVerified(false);
      }
    };
    insureVerification();
  }, []);

  return (
    <div className="WL_persnal_wrap customform newpersonalwrap">
      <h4 className="WL_personalheading d-flex gap-2 align-items-center">
        {t("checkoutPage.checkoutUserForm.FillYourPersonalDetails")}{" "}
        {userAuthData == null && (
          <span className="user_login_link" onClick={handleShowLoginPopup}>
            <FiLogIn /> {t("checkoutPage.checkoutUserForm.Login")}
          </span>
        )}{" "}
      </h4>
      {/* <Form> */}
      <div className="form50">
        <Form.Group controlId="formCheckoutFirstname">
          <Form.Label>
            {t("checkoutPage.checkoutUserForm.FirstName")} *
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t("checkoutPage.checkoutUserForm.FirstName")}
            // required
            onChange={handlePersonalDetailsChange}
            value={userData.firstname}
            name="firstname"
            ref={firstnameRef}
          />
          <Form.Text className="text-muted">
            {userDataError.firstnameErr}
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="formCheckoutLastname">
          <Form.Label>{t("checkoutPage.checkoutUserForm.LastName")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("checkoutPage.checkoutUserForm.LastName")}
            // required
            name="lastname"
            onChange={handlePersonalDetailsChange}
            value={userData.lastname}
            ref={lastNameRef}
          />
          <Form.Text className="text-muted">
            {userDataError.lastnameErr}
          </Form.Text>
        </Form.Group>
      </div>

      <div className="form50">
        <Form.Group controlId="formCheckoutEmail">
          <Form.Label>
            {t("checkoutPage.checkoutUserForm.EmailAddress")} *
          </Form.Label>
          <Form.Control
            type="email"
            placeholder={t("checkoutPage.checkoutUserForm.EmailAddress")}
            // required
            name="email"
            onChange={handlePersonalDetailsChange}
            // value={userData.email}
            value={userData.email}
            ref={emailRef}
            onBlur={handleCheckExistingUser}
            disabled={isEmailVerified}
            // style={{border:isEmailExits==true?"1px solid red": ""}}
          />
          <Form.Text className="text-muted">{userDataError.emailErr}</Form.Text>
        </Form.Group>
        <Form.Group controlId="formCheckoutEmailadd">
          <Form.Label>
            {t("checkoutPage.checkoutUserForm.ConfirmEmailAddress")} *
          </Form.Label>
          <Form.Control
            type="email"
            placeholder={t("checkoutPage.checkoutUserForm.ConfirmEmailAddress")}
            // required
            name="confirmEmail"
            onChange={handlePersonalDetailsChange}
            value={userData.confirmEmail}
            ref={confirmEmailRef}
            disabled={isEmailVerified}
          />
          <Form.Text className="text-muted">
            {userDataError.confirmEmailErr}
          </Form.Text>
        </Form.Group>
      </div>

      <div className="form50 form100">
        <Form.Group controlId="formCheckoutPhone">
          <Form.Label>
            {t("checkoutPage.checkoutUserForm.Phonenumber")} *
          </Form.Label>

          <div className="phoneWithCountryCode">
            <Form.Group
              controlId="formCheckoutCountryCode"
              className="checkoutcountryCode"
            >
              <PhoneInput
                // defaultCountry="IN"
                autoFormat={false}
                enableSearch={true}
                excludeCountries={[
                  "cu",
                  "ru",
                  "kp",
                  "ua",
                  "ir",
                  "sd",
                  "ss",
                  "sy",
                ]}
                //country={"in"}
                value={countryCode}
                onChange={handleCountryCodeChange}
                name="countryCode"
                placeholder={"+966"}
                className="checkoutcountryCodeInput"
                ref={countryCodeRef}
              />
            </Form.Group>
            <Form.Group
              controlId="formCheckoutPoneNumber"
              className="checkoutPhoneNumber"
            >
              <Form.Control
                type="text"
                placeholder={t("checkoutPage.checkoutUserForm.Phonenumber")}
                // required
                name="mobileNo"
                onChange={handlePersonalDetailsChange}
                value={userData.mobileNo}
                ref={mobileNoRef}
                maxLength={10}
              />
            </Form.Group>
          </div>
          <Form.Text className="text-muted">
            {userDataError.mobileNoErr} {countryCodeErr}
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="formCheckoutArriv" className="timepicker">
          <Form.Label>
            {t("checkoutPage.checkoutUserForm.ExpectedTimeofArrival")}{" "}
          </Form.Label>

          <select
            onChange={(value) => chandleChangeExpectedTime(value)}
            name="datetime"
            id="datetime"
          >
            <option>Select Time</option>
            {ExpectedTimeData.map((data, index) => {
              return (
                <option value={data.value} key={index}>
                  {data.label}
                </option>
              );
            })}
          </select>
        </Form.Group>
      </div>
    </div>
  );
};

export default CheckoutUserForm;
