import React from "react";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet";

const BookingFailed = () => {
  return (
    <>

    {/* for SEO purpose */}
    <Helmet>
        <title>Point Purchase Failed - Transaction Error | Wfrlee</title>
        <meta name="description" content="Your point purchase transaction failed. Please try again or contact support for assistance. Ensure your payment details are correct and your account is active" />
      </Helmet>

    <div
      className="notFoundWrapper text-center"
      style={{
        minHeight: "70vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <h1 style={{ fontSize: "80px", marginBottom: "60px", opacity: ".5" }}>
          Point Purchase Failed
        </h1>
        <p className="headerTitle mt-2">Sorry, Your Wfrlee Point Purchase is Failed!</p>
      </div>
    </div>
    </>

  );
};

export default BookingFailed;
