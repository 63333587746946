import React from "react";
import { getAllNotificationsRequest } from "../../redux/useraccount/useraccount.actions";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectAllNotificationsData } from "../../redux/useraccount/useraccount.selectors";
import {
  selectSocialLoginUser,
  selectUserLoginData,
} from "../../redux/user/user.selectors";
import { selectlanguageToShow } from "./../../redux/language/language.selectors";
import MyNotificationsList from "../../components/MyAccount/MyNotificationsList";
import Pagination from "@mui/material/Pagination";
import { useTranslation } from "react-i18next";
import MyAccountLeftMenu from "../../components/MyAccount/MyAccountLeftMenu";
import { Helmet } from "react-helmet";

const MynotificationPage = ({
  getAllNotificationsRequest,
  notificationsData,
  languageToShow,
  userAuthData,
}) => {
  const [t] = useTranslation("common");
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const perPageLimit = 3;

  const data = {
    languageToShow: languageToShow,
    token: userAuthData != null ? userAuthData.token : "",
    skip: page,
    limit: perPageLimit,
  };

  React.useEffect(() => {
    getAllNotificationsRequest(data);
    setPage(1);
    setRowsPerPage(perPageLimit);
  }, [languageToShow]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    const data = {
      languageToShow: languageToShow,
      token: userAuthData != null ? userAuthData.token : "",
      skip: newPage,
      limit: perPageLimit,
    };
    getAllNotificationsRequest(data);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {/* for SEO purpose */}
      <Helmet>
        <title>Manage Notifications - Wfrlee User Settings</title>
        <meta
          name="description"
          content="Customize and control your notification preferences on Wfrlee to stay updated without the clutter. Manage alerts for updates, promotions, and more."
        />
      </Helmet>

      {/* //////////Myprofile page Section/////////// */}
      <div className="myaccountpage">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="accounttotalsection">
                <div className="leftmenu">
                  <div className="account-titlearea">
                    <h2>{t("MyAccount.heading")}</h2>
                    <p>{t("MyAccount.subHeading")}</p>
                  </div>
                  <MyAccountLeftMenu />
                </div>
                <div className="rightsection">
                  <h1>{t("MyAccount.NotificationsPage.heading")}</h1>
                  <div className="accountpoint notificaton">
                    {notificationsData != null ? (
                      <>
                        {(notificationsData?.data || []).map(
                          (myNotificationsList, index) => (
                            <MyNotificationsList
                              key={index}
                              myNotificationsList={myNotificationsList}
                            />
                          )
                        )}
                      </>
                    ) : (
                      <div className="bookingbox">
                        <div className="bookingboxlft">
                          <p>{t("MyAccount.NotificationsPage.NoDataFound")}</p>
                        </div>
                      </div>
                    )}

                    <Pagination
                      boundaryCount={1}
                      count={
                        notificationsData != null
                          ? Math.ceil(
                              notificationsData?.total_count / perPageLimit
                            )
                          : 0
                      }
                      defaultPage={1}
                      onChange={handleChangePage}
                      page={page}
                      showFirstButton={true}
                      showLastButton={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* //////////Myprofile page Section/////////// */}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  languageToShow: selectlanguageToShow,
  notificationsData: selectAllNotificationsData,
  userAuthData: selectUserLoginData,
  userSocialAuthData: selectSocialLoginUser,
});
const mapDispatchToProps = (dispatch) => ({
  getAllNotificationsRequest: (data) =>
    dispatch(getAllNotificationsRequest(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MynotificationPage);
