const HotelActionTypes = {
  GET_FULL_HOTELS_DETAILS_REQUEST: "GET_FULL_HOTELS_DETAILS_REQUEST",
  GET_FULL_HOTELS_DETAILS_SUCCESS: "GET_FULL_HOTELS_DETAILS_SUCCESS",
  GET_FULL_HOTELS_DETAILS_FAILED: "GET_FULL_HOTELS_DETAILS_FAILED",
  GET_HOTEL_LIST_SEARCH_START: "GET_HOTEL_LIST_SEARCH_START",
  GET_HOTEL_LIST_SEARCH_SUCCESS: "GET_HOTEL_LIST_SEARCH_SUCCESS",
  HOTEL_TOTAL_LIST_COUNT: "HOTEL_TOTAL_LIST_COUNT",
  GET_HOTEL_LIST_SEARCH_FAILURE: "GET_HOTEL_LIST_SEARCH_FAILURE",
  GET_HOTEL_ANIMITY_LIST_START: "GET_HOTEL_ANIMITY_LIST_START",
  GET_HOTEL_ANIMITY_LIST_SUCCESS: "GET_HOTEL_ANIMITY_LIST_SUCCESS",
  GET_HOTEL_ANIMITY_LIST_FAILURE: "GET_HOTEL_ANIMITY_LIST_FAILURE",
  ADD_TO_FAVOURITE_HOTEL_REQUEST: "ADD_TO_FAVOURITE_HOTEL_REQUEST",
  ADD_TO_FAVOURITE_HOTEL_SUCCESS: "ADD_TO_FAVOURITE_HOTEL_SUCCESS",
  ADD_TO_FAVOURITE_HOTEL_FAILED: "ADD_TO_FAVOURITE_HOTEL_FAILED",
  REMOVE_TO_FAVOURITE_HOTEL_REQUEST: "REMOVE_TO_FAVOURITE_HOTEL_REQUEST",
  REMOVE_TO_FAVOURITE_HOTEL_SUCCESS: "REMOVE_TO_FAVOURITE_HOTEL_SUCCESS",
  REMOVE_TO_FAVOURITE_HOTEL_FAILED: "REMOVE_TO_FAVOURITE_HOTEL_FAILED",
  GET_ALL_FAVOURITE_HOTEL_LIST_REQUEST: "GET_ALL_FAVOURITE_HOTEL_LIST_REQUEST",
  GET_ALL_FAVOURITE_HOTEL_LIST_SUCCESS: "GET_ALL_FAVOURITE_HOTEL_LIST_SUCCESS",
  GET_ALL_FAVOURITE_HOTEL_LIST_FAILED: "GET_ALL_FAVOURITE_HOTEL_LIST_FAILED",
  STATE_CLEAR_AFTER_HOTEL_LIST_FAVRITE_MSG:
    "STATE_CLEAR_AFTER_HOTEL_LIST_FAVRITE_MSG",
  STATE_AFTER_HOTEL_SEARCH_DATA_SAVE: "STATE_AFTER_HOTEL_SEARCH_DATA_SAVE",
  STATE_AFTER_HOTEL_SEARCH_DATA_REMOVE: "STATE_AFTER_HOTEL_SEARCH_DATA_REMOVE",
  STATE_SEARCH_START_AFTER_CLICK_ON_BUTTON:
    "STATE_SEARCH_START_AFTER_CLICK_ON_BUTTON",
  STATE_SEARCH_STOP_AFTER_CLICK_ON_BUTTON:
    "STATE_SEARCH_STOP_AFTER_CLICK_ON_BUTTON",
  STATE_SEARCH_ALL_SAVE_FILTER_DATA: "STATE_SEARCH_ALL_SAVE_FILTER_DATA",
  STATE_CLEAR_AFTER_HOTEL_LIST_FAVRITE_MSG:
    "STATE_CLEAR_AFTER_HOTEL_LIST_FAVRITE_MSG",
  STATE_AFTER_HOTEL_SEARCH_DATA_SAVE: "STATE_AFTER_HOTEL_SEARCH_DATA_SAVE",
  STATE_AFTER_HOTEL_SEARCH_DATA_REMOVE: "STATE_AFTER_HOTEL_SEARCH_DATA_REMOVE",
  STATE_SEARCH_START_AFTER_CLICK_ON_BUTTON:
    "STATE_SEARCH_START_AFTER_CLICK_ON_BUTTON",
  STATE_SEARCH_STOP_AFTER_CLICK_ON_BUTTON:
    "STATE_SEARCH_STOP_AFTER_CLICK_ON_BUTTON",

  /************* HOTELFILTER ADDD OR NOT FUNCTIONALITY  ************/
  HOTEL_FILTER_ADDED_OR_NOT_HANDLE: "HOTEL_FILTER_ADDED_OR_NOT_HANDLE",

  /************* HOTELFILTER ADDD OR NOT FUNCTIONALITY  ************/
  /************ Bid Checkout Details     *************** */
  GET_BID_CHECKOUT_DETAILS_REQUEST: "GET_BID_CHECKOUT_DETAILS_REQUEST",
  GET_BID_CHECKOUT_DETAILS_SUCCESS: "GET_BID_CHECKOUT_DETAILS_SUCCESS",
  GET_BID_CHECKOUT_DETAILS_FAILED: "GET_BID_CHECKOUT_DETAILS_FAILED",

  /************ Hourly Checkout Details     *************** */
  GET_HOURLY_CHECKOUT_DETAILS_REQUEST: "GET_HOURLY_CHECKOUT_DETAILS_REQUEST",
  GET_HOURLY_CHECKOUT_DETAILS_SUCCESS: "GET_HOURLY_CHECKOUT_DETAILS_SUCCESS",
  GET_HOURLY_CHECKOUT_DETAILS_FAILED: "GET_HOURLY_CHECKOUT_DETAILS_FAILED",

  /*********** Confirm Hotel Booking    ************/
  HOTEL_BOOKING_REQUEST: "HOTEL_BOOKING_REQUEST",
  HOTEL_BOOKING_SUCCESS: "HOTEL_BOOKING_SUCCESS",
  HOTEL_BOOKING_FAILED: "HOTEL_BOOKING_FAILED",

  /*********** Hotel Booking List Handleing **********/
  HOTEL_BOOKING_LIST_DATA_FETCH: "HOTEL_BOOKING_LIST_DATA_FETCH",
  HOTEL_BOOKING_LIST_DATA_SUCCESS: "HOTEL_BOOKING_LIST_DATA_SUCCESS",
  HOTEL_BOOKING_LIST_DATA_FAILURE: "HOTEL_BOOKING_LIST_DATA_FAILURE",

  /*********** Apply Wallet    ************/
  APPLY_WALLET_REQUEST: "APPLY_WALLET_REQUEST",
  APPLY_WALLET_SUCCESS: "APPLY_WALLET_SUCCESS",
  APPLY_WALLET_FAILED: "APPLY_WALLET_FAILED",

  /*********** Apply Promo Code    ************/
  APPLY_PROMO_CODE_REQUEST: "APPLY_PROMO_CODE_REQUEST",
  APPLY_PROMO_CODE_SUCCESS: "APPLY_PROMO_CODE_SUCCESS",
  APPLY_PROMO_CODE_FAILED: "APPLY_PROMO_CODE_FAILED",

  /*********** Bid Your Amount    ************/
  BID_YOUR_AMOUNT_REQUEST: "BID_YOUR_AMOUNT_REQUEST",
  BID_YOUR_AMOUNT_SUCCESS: "BID_YOUR_AMOUNT_SUCCESS",
  BID_YOUR_AMOUNT_FAILED: "BID_YOUR_AMOUNT_FAILED",

  /*********** Apply hotel Offers    ************/
  APPLY_HOTEL_OFFERS_REQUEST: "APPLY_HOTEL_OFFERS_REQUEST",
  APPLY_HOTEL_OFFERS_SUCCESS: "APPLY_HOTEL_OFFERS_SUCCESS",
  APPLY_HOTEL_OFFERS_FAILED: "APPLY_HOTEL_OFFERS_FAILED",

  /* PLACE WEATHER API REDUX WORKING  */
  HOTEL_WHEATHER_API_REQUEST: "HOTEL_WHEATHER_API_REQUEST",
  HOTEL_WHEATHER_API_SUCCESS: "HOTEL_WHEATHER_API_SUCCESS",
  HOTEL_WHEATHER_API_FAILURE: "HOTEL_WHEATHER_API_FAILURE",
  HOTEL_WHEATHER_API_DATA_REMOVE:"HOTEL_WHEATHER_API_DATA_REMOVE",
  /* PLACE WEATHER API REDUX WORKING  */

 /******** GET HOTEL PERFERENCE LIST  ************/  
 HOTEL_PERFERENCE_API_REQUEST: "HOTEL_PERFERENCE_API_REQUEST",
 HOTEL_PERFERENCE_API_SUCCESS: "HOTEL_PERFERENCE_API_SUCCESS",
 HOTEL_PERFERENCE_API_FAILURE: "HOTEL_PERFERENCE_API_FAILURE",
 /******** GET HOTEL PERFERENCE LIST  ************/

  STATE_CLEAR_AFTER_TASK: "STATE_CLEAR_AFTER_TASK",
  /* Place search save  */
  HOTEL_SEARCH_STATE_SAVE: "HOTEL_SEARCH_STATE_SAVE",
  /* Place search save  */

  /***** Save checkout guest User Form Data   ***/
  SAVE_GUEST_USER_CHECKOUT_DATA: "SAVE_GUEST_USER_CHECKOUT_DATA",
  /***** End Save checkout guest User Form Data   ***/

  /*********** HOTEL RE-SEND CONFIRMATION **********/
  HOTEL_RESEND_CONFIRMATION_REQUEST: "HOTEL_RESEND_CONFIRMATION_REQUEST",
  HOTEL_RESEND_CONFIRMATION_SUCCESS: "HOTEL_RESEND_CONFIRMATION_SUCCESS",
  HOTEL_RESEND_CONFIRMATION_FAILED: "HOTEL_RESEND_CONFIRMATION_FAILED",


  /************** Hotel List BidNow States Handle  ****************/
  HOTEL_ROUTE_STATE_HANDLE_REQUEST: "HOTEL_ROUTE_STATE_HANDLE_REQUEST",

  /************** Hotel List BidNow States Handle ****************/
  /************** Hotel List BidDetails States Handle ****************/ 
  HOTEL_ROUTE_STATE_HANDLE_REQUEST_DETAILS: "HOTEL_ROUTE_STATE_HANDLE_REQUEST_DETAILS",
  /************** Hotel List BidDetails States Handle ****************/
  /***************** HOTEL PLACE HANDLER ************************/
  HOTEL_PLACE_DATA_STATE_HANDLE: "HOTEL_PLACE_DATA_STATE_HANDLE",
  /***************** HOTEL PLACE HANDLER ************************/
  /************ Hotel Offers Handler ***************/
  HOTEL_OFFERS_HANDLER_STATE:"HOTEL_OFFERS_HANDLER_STATE",
  HOTEL_OFFERS_REMOVE_HANDLER_STATE:"HOTEL_OFFERS_REMOVE_HANDLER_STATE",
  /************ Hotel Offers Handler ***************/
  /************ Hotel GEOLOCATION Handler ***************/
  HOTEL_GETLOCATION_UPLOADED:"HOTEL_GETLOCATION_UPLOADED",
  /************ Hotel GEOLOCATION Handler ***************/
  /******** HOTEL PAGINATION START *********/
  GET_HOTEL_PAGINATION_LIST_SEARCH_START: "GET_HOTEL_PAGINATION_LIST_SEARCH_START",
  /******** HOTEL PAGINATION START *********/

  /********** HOTEL LIST UPDATE AFTER ADD FAVORITE OR REMOVE FAVORITE *********/
  GET_HOTEL_LIST_UPDATE_AFTER_ADD_OR_REMOVE_FAVORITE:"GET_HOTEL_LIST_UPDATE_AFTER_ADD_OR_REMOVE_FAVORITE",
  /********** HOTEL LIST UPDATE AFTER ADD FAVORITE OR REMOVE FAVORITE *********/
  /******* HOTEL RATING AND REVIEW REQUEST SUBMIT  *******/
  POST_HOTEL_RATING_REVIEW_REQUEST:"POST_HOTEL_RATING_REVIEW_REQUEST",
  /******* HOTEL RATING AND REVIEW REQUEST SUBMIT  *******/
  /******* HOTEL RATING AND REVIEW REQUEST SUCCESS  *******/
  POST_HOTEL_RATING_REVIEW_SUCCESS:"POST_HOTEL_RATING_REVIEW_SUCCESS",
  /******* HOTEL RATING AND REVIEW REQUEST SUCCESS  *******/
  /******* HOTEL RATING AND REVIEW REQUEST FAILURE  *******/
  POST_HOTEL_RATING_REVIEW_FAILURE:"POST_HOTEL_RATING_REVIEW_FAILURE",
  /******* HOTEL RATING AND REVIEW REQUEST FAILURE  *******/ 

  /******* HOTEL BOOKING DETAILS REQUEST   *******/ 
  HOTEL_BOOKING_DETAILS_REQUEST:"HOTEL_BOOKING_DETAILS_REQUEST",
  /******* HOTEL BOOKING DETAILS REQUEST   *******/ 
  /******* HOTEL BOOKING DETAILS REQUEST   *******/ 
  HOTEL_BOOKING_DETAILS_SUCCESS:"HOTEL_BOOKING_DETAILS_SUCCESS",
  /******* HOTEL BOOKING DETAILS REQUEST   *******/ 
  /************** HOTEL CANCELATION REQUEST ****************/
  HOTEL_BIDDING_CENCEL_REQUEST:"HOTEL_BIDDING_CENCEL_REQUEST",
  /************** HOTEL CANCELATION REQUEST ****************/
  /************** HOTEL CANCELATION SUCCESS ****************/
  HOTEL_BIDDING_CENCEL_SUCCESS:"HOTEL_BIDDING_CENCEL_SUCCESS",
  /************** HOTEL CANCELATION SUCCESS ****************/
  /************** HOTEL CANCELATION FAILED ****************/
  HOTEL_BIDDING_CENCEL_FAILED:"HOTEL_BIDDING_CENCEL_FAILED",
  /************** HOTEL CANCELATION FAILED ****************/
  /********** HOTEL STAR API CALL REQUEST *******/ 
  HOTEL_STAR_API_FILTER_REQUEST:"HOTEL_STAR_API_FILTER_REQUEST",
  /********** HOTEL STAR API CALL REQUEST *******/
  /********** HOTEL STAR API CALL SUCCESS *******/ 
  HOTEL_STAR_API_FILTER_SUCCESS:"HOTEL_STAR_API_FILTER_SUCCESS",
  /********** HOTEL STAR API CALL SUCCESS *******/
  /********** HOTEL STAR API CALL FAILURE *******/ 
  HOTEL_STAR_API_FILTER_FAILURE:"HOTEL_STAR_API_FILTER_FAILURE"
  /********** HOTEL STAR API CALL FAILURE *******/
};

export default HotelActionTypes;
