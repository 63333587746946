import { createSelector } from "reselect";


const selectHome = (state) => state.home;

export const selectofferspromotionsdestinationData = createSelector(
  [selectHome],
  (home) => home.offer_promotions_partners_preferenceData
);


export const selectofferLoading = createSelector(
[selectHome],
(home) => home.loading
);

export const selectPreference = createSelector(
[selectHome],
(home) => home.perference
);

export const selectRandomReview = createSelector(
[selectHome],
(home) => home.randomreview
);