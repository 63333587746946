const UserActionTypes = {
  SET_CURRENT_USER: "SET_CURRENT_USER",
  GOOGLE_SIGN_IN_START: "GOOGLE_SIGN_IN_START",
  EMAIL_SIGN_IN_START: "EMAIL_SIGN_IN_START",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  SIGN_IN_FAILURE: "SIGN_IN_FAILURE",
  CHECK_USER_SESSION: "CHECK_USER_SESSION",
  SIGN_OUT_START: "SIGN_OUT_START",
  SIGN_OUT_SUCCESS: "SIGN_OUT_SUCCESS",
  SIGN_OUT_FAILURE: "SIGN_OUT_FAILURE",
  SIGN_UP_START: "SIGN_UP_START",
  SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
  SIGN_UP_FAILURE: "SIGN_UP_FAILURE",
  EMAIL_VERIFICATION_START: "EMAIL_VERIFICATION_START",
  EMAIL_VERIFICATION_SUCCESS: "EMAIL_VERIFICATION_SUCCESS",
  EMAIL_VERIFICATION_FAILURE: "EMAIL_VERIFICATION_FAILURE",
  STATE_CLEAR_AFTER_TASK: "STATE_CLEAR_AFTER_TASK",
  SOCIAL_LOGIN_REQUEST: "SOCIAL_LOGIN_REQUEST",
  SOCIAL_LOGIN_SUCCESS: "SOCIAL_LOGIN_SUCCESS",
  SOCIAL_LOGIN_FAILED: "SOCIAL_LOGIN_FAILED",
  USER_LOGOUT_START:"USER_LOGOUT_START",
  USER_LOGOUT_SUCCESS:"USER_LOGOUT_SUCCESS",
  USER_LOGOUT_FAILURE:"USER_LOGOUT_FAILURE",
  FORGOT_PASSWORD_START:"FORGOT_PASSWORD_START",
  FORGOT_PASSWORD_SUCCESS:"FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE:"FORGOT_PASSWORD_FAILURE",
  FORGOT_PASSWORD_RESET:"FORGOT_PASSWORD_RESET",
  RESET_PASSWORD_START:"RESET_PASSWORD_START",
  RESET_PASSWORD_SUCCESS:"RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE:"RESET_PASSWORD_FAILURE",
  RESET_PASSWORD_AFTER_FORGOT_START:"RESET_PASSWORD_AFTER_FORGOT_START",
  RESET_PASSWORD_AFTER_FORGOT_SUCCESS:"RESET_PASSWORD_AFTER_FORGOT_SUCCESS",
  RESET_PASSWORD_AFTER_FORGOT_FAILURE:"RESET_PASSWORD_AFTER_FORGOT_FAILURE",
  USER_PROFILE_UPDATE_REQUEST:"USER_PROFILE_UPDATE_REQUEST",
  USER_PROFILE_UPDATE_SUCCESS:"USER_PROFILE_UPDATE_SUCCESS",
  USER_PROFILE_UPDATE_FAILURE:"USER_PROFILE_UPDATE_FAILURE",
  USER_PROFILE_IMAGE_UPLOAD_REQUEST:"USER_PROFILE_IMAGE_UPLOAD_REQUEST",
  USER_PROFILE_IMAGE_UPLOAD_SUCCESS:"USER_PROFILE_IMAGE_UPLOAD_SUCCESS",
  USER_PROFILE_IMAGE_UPLOAD_FAILURE:"USER_PROFILE_IMAGE_UPLOAD_FAILURE",
  USER_PROFILE_IMAGE_UPLOAD_MESSAGE_CLEAR:"USER_PROFILE_IMAGE_UPLOAD_MESSAGE_CLEAR",
  USER_PROFILE_DATA_REQUEST:"USER_PROFILE_DATA_REQUEST",
  USER_PROFILE_DATA_SUCCESS:"USER_PROFILE_DATA_SUCCESS",
  USER_PROFILE_DATA_FAILURE:"USER_PROFILE_DATA_FAILURE",
  USER_PROFILE_DATA_UPDATE_THROUGH_PROFILE:"USER_PROFILE_DATA_UPDATE_THROUGH_PROFILE",


   /***** Save checkout guest User Form Data   ***/
   SAVE_GUEST_USER_CHECKOUT_DATA: "SAVE_GUEST_USER_CHECKOUT_DATA",
   /***** End Save checkout guest User Form Data   ***/

    /*********** VALIDATE USER EMAIL **********/
    VALIDATE_USER_EMAIL_REQUEST: "VALIDATE_USER_EMAIL_REQUEST",
    VALIDATE_USER_EMAIL_SUCCESS: "VALIDATE_USER_EMAIL_SUCCESS",
    VALIDATE_USER_EMAIL_FAILED: "VALIDATE_USER_EMAIL_FAILED",

    VALIDATE_EMAIL_STATE_CLEAR_AFTER_TASK: "VALIDATE_EMAIL_STATE_CLEAR_AFTER_TASK",
  
};

export default UserActionTypes;
