import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import OffersDetailsModal from "./OffersDetailsModal";
import { useTranslation } from "react-i18next";

const OffersCard = ({
  shareCode,
  setShareCode,
  onCopy,
  isCopied,
  setIsCopied,
  cardIndex,
  couponData,
}) => {
  const [t] = useTranslation("common");
  var [show, setShow] = useState(false);
  var handleClose = () => {
    setShow(false);
    var couponDialogId = document.getElementById("couponListModal");
    couponDialogId.style.display = "block";
  };
  var handleShow = () => setShow(true);
  

  const resnderCopyElement = () => {
    return (
      <div className="inner_offercardbox_footer">
        

        <div className="field copyToClipboardArea  d-flex align-items-center mb-2">
          
          {isCopied == true ? (
            <span className="px-2"> #{couponData.code}</span>
          ) : (
            ""
          )}
          <CopyToClipboard
            text={couponData.code}
            onCopy={() => onCopy(true, cardIndex)}
          >
            <Button
              className={"copyLinkBtn"}
              disabled={isCopied == true ? true : false}
            >
              {isCopied == true ? "Copied" : couponData.code}
            </Button>
          </CopyToClipboard>
        </div>
      </div>
    );
  };

  return (
    <div className="inner_mbox_offercard">
      <div className="inner_offercardbox">
        <div
          className="inner_offerbox_image_text"
          style={{ cursor: "pointer" }}
        >
          <img src={couponData?.image} alt="" />
          <div
            onClick={() => {
              setShow(true);
              var couponDialogId = document.getElementById("couponListModal");
              couponDialogId.style.display = "none";
            }}
          >
            <h4>
              <strong>
                {couponData?.name}: {t("OffersPage.Getupto")}{" "}
                {couponData?.discount_amount}
                {couponData?.discount_type == "percentage"
                  ? "%"
                  : " " + couponData?.discount_type}{" "}
                {t("OffersPage.OFF*")}
                {/* on your first hotel booking */}
              </strong>
            </h4>
          </div>
          {resnderCopyElement()}
        </div>
        

        <div>
          {couponData?.hotels != null
            ? couponData?.hotels.length > 0
              ? (couponData?.hotel || []).map((hotel, index) => (
                  <p key={index}>{hotel}</p>
                ))
              : ""
            : ""}
        </div>

        
      </div>
      

      <OffersDetailsModal
        show={show}
        handleClose={handleClose}
        offersData={couponData}
        outlet={resnderCopyElement()}
      />
    </div>
  );
};

export default OffersCard;
