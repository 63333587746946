import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { selectlanguageToShow } from "../../redux/language/language.selectors";
import { getPerferenceDataList } from "./../../redux/home/home.actions";
import {
  handleSearchRequestData,
  searchFilterAddOrNot,
  selectFavouriteHotelSearchData,
  selectHotelAminityData,
  selectHotelCity,
  selectHotelDataList,
  selectHotelStarRatingFilterStarSelect,
  selectPlaceDataHandleData,
  selectPreferenceData,
} from "./../../redux/hotels/hotel.selectors";

import MultiRangeSlider from "multi-range-slider-react";
import { useTranslation } from "react-i18next";
import "react-loading-skeleton/dist/skeleton.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import BidNowList from "../../components/BidNowList/BidNowList";
import {
  getHotelAnimityStart,
  getHotelListByPagination,
  getHotelListRequest,
  hotelPerferenceListHandler,
  hotelRouteStateHandler,
  hotelStarFilterApiCallRequest,
  hotelToggleAddOrNot,
  hotelWheatherApiDataRemove,
  placeStateDataSave,
  searchFilterDataSave,
  stateClearAfterAdd,
} from "../../redux/hotels/hotel.actions";
import GoogleMaps from "./../../components/googleMaps/googleMaps";
import HotelSearchForOthers from "./../../components/HeaderSearch/HeaderSearch";
import { selectPreference } from "./../../redux/home/home.selectors";
import {
  hotelRouteDetailsStateHandler,
  hotelWheatherApiRequest,
  searchDataRemove,
} from "./../../redux/hotels/hotel.actions";
import {
  hotelListDataLoading,
  selectFavouriteHotel,
  selectHotelBidNowPageRouteData,
  selectHotelDetailsPageRouteData,
  TotalHotelPageCount,
} from "./../../redux/hotels/hotel.selectors";
import { selectCurrentUser } from "./../../redux/user/user.selectors";
const ListingbidnowPage = ({
  selectFavouriteHotel,
  searchDataRemove,
  languageToShow,
  getHotelListRequest,
  selectHotelDataList,
  getHotelAnimityStart,
  slectAminityData,
  hotelListDataLoading,
  searchFilterDataSave,
  saveloadeddata,
  searchFilterAddOrNot,
  hotelToggleAddOrNot,
  TotalHotelPageCount,
  placeStateDataSave,
  selectHotelCity,
  hotelWheatherApiRequest,
  hotelRouteStateHandler,
  selectHotelBidNowPageRouteData,
  selectHotelDetailsPageRouteData,
  hotelRouteDetailsStateHandler,
  selectPlaceDataHandleData,
  hotelPerferenceListHandler,
  selectPreferenceData,
  selectPreference,
  getHotelListByPagination,
  getPerferenceDataList,
  hotelWheatherApiDataRemove,
  hotelStarFilterApiCallRequest,
  starselectFilter,
}) => {
  var listSlide = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
  };
  const MySwal = withReactContent(Swal);
  const [searchParams] = useSearchParams();
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAminity, setSelectedAminity] = useState([]);
  const [selectedPerference, setSelectedPerference] = useState([]);
  const [selectStar, setSelectStar] = useState([]);
  const [hotelBookingPrice, setHotelBookingPrice] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(10000);
  const [minValue2, setMinValue2] = useState(0);
  const [maxValue2, setMaxValue2] = useState(10000);
  const [minValueIni1, set_minValueIni1] = useState(0);
  const [maxValueIni2, set_maxValueIni2] = useState(5000);
  const [shortBy, setShortBy] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const listInnerRef = useRef();
  const [currPage, setCurrPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [userList, setUserList] = useState([]);
  const [lastList, setLastList] = useState(false);
  const [mapPopUp, setMapPopUp] = useState(false);
  const [shortByArrow, setShortByArrow] = useState(false);
  const [place, setPlace] = useState(
    searchParams.get("city") == null ? null : searchParams.get("city")
  );
  const [longaddress, setLongaddress] = useState(
    selectPlaceDataHandleData?.place != null
      ? selectPlaceDataHandleData?.place
      : searchParams.get("city") == null
      ? null
      : searchParams.get("city")
  );
  const [t] = useTranslation("common");

  useEffect(() => {
    const pagesavg = (TotalHotelPageCount + 11 - 1) / 10;
    const pagesRound = Math.round(pagesavg);
    setTotalPages(pagesRound);
  }, [TotalHotelPageCount]);

  useEffect(() => {
    if (selectPlaceDataHandleData?.place != undefined) {
      setPlace(selectPlaceDataHandleData?.place);
      setLongaddress(selectPlaceDataHandleData?.place);
    }
  }, [selectPlaceDataHandleData]);

  /***** Hotel Wheather API and Aminity Data Request ********/
  useEffect(() => {
    const data = {
      language: languageToShow,
      search_type: searchParams.get("search_type"),
      city: place,
      book_for: searchParams.get("book_for"),
      check_in_date: searchParams.get("check_in_date"),
      check_out_date: searchParams.get("check_out_date"),
      adults: searchParams.get("adults"),
      children: searchParams.get("children"),
      rooms: searchParams.get("rooms"),
    };

    if (languageToShow != "undefined") {
      getHotelAnimityStart(data);
      hotelStarFilterApiCallRequest();
    }
  }, [languageToShow]);

  /********** Wheather API calling Method Change **********/
  /**********    New Method    **************/
  useEffect(() => {
    if (selectHotelDataList != undefined && selectHotelDataList != null) {
      hotelWheatherApiRequest(
        selectHotelDataList?.hotels[0]?.address.city_village
      );
    } else {
      hotelWheatherApiDataRemove();
    }
  }, [selectHotelDataList]);
  /**********    New Method    **************/
  /********** Wheather API calling Method Change **********/

  /***** Hotel Wheather API and Aminity Data Request ********/

  /***** Hotel  Aminity List Check Box Calculation ********/
  const changeAminityselectaminity = (id) => {
    const selectedCheckboxes = selectedAminity;

    const findIdx = selectedCheckboxes.indexOf(id);
    if (findIdx > -1) {
      setSelectedAminity((prev) => prev.filter((fruit) => fruit !== id));
      //   selectedCheckboxes.splice(findIdx, 1);
    } else {
      //  selectedCheckboxes.push(id);
      setSelectedAminity((prev) => [...prev, id]);
    }
    // setSelectedAminity(selectedCheckboxes);
  };
  /***** Hotel  Aminity List Check Box Calculation ********/

  /***** Hotel  Preference List Calculation ********/
  const changeSelectListPerference = (id) => {
    const selectedCheckboxes = selectedPerference;

    const findIdx = selectedCheckboxes.indexOf(id);
    if (findIdx > -1) {
      setSelectedPerference((prev) => prev.filter((fruit) => fruit !== id));
      //   selectedCheckboxes.splice(findIdx, 1);
    } else {
      //  selectedCheckboxes.push(id);
      setSelectedPerference((prev) => [...prev, id]);
    }
  };
  /***** Hotel  Preference List Calculation ********/

  /*** Preference Selected Value ****/
  useEffect(() => {
    if (selectPreference != null) {
      //  setSelectedPerference([selectPreference])
      setSelectedPerference((oldArray) => [...oldArray, selectPreference]);
    }
  }, [selectPreference]);
  /*** Preference Selected Value ****/

  /****** Hotel Price Handleing ********/
  const handlePrice = (e) => {
    setHotelBookingPrice(e.target.value);
  };
  /****** Hotel Price Handleing ********/

  /****** Hotel Star Select  Handleing ********/
  const handlestar = (star) => {
    const selectedCheckboxes = selectStar;
    const findIdx = selectedCheckboxes.indexOf(star);
    if (findIdx > -1) {
      // selectedCheckboxes.splice(findIdx, 1);
      setSelectStar((prev) => prev.filter((fruit) => fruit !== star));
    } else {
      // selectedCheckboxes.push(star);
      // setSelectStar(selectedCheckboxes);
      setSelectStar((prev) => [...prev, star]);
    }
  };
  /****** Hotel Star Select  Handleing ********/

  /****** Hotel List Data Request Initial   *******/
  useEffect(() => {
    const filters = {
      shortby: shortBy,
      price: [parseInt(minValueIni1), parseInt(maxValueIni2)],
      amenity: selectedAminity,
      star: selectStar,
    };
    searchFilterDataSave(filters);
    delete filters.shortby;
    const data = {
      filter: searchFilterAddOrNot,
      language: languageToShow,
      search_type: searchParams.get("search_type"),
      city: place,
      book_for: searchParams.get("book_for"),
      check_in_date: searchParams.get("check_in_date"),
      check_out_date: searchParams.get("check_out_date"),
      adults: searchParams.get("adults"),
      children: searchParams.get("children"),
      rooms: searchParams.get("rooms"),
      sort_by: shortBy,
      filters: encodeURIComponent(JSON.stringify(filters)),
    };
    placeStateDataSave(longaddress);
    getHotelListRequest(data);
    hotelPerferenceListHandler({ language: languageToShow });
  }, [languageToShow]);

  /****** Hotel List Data Request Initial   *******/

  /****** Hotel List Data Request Reset Filter  *******/
  const handleResetEvent = (time) => {
    var startDate = new Date(saveloadeddata.checkInTime);
    var endDate = new Date(saveloadeddata.checkOutTime);
    if (place.length == 0) {
      if (time == 1) {
        MySwal.fire({
          confirmButtonText: "OK",
          confirmButtonColor: "#296da9",
          text: t("hotelList.PlaceValidationMsg"),
        });
        // errorToast(t("hotelList.PlaceValidationMsg"));
      }
      return;
    }
    if (saveloadeddata.searchType == "bid" && endDate.getTime() == "0") {
      if (time == 1) {
        MySwal.fire({
          confirmButtonText: "OK",
          confirmButtonColor: "#296da9",
          text: t("headerSearch.search.checkoutdateValidationMsg"),
        });
        // errorToast(t("headerSearch.search.checkoutdateValidationMsg"));
      }
      return;
    } else if (
      saveloadeddata.searchType == "bid" &&
      endDate.getTime() <= startDate.getTime()
    ) {
      if (time == 1) {
        MySwal.fire({
          confirmButtonText: "OK",
          confirmButtonColor: "#296da9",
          text: t("headerSearch.search.dateValidationMsg"),
        });
        //  errorToast(t("headerSearch.search.dateValidationMsg"));
      }
      return;
    }
    setSelectedAminity([]);
    setSelectedPerference([]);
    setShortBy("");
    set_minValueIni1(0);
    set_maxValueIni2(5000);
    setSelectStar([]);
    hotelToggleAddOrNot(false);
    const filters = {
      price: [parseInt(minValueIni1), parseInt(maxValueIni2)],
      amenity: selectedAminity,
      star: selectStar,
    };
    const data = {
      filter: false,
      language: languageToShow,
      search_type: saveloadeddata.searchType,
      city: place,
      book_for: saveloadeddata.getHours,
      check_in_date: saveloadeddata.checkInTime,
      check_out_date: saveloadeddata.checkOutTime,
      adults: saveloadeddata.roomdata.adults,
      children: saveloadeddata.roomdata.child,
      rooms: saveloadeddata.roomdata.rooms,
      sort_by: shortBy,
      filters: encodeURIComponent(JSON.stringify(filters)),
    };
    placeStateDataSave(longaddress);
    getHotelListRequest(data);
    getPerferenceDataList(null);
    if (matches) {
      window.scroll(0, 50);
    } else {
      window.scroll(0, 1800);
    }
  };
  /****** Hotel List Data Request Reset Filter  *******/

  /****** Hotel List Data Request Apply  Filter  *******/
  const handleApplyEvent = (time) => {
    var startDate = new Date(saveloadeddata.checkInTime);
    var endDate = new Date(saveloadeddata.checkOutTime);
    if (place.length == 0) {
      if (time == 1) {
        MySwal.fire({
          confirmButtonText: "OK",
          confirmButtonColor: "#296da9",
          text: t("hotelList.PlaceValidationMsg"),
        });
        //errorToast(t("hotelList.PlaceValidationMsg"));
      }
      return;
    }
    if (saveloadeddata.searchType == "bid" && endDate.getTime() == "0") {
      if (time == 1) {
        MySwal.fire({
          confirmButtonText: "OK",
          confirmButtonColor: "#296da9",
          text: t("headerSearch.search.checkoutdateValidationMsg"),
        });
        //  errorToast(t("headerSearch.search.checkoutdateValidationMsg"));
      }
      return;
    } else if (
      saveloadeddata.searchType == "bid" &&
      endDate.getTime() <= startDate.getTime()
    ) {
      if (time == 1) {
        MySwal.fire({
          confirmButtonText: "OK",
          confirmButtonColor: "#296da9",
          text: t("headerSearch.search.dateValidationMsg"),
        });
        // errorToast(t("headerSearch.search.dateValidationMsg"));
      }
      return;
    }
    const filters = {
      price: [parseInt(minValueIni1), parseInt(maxValueIni2)],
      amenity: selectedAminity,
      star: selectStar,
    };

    const data = {
      filter: true,
      language: languageToShow,
      search_type: saveloadeddata.searchType,
      city: place,
      book_for: saveloadeddata.getHours,
      check_in_date: saveloadeddata.checkInTime,
      check_out_date: saveloadeddata.checkOutTime,
      adults: saveloadeddata.roomdata.adults,
      children: saveloadeddata.roomdata.child,
      rooms: saveloadeddata.roomdata.rooms,
      sort_by: shortBy,
      filters: encodeURIComponent(JSON.stringify(filters)),
    };
    placeStateDataSave(longaddress);
    getHotelListRequest(data);
    hotelToggleAddOrNot(true);
    if (selectedPerference.length > 0) {
      const datapreference = selectedPerference.filter(function (obj) {
        return typeof obj != "object";
      });
      getPerferenceDataList(datapreference);
    } else {
      getPerferenceDataList(null);
    }
    if (matches) {
      window.scroll(0, 50);
    } else {
      window.scroll(0, 1800);
    }
  };
  /****** Hotel List Data Request Apply  Filter  *******/

  useEffect(() => {
    if (
      selectFavouriteHotel != null &&
      (selectFavouriteHotel.message == "Hotel added to favourite." ||
        selectFavouriteHotel.message == "Hotel removed from favourite.")
    ) {
    }
  }, [selectFavouriteHotel]);

  /********* Hotel Aminity, Price , star Update detection  **********/
  useEffect(() => {
    const filters = {
      shortby: shortBy,
      price: [parseInt(minValueIni1), parseInt(maxValueIni2)],
      amenity: selectedAminity,
      star: selectStar,
    };
    searchFilterDataSave(filters);
  }, [
    parseInt(minValueIni1),
    parseInt(maxValueIni2),
    selectedAminity,
    shortBy,
    selectStar,
  ]);
  /********* Hotel Aminity, Price , star Update detection  **********/

  /******* Hotel Search Data Remove ********/
  useEffect(() => {
    return () => {
      searchDataRemove();
    };
  }, []);
  /******* Hotel Search Data Remove ********/

  /***** scroll on Top  ***/
  const nearToTop = () => {
    window.scroll(0, 0);
  };
  /***** scroll on Top  ***/

  const radioChangeHandler = (e) => {
    setShortBy(e.target.value);
  };
  const handlePagination = (pages) => {
    if (pages <= totalPages) {
      setCurrentPage(pages);
    }
  };

  window.onscroll = function (ev) {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
    }
  };
  const handleshortby = () => {
    setShortByArrow(!shortByArrow);
  };

  useEffect(() => {
    setTimeout(() => {
      hotelRouteDetailsStateHandler(null);
    }, 3000);
  }, []);

  const handleMapClick = () => {
    setMapPopUp(true);
  };
  const handleMapClose = () => {
    setMapPopUp(false);
  };

  /****** Hotel Data Request Through Pagination ******/
  const fetchData = () => {
    if (
      saveloadeddata.checkOutTime == null &&
      saveloadeddata.searchType == "bid"
    ) {
      return;
    }

    if (saveloadeddata.checkOutTime != null) {
      const filters = {
        price: [parseInt(minValueIni1), parseInt(maxValueIni2)],
        amenity: selectedAminity,
        star: selectStar,
      };
      const data = {
        filter: searchFilterAddOrNot,
        language: languageToShow,
        search_type: saveloadeddata.searchType,
        city: place,
        book_for: saveloadeddata.getHours,
        check_in_date: saveloadeddata.checkInTime,
        check_out_date: saveloadeddata.checkOutTime,
        adults: saveloadeddata.roomdata.adults,
        children: saveloadeddata.roomdata.child,
        rooms: saveloadeddata.roomdata.rooms,
        sort_by: shortBy,
        page: Math.ceil(selectHotelDataList.hotels.length / 10) + 1,
        filters: encodeURIComponent(JSON.stringify(filters)),
      };
      getHotelListByPagination(data);
    } else {
      const filters = {
        price: [parseInt(minValueIni1), parseInt(maxValueIni2)],
        amenity: selectedAminity,
        star: selectStar,
      };
      const data = {
        filter: searchFilterAddOrNot,
        language: languageToShow,
        search_type: saveloadeddata.searchType,
        city: place,
        book_for: saveloadeddata.getHours,
        check_in_date: saveloadeddata.checkInTime,
        check_out_date: saveloadeddata.checkOutTime,
        adults: saveloadeddata.roomdata.adults,
        children: saveloadeddata.roomdata.child,
        rooms: saveloadeddata.roomdata.rooms,
        sort_by: shortBy,
        page: Math.ceil(selectHotelDataList.hotels.length / 10) + 1,
        filters: encodeURIComponent(JSON.stringify(filters)),
      };
      getHotelListByPagination(data);
    }
  };

  /****** Hotel Data Request Through Pagination ******/
  const matches = useMediaQuery("(min-width:850px)");

  return (
    <>
      {/* for SEO purpose */}
      <Helmet>
        <title>Bid on Listings Now - Wfrlee Auction Platform</title>
        <meta
          name="description"
          content="Win exclusive hotels, services & experiences! Bid in real-time on Wfrlee's auction platform."
        />
      </Helmet>

      {/* inner banner start */}

      <div className="inner-banner">
        <div className="container">
          {/* search hotel start */}
          <div className="row">
            <div className="col-lg-12">
              <div className="search-hotel search-list-header">
                <HotelSearchForOthers />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* inner banner end */}

      {/* listing page start */}

      <div className="listing-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              {/* sort by start */}
              <div className="sort-by">
                <Dropdown>
                  <Dropdown.Toggle variant="" id="sort">
                    <label style={{ paddingTop: "5px" }}>
                      {shortBy != "" && shortBy == "recommended"
                        ? t("hotelList.sortBy.Recommended")
                        : shortBy != "" && shortBy == "price_low_to_high"
                        ? t("hotelList.sortBy.PriceLowtoHigh")
                        : shortBy != "" && shortBy == "price_high_to_low"
                        ? t("hotelList.sortBy.PriceHightoLow")
                        : t("hotelList.sortBy.SortBy")}
                    </label>{" "}
                    {/* { shortByArrow ?  */}
                    <img
                      src="./img/up-arrow.svg"
                      alt="arrow up down"
                      className="upArrow"
                      onClick={handleshortby}
                    />
                    {/* : */}
                    <img
                      src="./img/down-arrow.svg"
                      alt="arrow up down"
                      className="downArrow"
                      onClick={handleshortby}
                    />
                    {/* } */}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className={
                      shortBy != "" ? "hideDropdownMenu" : "showDropdownMenu"
                    }
                  >
                    <Form>
                      {["radio"].map((type) => (
                        <div key={`inline-${type}`}>
                          <Form.Check
                            label={t("hotelList.sortBy.Recommended")}
                            name="group1"
                            type={type}
                            value="recommended"
                            id={`inline-${type}-1`}
                            onChange={radioChangeHandler}
                            checked={shortBy == "recommended"}
                          />

                          <Form.Check
                            label={t("hotelList.sortBy.PriceLowtoHigh")}
                            value="price_low_to_high"
                            name="group1"
                            type={type}
                            id={`inline-${type}-3`}
                            onChange={radioChangeHandler}
                            checked={shortBy == "price_low_to_high"}
                          />

                          <Form.Check
                            label={t("hotelList.sortBy.PriceHightoLow")}
                            value="price_high_to_low"
                            name="group1"
                            type={type}
                            id={`inline-${type}-4`}
                            onChange={radioChangeHandler}
                            checked={shortBy == "price_high_to_low"}
                          />
                        </div>
                      ))}
                    </Form>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {/* sort by end */}

              {/* Price Range start */}
              <div className="content-box">
                <div className="title">{t("hotelList.SelectPriceRange")}</div>
                <div className="price-range">
                  <MultiRangeSlider
                    min={50}
                    max={5000}
                    minValue={minValueIni1}
                    maxValue={maxValueIni2}
                    onChange={(e) => {
                      set_minValueIni1(e.minValue);
                      set_maxValueIni2(e.maxValue);
                    }}
                    step={50}
                    stepOnly={true}
                    label={false}
                    ruler={false}
                    style={{
                      border: "none",
                      boxShadow: "none",
                      padding: "40px 15px 20px 15px",
                    }}
                    barLeftColor="#000000"
                    barInnerColor="#3488CD"
                    barRightColor="#000000"
                    thumbLeftColor="lime"
                    thumbRightColor="lime"
                  />
                </div>
              </div>
              {/* Price Range end */}

              {/* Filter by start */}
              <div className="content-box">
                <div className="title">{t("hotelList.FilterBy.heading")}</div>
                <div className="title-sm">
                  {t("hotelList.FilterBy.Amenities")}
                </div>
                <div className="item-box forscroll">
                  <Form>
                    {slectAminityData != null
                      ? slectAminityData.length > 0
                        ? slectAminityData.map((aminity, id) => (
                            <Form.Group controlId="formBasicCheckbox" key={id}>
                              <Form.Check
                                type="checkbox"
                                label={aminity.amenity_name}
                                onChange={() =>
                                  changeAminityselectaminity(aminity.id)
                                }
                                selected={selectedAminity.includes(aminity.id)}
                                checked={selectedAminity.includes(aminity.id)}
                              />
                            </Form.Group>
                          ))
                        : null
                      : null}
                  </Form>
                </div>
                <div className="custom-perference">
                  <div className="title-sm">
                    {t("hotelList.FilterBy.Perference")}
                  </div>
                  <div className="item-box forscroll">
                    <Form>
                      {selectPreferenceData != null
                        ? selectPreferenceData.length > 0
                          ? selectPreferenceData.map((perference, id) => (
                              <Form.Group
                                controlId="formBasicCheckbox"
                                key={id}
                              >
                                <Form.Check
                                  type="checkbox"
                                  label={perference.name}
                                  onChange={() =>
                                    changeSelectListPerference(perference.id)
                                  }
                                  selected={selectedPerference.includes(
                                    perference.id
                                  )}
                                  checked={selectedPerference.includes(
                                    perference.id
                                  )}
                                />
                              </Form.Group>
                            ))
                          : null
                        : null}
                    </Form>
                  </div>
                </div>
              </div>

              <div className="content-box">
                <div className="title">
                  {t("hotelList.FilterBy.StarRating.heading")}
                </div>

                <div className="item-box">
                  <Form>
                    {/* Filter by star start */}
                    {starselectFilter != null
                      ? starselectFilter.data.map((filter, id) => {
                          return (
                            <Form.Group controlId="formBasicCheckbox" key={id}>
                              <Form.Check
                                type="checkbox"
                                label={filter.name}
                                onChange={() => handlestar(filter.value)}
                                checked={selectStar.includes(filter.value)}
                              />
                            </Form.Group>
                          );
                        })
                      : null}
                    {/* Filter by star end */}
                  </Form>
                </div>
              </div>
              <button
                className="reset-now"
                onClick={() => {
                  handleApplyEvent(1);
                  handleApplyEvent(2);
                }}
              >
                {t("hotelList.Apply")}
              </button>
              <br />
              <button
                className="reset-now"
                onClick={() => {
                  handleResetEvent(1);
                  handleResetEvent(2);
                }}
              >
                {t("hotelList.Reset")}
              </button>
              {/* Star Rating end */}
            </div>
            <div className="col-lg-9">
              {/* listing header start */}
              <div className="listing-header" id="backtotop">
                <div className="header-left">
                  <h3>
                    {selectHotelDataList != null
                      ? selectHotelDataList.length
                      : null}{" "}
                    {saveloadeddata != null
                      ? saveloadeddata.searchButtonShow == "bid"
                        ? t("hotelList.Bid")
                        : t("hotelList.Hourly")
                      : ""}{" "}
                    {t("hotelList.HotelsIn")}{" "}
                    {selectPlaceDataHandleData == null
                      ? saveloadeddata != null
                        ? selectHotelCity
                        : ""
                      : selectPlaceDataHandleData.longaddress}
                  </h3>
                </div>
                <div className="header-right">
                  <div
                    className="map"
                    style={{ cursor: "pointer" }}
                    onClick={handleMapClick}
                  ></div>
                </div>
              </div>

              {/* listing header end */}
              {/* listing box start */}

              <BidNowList
                hotelListDataLoading={hotelListDataLoading}
                selectHotelDataList={selectHotelDataList}
                fetchData={fetchData}
                TotalHotelPageCount={TotalHotelPageCount}
              />

              <div className="list-pagination"></div>
            </div>
            <Button className="back-to-top" onClick={nearToTop}>
              {t("hotelList.BACKTOTOP")}
            </Button>
          </div>
        </div>
      </div>

      {/* listing page end */}
      {mapPopUp ? (
        <div className="modal show mappopup">
          <Modal.Dialog size="xl">
            <Modal.Header>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={handleMapClose}
              ></button>
            </Modal.Header>
            <Modal.Body>
              {selectHotelDataList != null ? (
                <GoogleMaps
                  center={{
                    lat: selectHotelDataList.avg_lat_long.latitude,
                    lng: selectHotelDataList.avg_lat_long.longitude,
                  }}
                  zoom={8}
                  places={selectHotelDataList.hotels}
                  searchData={saveloadeddata}
                />
              ) : null}
            </Modal.Body>
          </Modal.Dialog>
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  languageToShow: selectlanguageToShow,
  selectHotelDataList: selectHotelDataList,
  slectAminityData: selectHotelAminityData,
  selectCurrentUser: selectCurrentUser,
  selectFavouriteHotel: selectFavouriteHotel,
  handleSearchRequestData: handleSearchRequestData,
  hotelListDataLoading: hotelListDataLoading,
  saveloadeddata: selectFavouriteHotelSearchData,
  searchFilterAddOrNot: searchFilterAddOrNot,
  TotalHotelPageCount: TotalHotelPageCount,
  selectHotelCity: selectHotelCity,
  selectHotelBidNowPageRouteData: selectHotelBidNowPageRouteData,
  selectHotelDetailsPageRouteData: selectHotelDetailsPageRouteData,
  selectPlaceDataHandleData: selectPlaceDataHandleData,
  selectPreferenceData: selectPreferenceData,
  selectPreference: selectPreference,
  starselectFilter: selectHotelStarRatingFilterStarSelect,
});
const mapDispatchToProps = (dispatch) => ({
  getHotelListRequest: (data) => dispatch(getHotelListRequest(data)),
  getHotelAnimityStart: (data) => dispatch(getHotelAnimityStart(data)),
  stateClearAfterAdd: () => dispatch(stateClearAfterAdd()),
  searchDataRemove: () => dispatch(searchDataRemove()),
  searchFilterDataSave: (data) => dispatch(searchFilterDataSave(data)),
  hotelToggleAddOrNot: (data) => dispatch(hotelToggleAddOrNot(data)),
  placeStateDataSave: (data) => dispatch(placeStateDataSave(data)),
  hotelWheatherApiRequest: (data) => dispatch(hotelWheatherApiRequest(data)),
  hotelRouteStateHandler: (data) => dispatch(hotelRouteStateHandler(data)),
  hotelRouteDetailsStateHandler: (data) =>
    dispatch(hotelRouteDetailsStateHandler(data)),
  hotelPerferenceListHandler: (data) =>
    dispatch(hotelPerferenceListHandler(data)),
  getHotelListByPagination: (data) => dispatch(getHotelListByPagination(data)),
  getPerferenceDataList: (data) => dispatch(getPerferenceDataList(data)),
  hotelWheatherApiDataRemove: (data) =>
    dispatch(hotelWheatherApiDataRemove(data)),
  hotelStarFilterApiCallRequest: (data) =>
    dispatch(hotelStarFilterApiCallRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListingbidnowPage);
