import HotelActionTypes from "./hotel.types";
const INITIAL_STATE = {
  loading: false,
  hotelData: null,
  hoteldetailsLoading:false,
  hotelListData: null,
  hotelListDataLoading: false,
  hotelAminityData: [],
  error: null,
  favouriteHotel: null,
  favouriteHotelListData: null,
  bidCheckoutDetails: null,
  hourlyCheckoutDetails: null,
  hotelBooking: null,
  hotelSearchData: null,
  hotelsearchhandle: false,
  hotelFilterSearch: null,
  hotelUserBookingListLoading: false,
  hotelUserBookingListData: null,
  hotelSearchData: null,
  hotelsearchhandle: false,
  applyWallet: null,
  applyPromoCode: null,
  bidYourAmount: null,
  applyHotelOffers: null,
  applyHotelOffersFailedData: null,
  hotelWhetherData: null,
  filteraddornot: false,
  totalHotelCount: 0,
  hotelplacedata: null,
  saveGuestUserCheckoutData: null,
  hotelResendConfirmation: null,
  currentUrl: 1,
  pastUrl: 1,
  offerId: null,
  preferenceList: null,
  geolocationadded: false,
  hotelBookingLoading: false,
  applyHotelOffersLoading: false,
  resendConfirmationLoading: false,
  afterAddReviewMessage:null,
  bookingdetails:null,
  cancelBidding:null,
  fiterStar:null
};

const hotelReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HotelActionTypes.GET_FULL_HOTELS_DETAILS_REQUEST:
      return {
        ...state,
        hoteldetailsLoading: true,
      };
    case HotelActionTypes.GET_FULL_HOTELS_DETAILS_SUCCESS:
      return {
        ...state,
        hotelData: action.payload,
        hoteldetailsLoading:false
      };
    case HotelActionTypes.GET_FULL_HOTELS_DETAILS_FAILED:
      return {
        ...state,
        error: action.payload,
        hoteldetailsLoading:false
      };
    case HotelActionTypes.GET_HOTEL_LIST_SEARCH_SUCCESS:
      return {
        ...state,
        hotelListData: action.payload,
        hotelListDataLoading: false,
      };
    case HotelActionTypes.HOTEL_TOTAL_LIST_COUNT:
      return {
        ...state,
        totalHotelCount: action.payload,
      };
    case HotelActionTypes.GET_HOTEL_LIST_SEARCH_FAILURE:
      return {
        ...state,
        hotelListData:action.payload.data,
        error: action.payload,
        hotelListDataLoading: false,
      };
    case HotelActionTypes.GET_HOTEL_ANIMITY_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case HotelActionTypes.GET_HOTEL_ANIMITY_LIST_SUCCESS:
      return {
        ...state,
        hotelAminityData: action.payload,
      };
    case HotelActionTypes.GET_HOTEL_ANIMITY_LIST_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case HotelActionTypes.GET_HOTEL_LIST_SEARCH_START:
      return {
        ...state,
        hotelListDataLoading: true,
        hotelListData:null
      };
    case HotelActionTypes.GET_HOTEL_PAGINATION_LIST_SEARCH_START:
    return {
    ...state,
    hotelListDataLoading: true
    };  



    case HotelActionTypes.ADD_TO_FAVOURITE_HOTEL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case HotelActionTypes.ADD_TO_FAVOURITE_HOTEL_SUCCESS:
      return {
        ...state,
        favouriteHotel: action.payload,
      };
    case HotelActionTypes.ADD_TO_FAVOURITE_HOTEL_FAILED:
      return {
        ...state,
        error: action.payload,
      };

    case HotelActionTypes.REMOVE_TO_FAVOURITE_HOTEL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case HotelActionTypes.REMOVE_TO_FAVOURITE_HOTEL_SUCCESS:
      return {
        ...state,
        favouriteHotel: action.payload,
      };
    case HotelActionTypes.REMOVE_TO_FAVOURITE_HOTEL_FAILED:
      return {
        ...state,
        error: action.payload,
      };

    case HotelActionTypes.GET_ALL_FAVOURITE_HOTEL_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case HotelActionTypes.GET_ALL_FAVOURITE_HOTEL_LIST_SUCCESS:
      return {
        ...state,
        favouriteHotelListData: action.payload,
      };
    case HotelActionTypes.GET_ALL_FAVOURITE_HOTEL_LIST_FAILED:
      return {
        ...state,
        error: action.payload,
      };

    case HotelActionTypes.STATE_CLEAR_AFTER_HOTEL_LIST_FAVRITE_MSG:
      return {
        ...state,
        favouriteHotel: null,
      };
    case HotelActionTypes.STATE_AFTER_HOTEL_SEARCH_DATA_SAVE:
      return {
        ...state,
        hotelSearchData: action.payload,
      };
    case HotelActionTypes.STATE_SEARCH_ALL_SAVE_FILTER_DATA:
      return {
        ...state,
        hotelFilterSearch: action.payload,
      };
    case HotelActionTypes.STATE_SEARCH_START_AFTER_CLICK_ON_BUTTON:
      return {
        ...state,
        hotelsearchhandle: true,
      };
    case HotelActionTypes.STATE_SEARCH_STOP_AFTER_CLICK_ON_BUTTON:
      return {
        ...state,
        hotelsearchhandle: false,
      };
    case HotelActionTypes.STATE_AFTER_HOTEL_SEARCH_DATA_SAVE:
      return {
        ...state,
        hotelSearchData: action.payload,
      };
    case HotelActionTypes.STATE_SEARCH_START_AFTER_CLICK_ON_BUTTON:
      return {
        ...state,
        hotelsearchhandle: true,
      };
    case HotelActionTypes.STATE_SEARCH_STOP_AFTER_CLICK_ON_BUTTON:
      return {
        ...state,
        hotelsearchhandle: false,
      };

    /************ Bid Checkout Details     *************** */
    case HotelActionTypes.GET_BID_CHECKOUT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case HotelActionTypes.GET_BID_CHECKOUT_DETAILS_SUCCESS:
      return {
        ...state,
        bidCheckoutDetails: action.payload,
      };
    case HotelActionTypes.GET_BID_CHECKOUT_DETAILS_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    /************ Hourly Checkout Details     *************** */
    case HotelActionTypes.GET_HOURLY_CHECKOUT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case HotelActionTypes.GET_HOURLY_CHECKOUT_DETAILS_SUCCESS:
      return {
        ...state,
        hourlyCheckoutDetails: action.payload,
      };
    case HotelActionTypes.GET_HOURLY_CHECKOUT_DETAILS_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    /************ Hotel Booking     *************** */
    case HotelActionTypes.HOTEL_BOOKING_REQUEST:
      return {
        ...state,
        loading: true,
        hotelBookingLoading: true,
      };
    case HotelActionTypes.HOTEL_BOOKING_SUCCESS:
      return {
        ...state,
        hotelBooking: action.payload,
        hotelBookingLoading: false,
      };
    case HotelActionTypes.HOTEL_BOOKING_FAILED:
      return {
        ...state,
        error: action.payload,
        hotelBooking: action.payload,
        hotelBookingLoading: false,
      };

    /********** User Hotel Booking History List  ************/
    case HotelActionTypes.HOTEL_BOOKING_LIST_DATA_FETCH:
      return {
        ...state,
        hotelUserBookingListLoading: true,
      };
    case HotelActionTypes.HOTEL_BOOKING_LIST_DATA_SUCCESS:
      return {
        ...state,
        hotelUserBookingListLoading: false,
        hotelUserBookingListData: action.payload,
      };
    case HotelActionTypes.HOTEL_BOOKING_LIST_DATA_FAILURE:
      return {
        ...state,
        hotelUserBookingListLoading: false,
        error: action.payload,
        hotelUserBookingListData: action.payload,
      };
    /********** User Hotel Booking History List  ************/
    /************ Apply Wallet     *************** */
    case HotelActionTypes.APPLY_WALLET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case HotelActionTypes.APPLY_WALLET_SUCCESS:
      return {
        ...state,
        applyWallet: action.payload,
        loading: false,
      };
    case HotelActionTypes.APPLY_WALLET_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    /************ Apply Promo Code     *************** */
    case HotelActionTypes.APPLY_PROMO_CODE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case HotelActionTypes.APPLY_PROMO_CODE_SUCCESS:
      return {
        ...state,
        applyPromoCode: action.payload,
        loading: false,
      };
    case HotelActionTypes.APPLY_PROMO_CODE_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    /************ Bid Your Amount    *************** */
    case HotelActionTypes.BID_YOUR_AMOUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case HotelActionTypes.BID_YOUR_AMOUNT_SUCCESS:
      return {
        ...state,
        bidYourAmount: action.payload,
        loading: false,
      };
    case HotelActionTypes.BID_YOUR_AMOUNT_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    /************ Apply Hotel Offers    *************** */
    case HotelActionTypes.APPLY_HOTEL_OFFERS_REQUEST:
      return {
        ...state,
        applyHotelOffersLoading: true,
      };
    case HotelActionTypes.APPLY_HOTEL_OFFERS_SUCCESS:
      return {
        ...state,
        applyHotelOffers: action.payload,
        applyHotelOffersLoading: false,
      };
    case HotelActionTypes.APPLY_HOTEL_OFFERS_FAILED:
      return {
        ...state,
        error: action.payload,
        applyHotelOffersFailedData: action.payload,
        applyHotelOffersLoading: false,
      };

    /* PLACE WEATHER API REDUX WORKING  */
    case HotelActionTypes.HOTEL_WHEATHER_API_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case HotelActionTypes.HOTEL_WHEATHER_API_SUCCESS:
      return {
        ...state,
        hotelWhetherData: action.payload,
        loading: false,
      };
    case HotelActionTypes.HOTEL_WHEATHER_API_FAILURE:
      return {
        ...state,
        error: action.payload,
        hotelWhetherData:null,
        loading: false,
    };
    case HotelActionTypes.HOTEL_WHEATHER_API_DATA_REMOVE:
    return {
        ...state,
        error: null,
        hotelWhetherData:null,
        loading: false,
    };

    /* PLACE WEATHER API REDUX WORKING  */

    /******** Toggle Filter add or not ********/
    case HotelActionTypes.HOTEL_FILTER_ADDED_OR_NOT_HANDLE:
      return {
        ...state,
        filteraddornot: action.payload,
      };

    /******** Toggle Filter add or not ********/

    case HotelActionTypes.STATE_CLEAR_AFTER_TASK:
      return {
        ...state,
        error: null,
        applyHotelOffers: null,
        hotelBooking: null,
      };
    /********* HOTEL DATA STATE SAVE  *************/
    case HotelActionTypes.HOTEL_SEARCH_STATE_SAVE:
      return {
        ...state,
        hotelplacedata: action.payload,
      };

    /***** Save checkout guest User Form Data   ***/
    case HotelActionTypes.SAVE_GUEST_USER_CHECKOUT_DATA:
      return {
        ...state,
        saveGuestUserCheckoutData: action.payload,
      };

    /************ HOTEL RE-SEND CONFIRMATION  *************** */
    case HotelActionTypes.HOTEL_RESEND_CONFIRMATION_REQUEST:
      return {
        ...state,
        loading: true,
        resendConfirmationLoading: true,
      };
    case HotelActionTypes.HOTEL_RESEND_CONFIRMATION_SUCCESS:
      return {
        ...state,
        hotelResendConfirmation: action.payload,
        loading: false,
        resendConfirmationLoading: false,
      };
    case HotelActionTypes.HOTEL_RESEND_CONFIRMATION_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
        resendConfirmationLoading: false,
      };
    /************** Hotel List Bid Now Route States Handle  ****************/
    case HotelActionTypes.HOTEL_ROUTE_STATE_HANDLE_REQUEST:
      return {
        ...state,
        currentUrl: action.payload,
      };
    /************** Hotel List Bid Now Route States Handle  ****************/
    case HotelActionTypes.HOTEL_ROUTE_STATE_HANDLE_REQUEST_DETAILS:
      return {
        ...state,
        pastUrl: action.payload,
      };
    case HotelActionTypes.HOTEL_PLACE_DATA_STATE_HANDLE:
      return {
        ...state,
        placeHandle: action.payload,
      };
    case HotelActionTypes.HOTEL_OFFERS_HANDLER_STATE:
      return {
        ...state,
        offerId: action.payload,
      };
    case HotelActionTypes.HOTEL_OFFERS_REMOVE_HANDLER_STATE:
      return {
      ...state,
      offerId:null
      }; 
    case HotelActionTypes.HOTEL_PERFERENCE_API_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case HotelActionTypes.HOTEL_PERFERENCE_API_SUCCESS:
      return {
        ...state,
        preferenceList: action.payload,
      };
    case HotelActionTypes.HOTEL_PERFERENCE_API_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case HotelActionTypes.HOTEL_GETLOCATION_UPLOADED:
      return {
        ...state,
        geolocationadded: action.payload,
      };
    case HotelActionTypes.GET_HOTEL_LIST_UPDATE_AFTER_ADD_OR_REMOVE_FAVORITE:
      return {
       ...state,
       hotelListData: action.payload,
    };
    case HotelActionTypes.POST_HOTEL_RATING_REVIEW_REQUEST:
    return {
    ...state,
    afterAddReviewMessage:null,
    } 
    case HotelActionTypes.POST_HOTEL_RATING_REVIEW_SUCCESS:
    return {
    ...state,
    afterAddReviewMessage:action.payload,
    }
    case HotelActionTypes.POST_HOTEL_RATING_REVIEW_FAILURE: 
    return {
    ...state,
    afterAddReviewMessage:action.payload,
    }
    case HotelActionTypes.HOTEL_BOOKING_DETAILS_REQUEST: 
    return {
    ...state,
    bookingdetails:null,
    }
    case HotelActionTypes.HOTEL_BOOKING_DETAILS_SUCCESS:
    return {
    ...state,
    bookingdetails:action.payload,
    }
    case HotelActionTypes.HOTEL_BIDDING_CENCEL_REQUEST: 
    return {
    ...state,
    cancelBidding:null
    }
    case HotelActionTypes.HOTEL_BIDDING_CENCEL_SUCCESS:
    return {
    state,  
    cancelBidding:action.payload
    }  
    case HotelActionTypes.HOTEL_BIDDING_CENCEL_FAILED:
    return {
    state,
    error:action.payload  
    }  
    case HotelActionTypes.HOTEL_STAR_API_FILTER_REQUEST: 
    return {
    ...state,
    loading:true
    }
    case HotelActionTypes.HOTEL_STAR_API_FILTER_SUCCESS:
    return {
    ...state,  
    fiterStar:action.payload
    }  
    case HotelActionTypes.HOTEL_STAR_API_FILTER_FAILURE:
    return {
    ...state,
    error:action.payload  
    }  
    default:
    return state;
  }
};

export default hotelReducer;
