import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import addressIcon from "../../assets/images/icon-list-map.svg";
import starIcon from "../../assets/images/icon-review-star.svg";
import shareIcon from "../../assets/images/icon-share.svg";
import HotelSearchForOthers from "./../../components/HeaderSearch/HeaderSearch";
import { useTranslation } from "react-i18next";

import {
  selectUserLoginData,
  selectSocialLoginUser,
} from "../../redux/user/user.selectors";
import { stateClearAfterTask } from "../../redux/hotels/hotel.actions";

import moment from "moment";
import { ReactComponent as FavouriteIcon } from "../../assets/images/icon-list-favourite.svg";
import {
  addFavouriteHotelRequest,
  removeFavouriteHotelRequest,
  // getAllFavouriteHotelListRequest,
  getFullHotelDetailsRequest,
  hotelWheatherApiRequest,
} from "../../redux/hotels/hotel.actions";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  selectFavouriteHotel,
  // selectFavouriteHotelList,
  selectFavouriteHotelSearchData,
} from "../../redux/hotels/hotel.selectors";
import { selectlanguageToShow } from "./../../redux/language/language.selectors";
import {
  selectHotelData,
  selectHotelDetailsLoading,
} from "./../../redux/hotels/hotel.selectors";
import ShareModal from "../../utils/ShareModal";
import GoogleMaps from "../googleMaps/goodleHotelDetailmaps";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import { selectcurrencyToShow } from "../../redux/currency/currency.selectors";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import Rating from "@mui/material/Rating";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const HotelListingDetails = ({
  languageToShow,
  selectHotelData,
  addFavouriteHotelRequest,
  removeFavouriteHotelRequest,
  favouriteHotel,
  hotel_slug,
  userAuthData,
  userSocialAuthData,
  searchsavedData,
  currencyToShow,
  stateClearAfterTask,
  hotelWheatherApiRequest,
  hotelDetailsLoading,
}) => {
  const [t] = useTranslation("common");
  var [show, setShow] = useState(false);
  var handleClose = () => setShow(false);
  var handleShow = () => setShow(true);
  const [isReadMore, setIsReadMore] = useState(true);
  const [isAddedFavourite, setIsAddedFavourite] = useState();
  const [openSharePopup, setOpenSharePopup] = React.useState(false);
  const [isCopied, setIsCopied] = React.useState(false);
  const [shareCode, setShareCode] = React.useState();
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [count, setCount] = useState(2);

  const [showMoreAmenities, setShowMoreAmenities] = useState(true);
  // const stateParams = useLocation();
  // const { searchData } = stateParams.state;
  const [searchParams] = useSearchParams();
  const [hovered, setHovered] = useState(false);
  const [timeSlotIndex, setTimeSlotIndex] = useState();
  const [roomIndex, setRoomIndex] = useState();
  const [mapPopUp, setMapPopUp] = useState(false);
  const location = useLocation();
  const [slotHour, setSlotHour] = useState("");
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const toggleShowMore = () => {
    setShowMoreAmenities(!showMoreAmenities);
  };
  var customerreview = {
    arrows: true,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var hotellistSlide = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
  };

  var detailsSlider = {
    arrows: true,
    dots: false,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
  };

  var sideSlider = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    focusOnSelect: true,
    centerMode: false,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 2,
    vertical: true,
  };

  var popSlider = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
  };

  React.useEffect(() => {
    if (searchParams.get("search_type") == "bid") {
      const searchedParams = {
        search_type: searchParams.get("search_type"),
        city: searchParams?.get("city"),
        check_in_date: searchParams.get("check_in_date"),
        check_out_date: searchParams.get("check_out_date"),
        adults: searchParams.get("adults"),
        children: searchParams.get("children"),
        rooms: searchParams.get("rooms"),
      };

      const fullHotelDetailsParam = {
        hotel_slug: hotel_slug,
        languageToShow: languageToShow,
        token: userAuthData != null ? userAuthData.token : "",
        searchedParams: searchedParams,
      };
      stateClearAfterTask();

      getFullHotelDetailsRequest(fullHotelDetailsParam);
    }

    if (searchParams.get("search_type") == "hour") {
      const searchedParams = {
        search_type: searchParams.get("search_type"),
        book_for: searchParams?.get("book_for"),
        city: searchParams?.get("city"),

        check_in_date: searchParams.get("check_in_date"),
        adults: searchParams.get("adults"),
        children: searchParams.get("children"),
        rooms: searchParams.get("rooms"),
      };
      const fullHotelDetailsParam = {
        hotel_slug: hotel_slug,
        languageToShow: languageToShow,
        token: userAuthData != null ? userAuthData.token : "",
        searchedParams: searchedParams,
      };
      stateClearAfterTask();

      getFullHotelDetailsRequest(fullHotelDetailsParam);
    }
  }, [languageToShow]);

  React.useEffect(() => {
    setIsAddedFavourite(selectHotelData?.data?.is_favourite);
  }, [selectHotelData]);

  const handleFavourite = (hotel_id) => {
    const postData = {
      hotel_id: hotel_id,
    };

    const data = {
      postData,
      languageToShow: languageToShow,
      token: userAuthData != null ? userAuthData.token : "",
    };

    if (userAuthData != null || userSocialAuthData != null) {
      if (hotel_id && isAddedFavourite === true) {
        removeFavouriteHotelRequest(data);
        setIsAddedFavourite(!isAddedFavourite);
        if (searchParams.get("search_type") == "bid") {
          const searchedParams = {
            search_type: searchParams.get("search_type"),
            city: searchParams?.get("city"),
            check_in_date: searchParams.get("check_in_date"),
            check_out_date: searchParams.get("check_out_date"),
            adults: searchParams.get("adults"),
            children: searchParams.get("children"),
            rooms: searchParams.get("rooms"),
          };

          const fullHotelDetailsParam = {
            hotel_slug: hotel_slug,
            languageToShow: languageToShow,
            token: userAuthData != null ? userAuthData.token : "",
            searchedParams: searchedParams,
          };
          getFullHotelDetailsRequest(fullHotelDetailsParam);
        }
        if (searchParams.get("search_type") == "hour") {
          const searchedParams = {
            search_type: searchParams.get("search_type"),
            book_for: searchParams?.get("book_for"),
            city: searchParams?.get("city"),

            check_in_date: searchParams.get("check_in_date"),

            adults: searchParams.get("adults"),
            children: searchParams.get("children"),
          };
          const fullHotelDetailsParam = {
            hotel_slug: hotel_slug,
            languageToShow: languageToShow,
            token: userAuthData != null ? userAuthData.token : "",
            searchedParams: searchedParams,
          };
          getFullHotelDetailsRequest(fullHotelDetailsParam);
        }
      } else {
        addFavouriteHotelRequest(data);
        setIsAddedFavourite(!isAddedFavourite);

        if (searchParams.get("search_type") == "bid") {
          const searchedParams = {
            search_type: searchParams.get("search_type"),
            city: searchParams?.get("city"),
            check_in_date: searchParams.get("check_in_date"),
            check_out_date: searchParams.get("check_out_date"),
            adults: searchParams.get("adults"),
            children: searchParams.get("children"),
            rooms: searchParams.get("rooms"),
          };

          const fullHotelDetailsParam = {
            hotel_slug: hotel_slug,
            languageToShow: languageToShow,
            token: userAuthData != null ? userAuthData.token : "",
            searchedParams: searchedParams,
          };
          getFullHotelDetailsRequest(fullHotelDetailsParam);
        }
        if (searchParams.get("search_type") == "hour") {
          const searchedParams = {
            search_type: searchParams.get("search_type"),
            book_for: searchParams?.get("book_for"),
            city: searchParams?.get("city"),

            check_in_date: searchParams.get("check_in_date"),

            adults: searchParams.get("adults"),
            children: searchParams.get("children"),
            rooms: searchParams.get("rooms"),
          };
          const fullHotelDetailsParam = {
            hotel_slug: hotel_slug,
            languageToShow: languageToShow,
            token: userAuthData != null ? userAuthData.token : "",
            searchedParams: searchedParams,
          };
          getFullHotelDetailsRequest(fullHotelDetailsParam);
        }
      }
    } else {
      MySwal.fire({
        confirmButtonText: "OK",
        confirmButtonColor: "#296da9",
        text: t("MyAccount.MyFavouritesPage.Pleaseloginfirst"),
      });
      // errorToast("Please login first");
    }
  };

  const handleSharePopup = (shareData) => {
    if (shareData) {
      setShareCode(shareData);
      setOpenSharePopup(!openSharePopup);
    } else {
      setShareCode("");
    }
  };

  const onCopy = React.useCallback((value) => {
    setIsCopied(value);
    setOpenSharePopup(false);
  }, []);

  const handleMapClick = () => {
    setMapPopUp(true);
  };
  const handleMapClose = () => {
    setMapPopUp(false);
  };
  const handleMouseHover = (index, roomId) => {
    setSlotHour("");
    setTimeSlotIndex(index);
    setRoomIndex(roomId);
    setHovered(true);
  };
  const handleMouseLeave = (index, roomId) => {
    setTimeSlotIndex(index);
    setRoomIndex(roomId);
    setHovered(false);
  };

  useEffect(() => {
    setSlotHour(searchParams?.get("book_for"));
  }, []);

  if (hotelDetailsLoading == true) {
    return (
      <div
        style={{ height: "500px" }}
        className={`datanotfound  ${selectHotelData == null ? "" : "hidden"}`}
      >
        <img src="../img/searc.png" alt="" />
        <h2>
          Please wait <span>Loading</span>
        </h2>
      </div>
    );
  }
  if (hotelDetailsLoading == false && selectHotelData == null) {
    return (
      <div
        style={{ height: "500px" }}
        className={`datanotfound  ${selectHotelData == null ? "" : "hidden"}`}
      >
        {/* <img src="../img/searc.png" alt=""/> */}

        <h2>
          Sorry <span>No data Found</span>
        </h2>
      </div>
    );
  }

  return selectHotelData !== null ? (
    <React.Fragment>
      {/* inner banner start */}
      <div className="inner-banner">
        <div className="container">
          {/* search hotel start */}
          <div className="row">
            <div className="col-lg-12">
              <div className="search-hotel search-list-header listing-details">
                <HotelSearchForOthers />
              </div>
            </div>
          </div>
          {/* search hotel start */}
        </div>
      </div>

      {/* inner banner end */}

      {/* breadcrumb start */}
      <div className="breadcrumb">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ul>
                <li>
                  <Link to="/">{t("hotelDetails.breadcrumb.Home")}</Link>
                </li>
                <li>
                  <Link to={-1}>
                    {t("hotelDetails.breadcrumb.Hotels")}{" "}
                    {searchParams?.get("city")}
                  </Link>
                </li>
                {/* <li>Mansard Dubai, a Radisson Collection Hotel</li> */}
                <li>{selectHotelData?.data?.name}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* breadcrumb end */}

      {/* listing details page start */}

      <div className="listing-details">
        <div className="container">
          {/* listing details header start */}
          <div className="row">
            <div className="col-lg-12">
              <div className="listing-details-header">
                <div className="left-txt flex-column">
                  <div className="top-part">
                    <div className="title-stars-container d-flex">
                      <p className="title">{selectHotelData?.data?.name}</p>
                      <span
                        className="star-rating"
                        style={{
                          flexShrink: 0,
                        }}
                      >
                        {[...Array(selectHotelData?.data?.star)].map((e, i) => (
                          <img src={starIcon} key={i} alt="star" />
                        ))}
                      </span>
                    </div>
                  </div>

                  <div className="address">
                    <div className="icon">
                      <img src={addressIcon} alt="" />
                    </div>
                    <div>
                      <span>
                        {selectHotelData?.data?.address.address_line1}
                      </span>
                      ,{" "}
                      {selectHotelData?.data?.address.address_line2 && (
                        <span>
                          {selectHotelData?.data?.address.address_line2},{" "}
                        </span>
                      )}{" "}
                      {selectHotelData?.data?.address.city_village && (
                        <span>
                          {selectHotelData?.data?.address.city_village}
                        </span>
                      )}
                      {selectHotelData?.data?.address.pincode && (
                        <span>
                          - {selectHotelData?.data?.address.pincode} ,
                        </span>
                      )}
                      <span>{selectHotelData?.data?.address.country}</span>
                    </div>
                  </div>
                </div>

                {/* Customer feedback static for now */}
                <div className="rating-content">
                  <div className="icon-list">
                    <Button
                      onClick={() => {
                        handleSharePopup(window.location.href);
                      }}
                      className={"onClickBtn"}
                    >
                      <img src={shareIcon} alt="" />
                    </Button>
                    <Button
                      className={
                        // selectHotelData?.is_favourite === true
                        isAddedFavourite == true
                          ? "activeFavourite onClickBtn"
                          : "onClickBtn"
                      }
                      onClick={() =>
                        handleFavourite(
                          selectHotelData?.data?.id,
                          selectHotelData?.data?.is_favourite
                        )
                      }
                    >
                      <FavouriteIcon />
                    </Button>
                  </div>
                  <div className="comment">
                    <p
                      className={`feedback${
                        languageToShow === "ar" ? " feedback-ar" : ""
                      }`}
                    >
                      {selectHotelData?.data?.review?.feedback_type}
                    </p>
                    <p
                      className={`reviews${
                        languageToShow === "ar" ? " reviews-ar" : ""
                      }`}
                    >
                      {selectHotelData?.data?.review?.guests}{" "}
                      {t("hotelDetails.hotelDetail.reviews")}
                    </p>
                  </div>
                  <div className="rating-txt">
                    {Number(selectHotelData?.data?.review?.rating).toFixed(1)}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* listing details header end */}

          {/* listing details slider start */}
          <div className="listing-details-slider">
            <div className="details-slider">
              <Slider
                {...detailsSlider}
                asNavFor={nav2}
                ref={(slider1) => setNav1(slider1)}
              >
                {selectHotelData?.data?.images.map((image, index) => {
                  return (
                    <div className="slide-item" key={index}>
                      <img src={image} alt="" />
                    </div>
                  );
                })}
              </Slider>
            </div>
            <div className="popup-slider popupSliderHD ">
              {selectHotelData?.data?.images.length > 2 && (
                <div
                  className="caption"
                  style={{
                    position: "absolute",
                    right: "7%",
                    fontWeight: "600",
                    fontSize: "24px",
                    lineHeight: "29px",
                    top: "82%",
                    color: "#fff",
                    cursor: "pointer",
                    zIndex: 1,
                  }}
                  onClick={handleShow}
                >
                  +{selectHotelData?.data?.images.length - 2}{" "}
                  {t("hotelDetails.hotelDetail.Photos")}{" "}
                </div>
              )}
              <Slider
                {...sideSlider}
                asNavFor={nav1}
                ref={(slider2) => setNav2(slider2)}
              >
                {selectHotelData?.data?.images.map((image, index) => {
                  return (
                    // <>
                    <div key={index} className="popSlider-item">
                      <img src={image} alt="" />
                    </div>
                    // </>
                  );
                })}
              </Slider>
            </div>
          </div>

          {/* listing details slider end */}

          {/* hotel wrapper start */}
          <div className="hotel-wrapper">
            <div className="row">
              <div className="col-lg-9">
                {/* Hotel Amenities start */}
                <div className="hotel-amenities">
                  <div className="title">
                    {t("hotelDetails.hotelDetail.HotelAmenities")}
                  </div>
                  <ul>
                    {selectHotelData?.data?.hotel_amenities.map(
                      (amenity, index) => {
                        return (
                          <li key={index}>
                            <span className="icon">
                              <img src={amenity.amenity_icon} alt="" />
                            </span>
                            {amenity.amenity_name}
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
                {/* Hotel Amenities end */}
                {/* Hotel List start */}
                {selectHotelData?.data?.room_type_data.map((room, index) => {
                  return (
                    <div className="hotel-listing-box" key={index}>
                      <div className="hlb-top-bar">
                        <div className="room_title">
                          <div className="title">{room.name}</div>

                          {(room?.total12HoursAvailableRooms ||
                            room?.total6HoursAvailableRooms ||
                            room?.total3HoursAvailableRooms ||
                            room?.totalBidAvailableRooms) === 2 ? (
                            <div className="recommendedTagBox hide_mobile">
                              <p className="lastAvaliable">
                                <span>
                                  {t(
                                    "hotelDetails.hotelDetail.RoomDetails.Last2RoomsAvailable"
                                  )}
                                </span>
                              </p>
                            </div>
                          ) : (room?.total12HoursAvailableRooms ||
                              room?.total6HoursAvailableRooms ||
                              room?.total3HoursAvailableRooms ||
                              room?.totalBidAvailableRooms) === 1 ? (
                            <div className="recommendedTagBox hide_mobile">
                              <p className="lastAvaliable">
                                <span>
                                  {t(
                                    "hotelDetails.hotelDetail.RoomDetails.LastRoomAvailable"
                                  )}
                                </span>
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div
                          className="title-sm"
                          contentEditable="false"
                          dangerouslySetInnerHTML={{
                            __html: `${
                              selectHotelData != null ? room.description : null
                            }`,
                          }}
                        ></div>
                      </div>
                      <div className="hotel-listing-box-content">
                        <div className="slide-box">
                          {room.images.length > 0 ? (
                            <Slider {...hotellistSlide}>
                              {room.images.map((image, index) => {
                                return (
                                  <div className="slide-item" key={index}>
                                    <img src={image} alt="" />
                                  </div>
                                );
                              })}
                            </Slider>
                          ) : (
                            <Slider {...hotellistSlide}>
                              <div className="slide-item">
                                <img
                                  src="./img/hotel-list-slide-1.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="slide-item">
                                <img
                                  src="./img/hotel-list-slide-2.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="slide-item">
                                <img
                                  src="./img/hotel-list-slide-3.jpg"
                                  alt=""
                                />
                              </div>
                            </Slider>
                          )}
                        </div>
                        {(room?.total12HoursAvailableRooms ||
                          room?.total6HoursAvailableRooms ||
                          room?.total3HoursAvailableRooms ||
                          room?.totalBidAvailableRooms) === 2 ? (
                          <div className="recommendedTagBox recommendedTagBox-mobile hide-desktop">
                            <p className="lastAvaliable">
                              <span>
                                {t(
                                  "hotelDetails.hotelDetail.RoomDetails.Last2RoomsAvailable"
                                )}
                              </span>
                            </p>
                          </div>
                        ) : (room?.total12HoursAvailableRooms ||
                            room?.total6HoursAvailableRooms ||
                            room?.total3HoursAvailableRooms ||
                            room?.totalBidAvailableRooms) === 1 ? (
                          <div className="recommendedTagBox recommendedTagBox-mobile hide-desktop">
                            <p className="lastAvaliable">
                              <span>
                                {t(
                                  "hotelDetails.hotelDetail.RoomDetails.LastRoomAvailable"
                                )}
                              </span>
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="middle">
                          <div className="amenities-item">
                            <div className="title">
                              {t(
                                "hotelDetails.hotelDetail.RoomDetails.RoomAmenities"
                              )}
                            </div>
                            <ul>
                              {showMoreAmenities
                                ? room.room_amenities
                                    .slice(0, 6)
                                    .map((amenity, i) => {
                                      return (
                                        <li key={i}>
                                          <span className="icon">
                                            <img
                                              src={amenity.amenity_icon}
                                              alt=""
                                            />
                                          </span>
                                          {amenity.amenity_name}
                                        </li>
                                      );
                                    })
                                : room.room_amenities.map((amenity, i) => {
                                    return (
                                      <li key={i}>
                                        <span className="icon">
                                          <img
                                            src={amenity.amenity_icon}
                                            alt=""
                                          />
                                        </span>
                                        {amenity.amenity_name}
                                      </li>
                                    );
                                  })}
                            </ul>

                            {room.room_amenities.length > 6 && (
                              <div onClick={toggleShowMore}>
                                {showMoreAmenities ? (
                                  <p className="link-more">
                                    {t(
                                      "hotelDetails.hotelDetail.RoomDetails.ShowMore"
                                    )}
                                  </p>
                                ) : (
                                  <p className="link-more">
                                    {t(
                                      "hotelDetails.hotelDetail.RoomDetails.ShowLess"
                                    )}
                                  </p>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="price-content">
                          <div>
                            <ul>
                              {(
                                room.hourly_availabilities_data.slots || []
                              ).map((slot, slotIndex) => {
                                return !slot.is_booked ||
                                  slot.is_booked == false ? (
                                  <li
                                    key={slotIndex}
                                    // className={slotIndex === 0 ? "active" : ""}
                                    className={
                                      searchParams.get("search_type") ==
                                        "hour" && slotHour == slot.hour
                                        ? "active"
                                        : hovered &&
                                          roomIndex === room._id &&
                                          timeSlotIndex === slotIndex
                                        ? "hoveredHourPriceSlot"
                                        : ""
                                    }
                                    onMouseEnter={() =>
                                      handleMouseHover(slotIndex, room._id)
                                    }
                                    onMouseLeave={() =>
                                      handleMouseLeave(slotIndex, room._id)
                                    }
                                  >
                                    <Tooltip
                                      title={
                                        slot.is_booked == true
                                          ? t(
                                              "hotelDetails.hotelDetail.RoomDetails.Slotnotavailable"
                                            )
                                          : ""
                                      }
                                      placement="top"
                                      arrow
                                    >
                                      <div translate="no">
                                        <Link
                                          className="text-white"
                                          style={{
                                            pointerEvents:
                                              slot.is_booked == true
                                                ? "none"
                                                : "visible",
                                          }}
                                          state={{
                                            search_type:
                                              searchParams.get("search_type"),
                                            hotel_id: selectHotelData?.data?.id,
                                            room_type_id: room._id,
                                            check_in_date:
                                              searchParams.get("check_in_date"),
                                            check_out_date:
                                              searchParams.get(
                                                "check_out_date"
                                              ),
                                            adults: searchParams.get("adults"),
                                            children:
                                              searchParams.get("children"),
                                            rooms: searchParams.get("rooms"),
                                            // slot_id: slot.slot_id,
                                            hour: slot.hour,
                                            book_for:
                                              searchParams?.get("book_for"),
                                            city: searchParams?.get("city"),
                                          }}
                                          to={"/hourlyguestuser"}
                                        >
                                          <span className="hrs">
                                            {slot.hour}{" "}
                                            {slot.hour == 12
                                              ? t("hotelList.hour")
                                              : t("hotelList.hours")}
                                          </span>
                                          <span className="hrs">
                                            {currencyToShow.current}
                                            {" " +
                                              parseInt(
                                                slot.actual_price *
                                                  currencyToShow.convertedRates
                                              )}
                                          </span>
                                          <span className="rates" dir="ltr">
                                            {moment(`${slot.start_time}`, [
                                              "hh:mm",
                                            ]).format("hh:mm A")}{" "}
                                            -{" "}
                                            {moment(`${slot.end_time}`, [
                                              "hh:mm",
                                            ]).format("hh:mm A")}
                                          </span>
                                        </Link>
                                      </div>
                                    </Tooltip>
                                  </li>
                                ) : (
                                  ""
                                );
                              })}
                            </ul>

                            {searchParams.get("search_type") == "bid" ? (
                              <Tooltip
                                title={
                                  room.bid_availabilities_data?.is_booked ==
                                  true
                                    ? t(
                                        "hotelDetails.hotelDetail.RoomDetails.Roomnotavailable"
                                      )
                                    : ""
                                }
                                placement="top"
                                arrow
                              >
                                <div
                                  className={
                                    room.hourly_availabilities_data.slots
                                      .length == 0
                                      ? "customBidNowWidth "
                                      : ""
                                  }
                                >
                                  <Link
                                    className="bid-now"
                                    style={{
                                      pointerEvents:
                                        room.bid_availabilities_data
                                          ?.is_booked == true
                                          ? "none"
                                          : "visible",
                                    }}
                                    state={{
                                      search_type:
                                        searchParams.get("search_type"),
                                      hotel_id: selectHotelData?.data?.id,
                                      room_type_id: room._id,
                                      check_in_date:
                                        searchParams.get("check_in_date"),
                                      check_out_date:
                                        searchParams.get("check_out_date"),
                                      adults: searchParams.get("adults"),
                                      children: searchParams.get("children"),
                                      rooms: searchParams.get("rooms"),
                                      // slot_id: "",
                                      hour: "24",
                                      book_for: searchParams?.get("book_for"),
                                      city: searchParams?.get("city"),
                                    }}
                                    to={"/bidguestuser"}
                                  >
                                    {t(
                                      "hotelDetails.hotelDetail.RoomDetails.BIDnow"
                                    )}
                                  </Link>
                                </div>
                              </Tooltip>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {/* Hotel List end */}
              </div>
              <div className="col-lg-3">
                <div
                  className="nearby-location-map nearby-google-location-map"
                  onClick={handleMapClick}
                ></div>

                <div className="nearby-location-item ">
                  <div className="title">
                    {t("hotelDetails.hotelDetail.RoomDetails.NearbyLocations")}
                  </div>
                  <ul>
                    {(selectHotelData?.data?.nearby_landmarks || []).map(
                      (nbLocation, index) => {
                        return (
                          <li key={index}>
                            <span className="item">
                              {nbLocation.nearby_landmarks_name}
                            </span>
                            <span className="distance">
                              {nbLocation.nearby_landmarks_distance}
                            </span>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
                <div className="nearby-location-description">
                  <div className="title">{selectHotelData?.data?.name}</div>
                  <p
                    contentEditable="false"
                    dangerouslySetInnerHTML={{
                      __html: `${selectHotelData?.data?.description}`,
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
          {/* hotel wrapper end */}
          {/* Customer Rating start */}
          <div className="customer-rating">
            <div className="row">
              <div className="col-lg-12">
                <div className="title">
                  {t("hotelDetails.hotelDetail.CustomerRating.heading")}
                </div>
                <div className="rating-container">
                  <div className="rating-box">
                    {Number(selectHotelData?.data?.review?.rating).toFixed(1)}
                  </div>
                  <div className="comment">
                    {/* Very Good */}
                    {selectHotelData?.data?.review?.feedback_type}
                  </div>
                  <div className="reviews">
                    {selectHotelData?.data?.review?.guests}{" "}
                    {/* Guest Reviews */}
                    {t("hotelDetails.hotelDetail.CustomerRating.reviews")}
                  </div>
                </div>

                <div className="categories">
                  <h4>
                    {t(
                      "hotelDetails.hotelDetail.CustomerRating.Categories.categoryHeading"
                    )}
                  </h4>
                  <ul>
                    {selectHotelData?.data?.review?.categories != null
                      ? selectHotelData?.data?.review?.categories.length > 0 &&
                        selectHotelData?.data?.review?.categories.map(
                          (category, index) => (
                            <li key={index}>
                              <div className="top-item">
                                <div className="item-name">{category.name}</div>
                                <div className="item-icon">
                                  <img src="./img/icon-uparrow.svg" alt="" />
                                </div>
                                <div className="item-rating">
                                  {category.value}
                                </div>
                              </div>
                              <div className="progress-bar">
                                <div
                                  className="progess green"
                                  // style={{ width: "90.3%" }}
                                  style={{
                                    width: `${Number(
                                      category.value * 10
                                    ).toFixed(1)}%`,
                                  }}
                                ></div>
                              </div>
                            </li>
                          )
                        )
                      : ""}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* Customer Rating end */}

          {/* Customer Review start */}
          <div className="customer-review">
            <div className="row">
              <div className="col-lg-12">
                {selectHotelData?.data?.review?.customer_review.length ? (
                  <div className="title">
                    {t(
                      "hotelDetails.hotelDetail.CustomerReview.CustomerReviewHeading"
                    )}
                  </div>
                ) : null}
                <Slider
                  {...customerreview}
                  className={
                    selectHotelData?.data?.review?.customer_review.length < 3
                      ? "customer_reviews_slider"
                      : ""
                  }
                >
                  {selectHotelData?.data?.review?.customer_review.length > 0 &&
                    selectHotelData?.data?.review?.customer_review.map(
                      (review, index) => (
                        <div className="customerreview" key={index}>
                          <div className="content-box">
                            <p>{review?.review}</p>

                            <div className="start-rating">
                              <Rating
                                name="half-rating"
                                defaultValue={
                                  review?.rating ? review?.rating : 0
                                }
                                precision={0.5}
                                readOnly={true}
                              />
                            </div>
                            <div className="d-flex gap-2 align-items-center pb-2">
                              <Avatar
                                alt={review?.customer_name}
                                src={review?.customer_profile_image}
                                sx={{ width: 24, height: 24 }}
                              />
                              <div className="author">
                                {review?.customer_name}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                </Slider>
              </div>
            </div>
          </div>

          {/* Customer Review end */}

          {/* House Rules start */}
          <div className="house-rules">
            <div className="row">
              <div className="col-lg-12">
                <div className="title">
                  {t("hotelDetails.hotelDetail.HouseRules.HouseRulesHeading")}
                </div>

                <div className="content-box">
                  <div className="content-row">
                    <div className="left">
                      <label>
                        {t("hotelDetails.hotelDetail.HouseRules.Checkin")}
                      </label>
                    </div>
                    <div
                      className="right"
                      contentEditable="false"
                      dangerouslySetInnerHTML={{
                        __html: `${
                          selectHotelData?.data?.hotel_rules?.check_in !== "" ||
                          selectHotelData?.data?.hotel_rules?.check_in != null
                            ? selectHotelData?.data?.hotel_rules?.check_in
                            : "..."
                        }`,
                      }}
                    ></div>
                  </div>
                  <div className="content-row">
                    <div className="left">
                      <label>
                        {t("hotelDetails.hotelDetail.HouseRules.Checkout")}
                      </label>
                    </div>

                    <div className="right">
                      <p
                        className="right"
                        contentEditable="false"
                        dangerouslySetInnerHTML={{
                          __html: `${
                            selectHotelData?.data?.hotel_rules?.check_out !== ""
                              ? selectHotelData?.data?.hotel_rules?.check_out
                              : "..."
                          }`,
                        }}
                      ></p>
                    </div>
                  </div>

                  <div className="content-row">
                    <div className="left">
                      <label>
                        {t(
                          "hotelDetails.hotelDetail.HouseRules.CancellationandRepayment"
                        )}
                      </label>
                    </div>
                    <div className="right">
                      <p
                        className="right"
                        contentEditable="false"
                        dangerouslySetInnerHTML={{
                          __html: `${
                            selectHotelData?.data?.hotel_rules
                              ?.cancellation_and_policy !== ""
                              ? selectHotelData?.data?.hotel_rules
                                  ?.cancellation_and_policy
                              : "..."
                          }`,
                        }}
                      ></p>
                    </div>
                  </div>
                  <div className="content-row">
                    <div className="left">
                      <label>
                        {t(
                          "hotelDetails.hotelDetail.HouseRules.ChildrenandBeds"
                        )}
                      </label>
                    </div>
                    <div className="right">
                      <p
                        className="right"
                        contentEditable="false"
                        dangerouslySetInnerHTML={{
                          __html: `${
                            selectHotelData?.data?.hotel_rules
                              ?.children_and_beds !== ""
                              ? selectHotelData?.data?.hotel_rules
                                  ?.children_and_beds
                              : "..."
                          }`,
                        }}
                      ></p>
                    </div>
                  </div>
                  <div className="content-row">
                    <div className="left">
                      <label>
                        {t(
                          "hotelDetails.hotelDetail.HouseRules.AgeRestriction"
                        )}
                      </label>
                    </div>
                    <div className="right">
                      <p
                        className="right"
                        contentEditable="false"
                        dangerouslySetInnerHTML={{
                          __html: `${
                            selectHotelData?.data?.hotel_rules
                              ?.age_restriction !== ""
                              ? selectHotelData?.data?.hotel_rules
                                  ?.age_restriction
                              : "..."
                          }`,
                        }}
                      ></p>
                    </div>
                  </div>
                  <div className="content-row">
                    <div className="left">
                      <label>
                        {t("hotelDetails.hotelDetail.HouseRules.Pets")}
                      </label>
                    </div>
                    <div className="right">
                      <p
                        className="right"
                        contentEditable="false"
                        dangerouslySetInnerHTML={{
                          __html: `${
                            selectHotelData?.data?.hotel_rules?.pets !== ""
                              ? selectHotelData?.data?.hotel_rules?.pets
                              : "..."
                          }`,
                        }}
                      ></p>
                    </div>
                  </div>
                  <div className="content-row">
                    <div className="left">
                      <label>
                        {t(
                          "hotelDetails.hotelDetail.HouseRules.PaymentAcceptance"
                        )}
                      </label>
                    </div>
                    <div className="right">
                      <p
                        className="right"
                        contentEditable="false"
                        dangerouslySetInnerHTML={{
                          __html: `${
                            selectHotelData?.data?.hotel_rules
                              ?.payment_acceptance !== ""
                              ? selectHotelData?.data?.hotel_rules
                                  ?.payment_acceptance
                              : "..."
                          }`,
                        }}
                      ></p>
                    </div>
                  </div>

                  <div className="content-row">
                    <div className="left">
                      <label>
                        {t("hotelDetails.hotelDetail.HouseRules.Remarks")}
                      </label>
                    </div>
                    <div className="right">
                      <p
                        className="right"
                        contentEditable="false"
                        dangerouslySetInnerHTML={{
                          __html: `${
                            selectHotelData?.data?.hotel_rules?.remarks !== ""
                              ? selectHotelData?.data?.hotel_rules?.remarks
                              : "..."
                          }`,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* House Rules end */}
        </div>
      </div>

      {/* listing details page end */}

      {/* Share popup start */}
      <ShareModal
        setOpenSharePopup={setOpenSharePopup}
        openSharePopup={openSharePopup}
        shareCode={shareCode}
        setShareCode={setShareCode}
        onCopy={onCopy}
        isCopied={isCopied}
        setIsCopied={setIsCopied}
      />
      {/* Share popup end */}
      {/* popup slide start */}
      {mapPopUp ? (
        <div className="modal show mappopup">
          <Modal.Dialog size="xl">
            <Modal.Header>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={handleMapClose}
              ></button>
            </Modal.Header>
            <Modal.Body>
              {selectHotelData != null ? (
                <GoogleMaps
                  isMarkerShown
                  lat={selectHotelData.data.location.coordinates[0]}
                  lng={selectHotelData.data.location.coordinates[1]}
                />
              ) : null}
            </Modal.Body>
          </Modal.Dialog>
        </div>
      ) : null}

      <Modal
        dialogClassName="modal-lg gen-modal hotel-details-modal-slider"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="pop-slide-content">
            <Slider {...popSlider}>
              {selectHotelData?.data?.images.map((image, index) => {
                return (
                  <div className="slide-item" key={index}>
                    <img src={image} alt="" />
                  </div>
                );
              })}
            </Slider>
          </div>
        </Modal.Body>
      </Modal>
      {/* popup slide end */}
    </React.Fragment>
  ) : null;
};

const mapStateToProps = createStructuredSelector({
  languageToShow: selectlanguageToShow,
  selectHotelData: selectHotelData,
  favouriteHotel: selectFavouriteHotel,
  userAuthData: selectUserLoginData,
  userSocialAuthData: selectSocialLoginUser,
  searchsavedData: selectFavouriteHotelSearchData,
  currencyToShow: selectcurrencyToShow,
  hotelDetailsLoading: selectHotelDetailsLoading,
});
const mapDispatchToProps = (dispatch) => ({
  addFavouriteHotelRequest: (data) => dispatch(addFavouriteHotelRequest(data)),
  removeFavouriteHotelRequest: (data) =>
    dispatch(removeFavouriteHotelRequest(data)),
  getFullHotelDetailsRequest: (data) =>
    dispatch(getFullHotelDetailsRequest(data)),
  stateClearAfterTask: () => dispatch(stateClearAfterTask()),
  hotelWheatherApiRequest: (data) => dispatch(hotelWheatherApiRequest(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HotelListingDetails);
