import HomeActionTypes from "./home.types";

/***** Home Page  Data Actions  ********/

export const getOfferPromotionsPartnersPreferenceRequest = (homeData) => ({
 type: HomeActionTypes.GET_OFFER_PROMOTIONS_PARTNERS_PREFERENCE_DATA_REQUEST,
 payload: homeData,
});
  
export const getOfferPromotionsPartnersPreferenceSuccess = (hotelData) => ({
 type: HomeActionTypes.GET_OFFER_PROMOTIONS_PARTNERS_PREFERENCE_DATA_SUCCESS,
 payload: hotelData,
});
  
export const getOfferPromotionsPartnersPreferenceFailure = (error) => ({
 type: HomeActionTypes.GET_OFFER_PROMOTIONS_PARTNERS_PREFERENCE_DATA_FAILED,
 payload: error,
});

export const getPerferenceDataList = (data) => ({
type: HomeActionTypes.GET_PREFERENCE_DATA_REQUEST_LIST,
payload: data
}); 
/***** Home Page Data Actions  ********/

/*********** HOME PAGE REVIEW HOME PAGE ACTIONS ************/
export const getHomePageRandomReviewRequest = (data) => ({
type: HomeActionTypes.GET_HOME_PAGE_RANDOM_REVIEW_REQUEST,
payload: data,
});
     
export const getHomePageRandomReviewSuccess = (data) => ({
type: HomeActionTypes.GET_HOME_PAGE_RANDOM_REVIEW_SUCCESS,
payload: data,
});
     
export const getHomePageRandomReviewFailure = (error) => ({
type: HomeActionTypes.GET_HOME_PAGE_RANDOM_REVIEW_FAILED,
payload: error,
});
/*********** HOME PAGE REVIEW HOME PAGE ACTIONS ************/


