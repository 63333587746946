import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { getCouponListRequest } from "./../../redux/offers/offer.actions";
import { selectCouponListData } from "./../../redux/offers/offer.selectors";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectlanguageToShow } from "./../../redux/language/language.selectors";
import OffersCard from "./../../components/Offers/OffersCard";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InfiniteScroll from "react-infinite-scroll-component";
import "react-loading-skeleton/dist/skeleton.css";
const ViewPromoCodeList = ({
  popUpState,
  ChildhandleClose,
  cuponList,
  getCouponListRequest,
  language,
}) => {
  const [open, setOpen] = useState(false);
  const [isCopied, setIsCopied] = React.useState(false);
  const [shareCode, setShareCode] = React.useState("");
  const [selectedIndex, setSelectedIndex] = React.useState();
  const [t] = useTranslation("common");
  useEffect(() => {
    getCouponListRequest(language);
    if (popUpState == true) {
      setOpen(true);
    }
  }, [popUpState]);

  const handleClose = () => {
    setOpen(false);
    ChildhandleClose();
  };

  const onCopy = React.useCallback((value, cardIndex) => {
    setIsCopied(value);
    setSelectedIndex(cardIndex);
  }, []);

  const fetchData = () => {
    let data = {
      languageToShow: language,
      skip: Math.ceil(cuponList.data.length / 10) + 1,
      limit: 10,
      scroll: true,
    };
    getCouponListRequest(data);
  };
  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} id="couponListModal">
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <strong>Coupon Code List</strong>
            </Box>
            <Box>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent tabIndex={0} id="scrollableDiv">
          <div id="offers_slider">
            {cuponList != null ? (
              cuponList != null && cuponList?.data?.length == 0 ? (
                <></>
              ) : (
                <InfiniteScroll
                  dataLength={cuponList.total_count} //This is important field to render the next data
                  next={fetchData}
                  hasMore={cuponList.total_count > cuponList.data.length}
                  loader={<h4>Loading...</h4>}
                  scrollableTarget="scrollableDiv"
                >
                  {cuponList?.data.map((data, index) => {
                    return (
                      <OffersCard
                        shareCode={data.code}
                        setShareCode={setShareCode}
                        onCopy={onCopy}
                        isCopied={selectedIndex == index ? isCopied : false}
                        setIsCopied={setIsCopied}
                        key={index}
                        cardIndex={index}
                        couponData={data}
                      />
                    );
                  })}
                </InfiniteScroll>
              )
            ) : (
              <></>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  cuponList: selectCouponListData,
  language: selectlanguageToShow,
});
const mapDispatchToProps = (dispatch) => ({
  getCouponListRequest: (data) => dispatch(getCouponListRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewPromoCodeList);
