import React , { useEffect } from "react";
import { Button } from "react-bootstrap";
import { GrFacebookOption } from "react-icons/gr";
import { BsTwitterX } from "react-icons/bs";
import { BsSnapchat } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa6";
import { BsLinkedin } from "react-icons/bs";
import { GrInstagram } from "react-icons/gr";
import { GrYoutube } from "react-icons/gr";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import UnionPay from "./../../../assets/images/union.png";
import Visacard from "./../../../assets/images/visa-master-card.png";
import mastercard from "./../../../assets/images/master-card.png";
import applepay from "./../../../assets/images/pay.png";
import { useTranslation } from "react-i18next";
import { createStructuredSelector } from "reselect";
import { selectlanguageToShow } from "./../../../redux/language/language.selectors";
import { connect } from "react-redux";
const Footer = ({languageToShow}) => {
  const [t, i18n] = useTranslation("common");
  const navigate = useNavigate();
  const handleChangeLink = (data) => {
    window.scroll(0, 0);
    if (data == "/safety-security") {
      navigate("/safety-security");
    } else if (data == "/terms&conditions") {
      navigate("/terms&conditions");
    } else if (data == "/privacy-policy") {
      navigate("/privacy-policy");
    } else if (data == "/referral") {
      navigate("/referral");
    } else {
    }
  };
  const handleLinkOffers = () => {
    window.scroll(0, 0);
    navigate("/offers");
  };

  useEffect(() => {
 i18n.changeLanguage(languageToShow);
  }, [languageToShow]);

  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
            
              <img src="./img/logo-white.svg" style={{cursor:"pointer"}} onClick={() => { window.scroll(0, 0);   navigate("/");}} alt="" className="footerlogo" />
            
              <ul className="footlocation">
                <li>
                  <Link to="/contactus">
                    <>
                      <img src="./img/call-calling.svg" alt="" />{" "}
                      <span >{t("footer.talkToUs")}</span>{" "}
                    </>
                  </Link>
                </li>
                <li>
                  <img src="./img/send-2.svg" alt="" />{" "}
                  <a href="mailto:info@wfrlee.com">info@wfrlee.com</a>{" "}
                </li>
                <li>
                  <Link to="/aboutus">
                    <img src="./img/location.svg" alt="" />{" "}
                    {t("footer.MoreInformation")}{" "}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <div className="footerpadding">
                <h3>{t("footer.Company.heading")}</h3>
                <ul>
                  <li>
                    <Link to="/faq">{t("footer.Company.FAQ")}</Link>
                  </li>
                  <li style={{ cursor: "pointer" }} onClick={handleLinkOffers}>
                    <div>{t("footer.Company.OffersandDiscounts")}</div>
                  </li>
                  <li>
                    <Link to="/career">{t("footer.Company.Careers")}</Link>
                  </li>
                  <li>
                    <Link to="/aboutus">{t("footer.Company.Aboutus")}</Link>
                  </li>
                  <li>
                    <Link to="/contactus">{t("footer.Company.Contactus")}</Link>
                  </li>
                  <li>
                    <Link target="_blank" to="https://blog.wfrlee.com/">{t("footer.Company.Blog")}</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footerpadding">
                <h3>{t("footer.OtherLinks.heading")}</h3>
                <ul>
                  <li>
                    <Button onClick={() => handleChangeLink("/referral")}>
                      {t("footer.OtherLinks.ReferralPage")}
                    </Button>
                  </li>
                  <li>
                    <Button onClick={() => handleChangeLink("/privacy-policy")}>
                      {t("footer.OtherLinks.PrivacyPolicy")}
                    </Button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleChangeLink("/safety-security")}
                    >
                      {" "}
                      {t("footer.OtherLinks.SafetyandSecurity")}{" "}
                    </button>
                  </li>
                  <li>
                    <Button
                      onClick={() => handleChangeLink("/terms&conditions")}
                    >
                      {t("footer.OtherLinks.TermsandConditions")}
                    </Button>
                  </li>

                  <li>
                    <Link to="/partnerswithwfrlee">
                      {t("footer.OtherLinks.Partnerswithwfrleecom")}
                    </Link>
                  </li>
                  <li className="socialic">
                    <img
                      src={UnionPay}
                      width={40}
                      height={21}
                    />
                    <img
                      src={Visacard}
                      width={40}
                      height={21}
                    />
                    <img
                      src={mastercard}
                      width={40}
                      height={21}
                    />
                     <img src="./img/mada.svg" alt=""  width={40} height={21} />
                    <img
                      src={applepay}
                      style={{ width: "40px", height: "21px" }}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="whiteborder"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="footer-socialarea">
                <Link target="_blank" to="https://www.facebook.com/wfrlee.co">
                  <GrFacebookOption />
                </Link>
                <Link target="_blank" to="https://twitter.com/wfrlee">
                  <BsTwitterX />
                </Link>
                <Link
                  target="_blank"
                  to="https://www.instagram.com/wfrlee.co/?igshid=YmMyMTA2M2Y%3D"
                >
                  <GrInstagram />
                </Link>
                <Link
                  target="_blank"
                  to="https://www.youtube.com/channel/UCnIVCjPG_jv_OWYyyeWB_ug/search?app=desktop"
                >
                  <GrYoutube />
                </Link>
                {/* snapchat */}
                <Link
                  target="_blank"
                  to="https://www.snapchat.com/add/wfrlee?invite_id=KnkVW8lA&locale=en_SA%40calendar%3Dgregorian&share_id=VvpHRf6uQt6vbtnEzOZcLw&sid=505bb414396144338bef5679717a11f8"
                >
                  <BsSnapchat />
                </Link>
                {/* tiktok */}
                <Link
                  target="_blank"
                  to="https://www.tiktok.com/@wfrlee.com/video/7283499842154908936?is_from_webapp=1"
                >
                  <FaTiktok />
                </Link>
                {/* linkedin */}
                <Link
                  target="_blank"
                  to="https://www.linkedin.com/company/wfrlee/"
                >
                  <BsLinkedin />
                </Link>
              </div>
            </div>
            <div className="col-md-6">
              <div className="copyright">
                {/* Copyright @ Techit LLC. All Rights Reserved */}
                <a href="https://techit-llc.com/">{t("footer.Copyright")}</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
languageToShow: selectlanguageToShow,
});

export default connect(mapStateToProps)(Footer);
