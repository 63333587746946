import React, { useEffect } from "react";
import { selectRandomReview } from "../../redux/home/home.selectors";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import { BsFillStarFill } from "react-icons/bs";
import { BsStar } from "react-icons/bs";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import CMSLeftMenu from "../../components/cms/CMSLeftMenu";

import { useLocation } from "react-router-dom";
import { getHomePageRandomReviewRequest } from "../../redux/home/home.actions";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';

const RatingAndReview = ({
  selectRandomReview,
  getHomePageRandomReviewRequest,
}) => {
  const [t] = useTranslation("common");
  const StyledRating = styled(Rating)({
    "& .MuiRating-iconEmpty ": {
      color: "#eee",
      marginLeft: "5px",
    },
    "& .MuiRating-iconFilled": {
      color: "#FFC764",
      marginLeft: "5px",
    },
    "& .MuiRating-iconHover": {
      color: "#FFC764",
    },
  });
  const location = useLocation();

  useEffect(() => {
    getHomePageRandomReviewRequest();
    return () => {};
  }, []);

  useEffect(()=>{
  window.scrollTo(0,0);
  },[])

  return (
    <>
    { /* For SEO */}
    <Helmet>
      <title>wfrlee.com Customer Reviews: Authentic Hotel Feedback by Actual Guests</title>
      <meta name="description" content= "Explore honest ratings and reviews on wfrlee.com. Discover the feedback that sets us apart. Your opinion matters!" />
    </Helmet>
    <div className="commonpage">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <CMSLeftMenu location={location} />
          </div>
          <div className="col-md-9 ">
            <h1>{t("CMSLeftMenu.ratingandreview")}</h1>
            &nbsp;
            <div className="card_list">
              { (!selectRandomReview || !selectRandomReview.customer_review) && "No review found" }
              {selectRandomReview != null && selectRandomReview.customer_review && selectRandomReview?.customer_review.length > 0 && selectRandomReview?.customer_review.map((data, index) => (
                  <div
                    className="reviewbox"
                    style={{
                      backgroundColor: index % 2 === 0 ? "#374a77" : "#5287b3",
                    }}
                  >
                    <p>{data?.review} </p>
                    <div className="reviewstar">
                      
                      <Box>
                        <StyledRating
                          name="read-only"
                          defaultValue={Number(data?.rating)}
                          icon={<BsFillStarFill fontSize="inherit" />}
                          emptyIcon={<BsStar fontSize="inherit" />}
                          readOnly
                        />
                        
                      </Box>
                    </div>
                    <div className="authorname">{data?.customer_name}</div>
                  </div>
                ))}
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  selectRandomReview: selectRandomReview,
});

const mapDispatchToProps = (dispatch) => ({
  // hotelToggleAddOrNot: (data) => dispatch(hotelToggleAddOrNot(data)),
  getHomePageRandomReviewRequest: (data) =>
    dispatch(getHomePageRandomReviewRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RatingAndReview);
