import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectlanguageToShow } from "./../../../redux/language/language.selectors";

const GoogleTranslate = ({ languageToShow }) => {
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit&hl=en"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en-US",
        autoDisplay: false,
        excludedLanguages: "ar",
        defaultLanguage: "en",
        targetLanguage: "en",
      },
      "google_translate_element"
    );
  };

  return (
    <>
      <div id="google_translate_element" lang="en-US"></div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  languageToShow: selectlanguageToShow,
});

export default connect(mapStateToProps)(GoogleTranslate);
