import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import ContactusForm from "../../components/contact/ContactusForm";
import CMSLeftMenu from "../../components/cms/CMSLeftMenu";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';

const ContactPage = () => {
  const [t] = useTranslation("common");
  const location = useLocation();
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      {/* //////////Common page Section/////////// */}
      { /* For SEO */}
      <Helmet>
        <title>Get in Touch: Contact wfrlee.com for assistance and inquiries.</title>
        <meta name="description" content="Contact wfrlee.com for any inquiries or assistance you may need. We're here to help and provide the information you're looking for. Reach out today!" />
      </Helmet>

      <div className="commonpage">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <CMSLeftMenu location={location} />

            </div>
            <div className="col-md-9">
              <div className="WL_contactmwrp customform rightFormWidth">
                <h1>{t("ContactusPage.contactusHeading")}</h1>

                <ContactusForm />
                {/* //////////call text Section start /////////// */}
                <div className="WL_contacttextwrp">
                  <div className="WL_innercontacttext">
                    <p className="WL_innercontacttextinner">
                      {t("ContactusPage.CallUsat")}
                      <span>
                        <Link to="tel:+971 589-706-050">+971 589-706-050</Link>
                      </span>
                    </p>
                  </div>

                  <div className="WL_innercontacttext">
                    <p className="WL_innercontacttextinner">
                      {t("ContactusPage.TextUsat")}
                      <span>
                        <a href="mailto:info@wfrlee.com">
                          {/* info@wfrlee.com */}
                          {t("ContactusPage.companyMail")}
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
                {/* //////////call text Section end /////////// */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* //////////Common page Section/////////// */}
    </>
  );
};

export default ContactPage;
