import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import moment from "moment";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import validator from "validator";

const MobileBookingDetails = forwardRef(
  (
    {
      hourlyCheckoutDetails,
      selectedCheckInTime,
      setSelectedCheckInTime,
      userAuthData,
      currencyToShow,
      applyWalletBal,
      setWalletBal,
      setWalletBalErr,
      walletBal,
      pointsApplied,
      walletBalErr,
      applyHotelOffers,
      paymentModeValue,
    },
    ref
  ) => {
    const [t] = useTranslation("common");
    const specificSectionRef = useRef();
    const [scrollToSection, setScrollToSection] = useState(false);

    React.useEffect(() => {
      if (scrollToSection && specificSectionRef.current) {
        console.log("Call Here -->> ");
        specificSectionRef.current.scrollIntoView({ behavior: "smooth" });
        setScrollToSection(false);
      }
    }, [scrollToSection]);

    // For handle payment summery click
    useEffect(() => {
      const handleClick = (event) => {
        // Check if the clicked element has a specific class
        if (event.target.classList.contains("payment_summary")) {
          // Your click event logic here
          console.log('Element with class "payment_summary" clicked!');
          setScrollToSection(true);
          // specificSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      };

      // Attach click event to the parent element using event delegation
      const contentElement = ref.current;
      if (contentElement) {
        contentElement.addEventListener("click", handleClick);
      }

      // Cleanup the event listener on component unmount
      return () => {
        if (contentElement) {
          contentElement.removeEventListener("click", handleClick);
        }
      };
    }, []);

    const checkInDateTime = moment(
      `${hourlyCheckoutDetails?.data?.booking_details?.check_in_date}-${selectedCheckInTime}`,
      "YYYY-MM-DD-HH:mm a"
    ).format("DD MMM YYYY - hh:mm A");

    const checkOutDateTime = moment(checkInDateTime, "DD MMM YYYY - hh:mm A")
      .add({
        hours: 6,
      })
      .format("DD MMM YYYY - hh:mm A");

    if (window.innerWidth <= 767) {
      return (
        <div className="WL_inner_leftwrp">
          <ul className="w-100 d-flex flex-column gap-1">
            <li className="d-flex align-items-center justify-content-between flex-grow-1">
              <p className="WL_select_checkintime_text">
                {t("checkoutPage.SelectCheckInTime")}
              </p>
              <div className="WL_checkintime_selectbox">
                <Form.Select
                  aria-label="CheckIn"
                  value={selectedCheckInTime}
                  onChange={(e) => {
                    setSelectedCheckInTime(e.target.value);
                  }}
                >
                  <option disabled value="">
                    {t("checkoutPage.SelectTime")}
                  </option>
                  {hourlyCheckoutDetails?.data?.booking_details?.check_in_slots.map(
                    (CHISlot, index) => (
                      <option value={CHISlot} key={index}>
                        {CHISlot}
                      </option>
                    )
                  )}
                </Form.Select>
              </div>
            </li>
            <li className="d-flex align-items-center justify-content-between flex-grow-1">
              <p className="WL_check_txt">{t("checkoutPage.CheckIn")}</p>
              <strong dir="ltr">{checkInDateTime}</strong>
            </li>
            {selectedCheckInTime ? (
              <li className="d-flex align-items-center justify-content-between flex-grow-1">
                <p className="WL_check_txt">{t("checkoutPage.CheckOut")}</p>
                <strong dir="ltr">{checkOutDateTime}</strong>
              </li>
            ) : null}
            <li className="d-flex align-items-center justify-content-between flex-grow-1">
              <p className="WL_select_checkintime_text">
                {t("checkoutPage.RoomType")}
              </p>
              <strong>
                {hourlyCheckoutDetails?.data?.booking_details?.room_type_name}
              </strong>
            </li>
            <li className="d-flex align-items-center justify-content-between flex-grow-1">
              <p className="WL_select_checkintime_text">
                {t("checkoutPage.NoofRoom")}
              </p>
              <strong>
                {hourlyCheckoutDetails?.data?.request_data.no_of_rooms}{" "}
                {hourlyCheckoutDetails?.data?.request_data.no_of_rooms > 1
                  ? t("checkoutPage.Rooms")
                  : t("checkoutPage.Room")}
              </strong>
            </li>
            <li className="d-flex align-items-center justify-content-between flex-grow-1">
              <p className="WL_select_checkintime_text">
                {t("checkoutPage.NoofGuest")}
              </p>
              <strong>
                {hourlyCheckoutDetails?.data?.request_data.adults}{" "}
                {hourlyCheckoutDetails?.data?.request_data.adults > 1
                  ? t("checkoutPage.Guests")
                  : t("checkoutPage.Guest")}
              </strong>
            </li>

            <li className="d-flex align-items-center justify-content-between flex-grow-1">
              <p className="WL_check_txt">{t("checkoutPage.TotalStay")}</p>
              <p className="WL_totl_text">
                <strong>
                  {
                    hourlyCheckoutDetails?.data?.booking_details
                      ?.total_length_of_stay_hours
                  }
                  {"  "}
                  {hourlyCheckoutDetails?.data?.booking_details
                    ?.total_length_of_stay_hours > 1
                    ? t("checkoutPage.Hours")
                    : t("checkoutPage.Hour")}
                </strong>
              </p>
            </li>
          </ul>
          <div className="WL_paymentwrp">
            <Accordion
              id="payment_summery_show"
              name="payment_summery_show"
              ref={specificSectionRef}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h3>{t("checkoutPage.PaymentSummary.PaymentSummary")}</h3>
              </AccordionSummary>
              {/* Payment Summar Calculation Part */}
              <AccordionDetails>
                <div className="WL_payment_inerwrp ">
                  <div className="WL_payment_iner_left">
                    {t("checkoutPage.PaymentSummary.Netrate")}:
                  </div>
                  <div className="WL_payment_iner_right">
                    {}

                    {applyHotelOffers !== null &&
                    "data" in applyHotelOffers &&
                    "net_rate" in applyHotelOffers.data
                      ? Number(
                          applyHotelOffers.data.net_rate *
                            currencyToShow.convertedRates
                        ).toFixed(2)
                      : ""}
                    {applyHotelOffers === null &&
                    hourlyCheckoutDetails &&
                    "data" in hourlyCheckoutDetails &&
                    "payment_summery_details" in hourlyCheckoutDetails.data &&
                    "net_rate" in
                      hourlyCheckoutDetails.data.payment_summery_details
                      ? Number(
                          hourlyCheckoutDetails.data.payment_summery_details
                            .net_rate * currencyToShow.convertedRates
                        ).toFixed(2)
                      : ""}
                    {" " + currencyToShow.current}
                  </div>
                </div>

                <div className="WL_payment_inerwrp">
                  <div className="WL_payment_iner_left">
                    {t("checkoutPage.PaymentSummary.vat15per")}
                    {applyHotelOffers != null
                      ? applyHotelOffers.data.is_tax_percentage
                        ? `(${applyHotelOffers.data.tax_amount}%)`
                        : null
                      : hourlyCheckoutDetails?.data.payment_summery_details
                          .is_tax_percentage
                      ? `(${hourlyCheckoutDetails?.data.payment_summery_details.tax_amount}%)`
                      : null}
                    :{" "}
                  </div>
                  <div className="WL_payment_iner_right">
                    {applyHotelOffers !== null &&
                    "data" in applyHotelOffers &&
                    "taxes" in applyHotelOffers.data
                      ? Number(
                          applyHotelOffers.data.taxes *
                            currencyToShow.convertedRates
                        ).toFixed(2)
                      : ""}
                    {applyHotelOffers === null &&
                    hourlyCheckoutDetails &&
                    "data" in hourlyCheckoutDetails &&
                    "payment_summery_details" in hourlyCheckoutDetails.data &&
                    "taxes" in
                      hourlyCheckoutDetails.data.payment_summery_details
                      ? Number(
                          hourlyCheckoutDetails.data.payment_summery_details
                            .taxes * currencyToShow.convertedRates
                        ).toFixed(2)
                      : ""}
                    {" " + currencyToShow.current}
                  </div>
                </div>

                {/******  municipal taxes**** */}

                <div className="WL_payment_inerwrp_municiplity">
                  <div className="WL_payment_inerwrp ">
                    <div className="WL_payment_iner_left">
                      {t("checkoutPage.PaymentSummary.Municipality")}
                      {applyHotelOffers != null
                        ? applyHotelOffers.data.is_municipal_tax_percentage
                          ? `(${applyHotelOffers.data.municipal_tax_amount}%)`
                          : null
                        : hourlyCheckoutDetails?.data.payment_summery_details
                            .is_municipal_tax_percentage
                        ? `(${hourlyCheckoutDetails?.data.payment_summery_details.municipal_tax_amount}%)`
                        : null}
                      :{" "}
                    </div>

                    <div className="WL_payment_iner_right">
                      {/* Municipality tax  Mar 30 */}

                      {applyHotelOffers !== null &&
                      "data" in applyHotelOffers &&
                      "municipal_taxes" in applyHotelOffers.data
                        ? Number(
                            applyHotelOffers.data.municipal_taxes *
                              currencyToShow.convertedRates
                          ).toFixed(2)
                        : ""}
                      {applyHotelOffers === null &&
                      hourlyCheckoutDetails &&
                      "data" in hourlyCheckoutDetails &&
                      "payment_summery_details" in hourlyCheckoutDetails.data &&
                      "municipal_taxes" in
                        hourlyCheckoutDetails.data.payment_summery_details
                        ? Number(
                            hourlyCheckoutDetails.data.payment_summery_details
                              .municipal_taxes * currencyToShow.convertedRates
                          ).toFixed(2)
                        : ""}
                      {" " + currencyToShow.current}
                      {/* Municipality tax  Mar 30 */}
                    </div>
                  </div>
                  {/* Coupon code tax  Mar 30 */}

                  {applyHotelOffers != null
                    ? applyHotelOffers?.data?.coupon_amount_applied && (
                        <div className="WL_payment_inerwrp">
                          <div className="WL_payment_iner_left">
                            <strong>
                              {t(
                                "checkoutPage.PaymentSummary.AppliedCouponAmount"
                              )}
                              :
                            </strong>{" "}
                          </div>
                          <div className="WL_payment_iner_right">
                            <strong>
                              {applyHotelOffers !== null &&
                              "data" in applyHotelOffers &&
                              "coupon_amount_applied" in applyHotelOffers.data
                                ? Number(
                                    applyHotelOffers.data
                                      .coupon_amount_applied *
                                      currencyToShow.convertedRates
                                  ).toFixed(2)
                                : ""}

                              {" " + currencyToShow.current}
                            </strong>
                          </div>
                        </div>
                      )
                    : null}
                </div>
                {/******  municipal taxes**** */}

                <div className="WL_payment_inerwrp">
                  <div className="WL_payment_iner_left">
                    {t("checkoutPage.PaymentSummary.Servicefee")}:
                  </div>
                  <div className="WL_payment_iner_right">
                    {applyHotelOffers !== null &&
                    "data" in applyHotelOffers &&
                    "service_fee" in applyHotelOffers.data
                      ? Number(
                          applyHotelOffers.data.service_fee *
                            currencyToShow.convertedRates
                        ).toFixed(2)
                      : ""}

                    {applyHotelOffers === null &&
                    hourlyCheckoutDetails &&
                    "data" in hourlyCheckoutDetails &&
                    "payment_summery_details" in hourlyCheckoutDetails.data &&
                    "service_fee" in
                      hourlyCheckoutDetails.data.payment_summery_details
                      ? Number(
                          hourlyCheckoutDetails.data.payment_summery_details
                            .service_fee * currencyToShow.convertedRates
                        ).toFixed(2)
                      : ""}
                    {" " + currencyToShow.current}
                  </div>
                </div>

                {applyHotelOffers != null
                  ? applyHotelOffers?.data?.wallet_balance_applied && (
                      <div className="WL_payment_inerwrp">
                        <div className="WL_payment_iner_left">
                          <strong>
                            {t(
                              "checkoutPage.PaymentSummary.AppliedWalletBalance"
                            )}
                          </strong>{" "}
                        </div>
                        <div className="WL_payment_iner_right">
                          <strong>
                            {applyHotelOffers !== null &&
                            "data" in applyHotelOffers &&
                            "wallet_balance_applied" in applyHotelOffers.data
                              ? Number(
                                  applyHotelOffers.data.wallet_balance_applied *
                                    currencyToShow.convertedRates
                                ).toFixed(2)
                              : ""}

                            {" " + currencyToShow.current}
                          </strong>
                        </div>
                      </div>
                    )
                  : null}
                {paymentModeValue == "cash" ? (
                  <div className="WL_payment_inerwrp">
                    <div className="WL_payment_iner_left">
                      <strong>
                        {t("checkoutPage.PaymentSummary.AmountToBePaidNow")}
                      </strong>{" "}
                    </div>
                    <div className="WL_payment_iner_right">
                      <strong>
                        {applyHotelOffers !== null
                          ? Number(
                              (Number(
                                applyHotelOffers?.data.amount_to_be_paid_now
                              ) -
                                Number(applyHotelOffers?.data.service_fee)) *
                                currencyToShow.convertedRates
                            ).toFixed(2)
                          : Number(
                              (Number(
                                hourlyCheckoutDetails?.data
                                  .payment_summery_details.amount_to_be_paid_now
                              ) -
                                Number(
                                  hourlyCheckoutDetails?.data
                                    ?.payment_summery_details.service_fee
                                )) *
                                currencyToShow.convertedRates
                            ).toFixed(2)}
                        {" " + currencyToShow.current}

                        {/* Pay At hotem  Mar 30 */}
                      </strong>
                    </div>
                  </div>
                ) : null}
              </AccordionDetails>
              {/* Wallet balance applied */}
            </Accordion>
            <div className="WL_payment_inerwrp total-payment">
              <div className="WL_payment_iner_left">
                <strong>{t("checkoutPage.PaymentSummary.Amount")}</strong>{" "}
              </div>
              <div className="WL_payment_iner_right">
                <strong>
                  {paymentModeValue == "cash"
                    ? applyHotelOffers !== null
                      ? (
                          Number(applyHotelOffers?.data.service_fee) *
                          Number(currencyToShow.convertedRates)
                        ).toFixed(2)
                      : (
                          Number(
                            hourlyCheckoutDetails?.data?.payment_summery_details
                              .service_fee
                          ) * Number(currencyToShow.convertedRates)
                        ).toFixed(2)
                    : applyHotelOffers !== null
                    ? (
                        Number(applyHotelOffers?.data.amount_to_be_paid_now) *
                        Number(currencyToShow.convertedRates)
                      ).toFixed(2)
                    : (
                        Number(
                          hourlyCheckoutDetails?.data.payment_summery_details
                            .amount_to_be_paid_now
                        ) * Number(currencyToShow.convertedRates)
                      ).toFixed(2)}
                  {" " + currencyToShow.current}
                </strong>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="WL_inner_leftwrp">
        {/* ///checkout checkin wrp start/// */}
        <div className="WL_checkwrp">
          <div className="WL_checkwrp_left">
            <p className="WL_check_txt">{t("checkoutPage.CheckIn")}</p>
            <p className="WL_check_txt2">
              {moment(
                hourlyCheckoutDetails?.data?.booking_details?.check_in_date
                // ,"DD/MM/YYYY"
              ).format("ddd, ll")}
            </p>
          </div>
          <div className="WL_checkwrp_left">
            <p className="WL_check_txt">{t("checkoutPage.TotalStay")}</p>
            <p className="WL_totl_text">
              <strong>
                {
                  hourlyCheckoutDetails?.data?.booking_details
                    ?.total_length_of_stay_hours
                }
                {"  "}
                {hourlyCheckoutDetails?.data?.booking_details
                  ?.total_length_of_stay_hours > 1
                  ? t("checkoutPage.Hours")
                  : t("checkoutPage.Hour")}
              </strong>
            </p>
          </div>
        </div>
        {/* ///checkout checkin wrp end/// */}

        {/* ///////Select Check In Time start/////// */}
        <div className="WL_select_checkintime">
          <p className="WL_select_checkintime_text">
            {t("checkoutPage.SelectCheckInTime")}
          </p>
          <div className="WL_checkintime_selectbox">
            <Form.Select
              aria-label="CheckIn"
              value={selectedCheckInTime}
              onChange={(e) => {
                setSelectedCheckInTime(e.target.value);
              }}
            >
              <option disabled value="">
                {t("checkoutPage.SelectTime")}
              </option>
              {hourlyCheckoutDetails?.data?.booking_details?.check_in_slots.map(
                (CHISlot, index) => (
                  <option value={CHISlot} key={index}>
                    {CHISlot}
                  </option>
                )
              )}
            </Form.Select>
          </div>
        </div>
        {/* ///////Select Check In Time end/////// */}

        {/* ///////Select Check Out Time start/////// */}
        <div className="WL_select_checkintime">
          <p className="WL_select_checkintime_text">
            {t("checkoutPage.CheckOutTime")}
          </p>
          <div className="WL_checkouttime_text">
            {selectedCheckInTime
              ? moment(selectedCheckInTime, ["hh:mm A"])
                  .add(
                    hourlyCheckoutDetails?.data?.booking_details
                      ?.total_length_of_stay_hours,
                    "hours"
                  )
                  .format("hh:mm A")
              : moment(
                  `${hourlyCheckoutDetails?.data?.booking_details?.check_out_time}`,
                  ["hh:mm"]
                ).format("hh:mm A")}
          </div>
        </div>
        {/* ///////Select Check Out Time end/////// */}

        {/* ///Total length wrp start/// */}
        <div className="WL_totl_textwrp">
          <p className="WL_totl_text">
            <span>{t("checkoutPage.NoofRoom")}:</span>{" "}
            <strong>
              {hourlyCheckoutDetails?.data?.request_data.no_of_rooms}{" "}
              {hourlyCheckoutDetails?.data?.request_data.no_of_rooms > 1
                ? t("checkoutPage.Rooms")
                : t("checkoutPage.Room")}
            </strong>
          </p>
          <p className="WL_totl_text">
            <span>{t("checkoutPage.RoomType")}:</span>{" "}
            <strong>
              {hourlyCheckoutDetails?.data?.booking_details?.room_type_name}
            </strong>
          </p>
          <p className="WL_totl_text">
            {/* Fits up to: */}
            <span>{t("checkoutPage.NoofGuest")}:</span>{" "}
            <strong>
              {hourlyCheckoutDetails?.data?.request_data.adults}{" "}
              {hourlyCheckoutDetails?.data?.request_data.adults > 1
                ? t("checkoutPage.Guests")
                : t("checkoutPage.Guest")}
            </strong>
          </p>
        </div>
        {/* ///Total length wrp end/// */}

        {/* ////////////////////Available balance wrp start/////////////// */}

        {/* ///Bid amount wrp start/// */}
        {userAuthData != null && (
          <div className="WL_bidamnt_wrp mt-4">
            <div className="WL_amnt_textheading">
              {t("checkoutPage.AvailableWalletBalance")}
            </div>
            <div className="WL_pointbox">
              {hourlyCheckoutDetails?.data?.user_details?.wallet_balance_points}{" "}
              {t("checkoutPage.POINTS")}
            </div>
            <div className="d-flex justify-content-start w-100">
              <p className="WL_totl_text_conversion">
                (
                <span>
                  {currencyToShow.current === "INR"
                    ? Number(
                        hourlyCheckoutDetails?.data
                          ?.wallet_points_conversion_rate?.wallet_point_value /
                          22
                      ).toFixed(2)
                    : hourlyCheckoutDetails?.data?.wallet_points_conversion_rate
                        ?.wallet_point_value}{" "}
                  {t("checkoutPage.Point")}
                </span>{" "}
                ={" "}
                <span>
                  {Number(
                    hourlyCheckoutDetails?.data?.wallet_points_conversion_rate
                      ?.conversion_amount * currencyToShow.convertedRates
                  ).toFixed(2)}{" "}
                  {currencyToShow.current}
                </span>
                )
              </p>
            </div>
          </div>
        )}

        {/* ///Bid amount wrp end/// */}

        {/* ///Card details wrp start/// */}
        {userAuthData != null && (
          <div className="WL_carddetailst_wrp customform applyform">
            <Form onSubmit={applyWalletBal}>
              <div className="WL_innercardholder">
                <Form.Group controlId="formCheckoutCardDetails">
                  <Form.Control
                    type="text"
                    placeholder={t("checkoutPage.EnterwalletBalance")}
                    className="WL_frm_wcrd WL_frm_wcrd_points"
                    name="walletBal"
                    onChange={(e) => {
                      setWalletBal(e.target.value);
                      if (!validator.isNumeric(e.target.value)) {
                        setWalletBalErr(
                          t("checkoutPage.validationMsg.PleaseEnteronlyNumber")
                        );
                        return;
                      } else {
                        setWalletBalErr("");
                      }
                    }}
                    value={walletBal}
                    disabled={
                      hourlyCheckoutDetails?.data?.user_details
                        ?.wallet_balance_points > 0
                        ? pointsApplied
                          ? true
                          : false
                        : true
                    }
                  />
                  <Form.Text className="text-muted">{walletBalErr}</Form.Text>
                </Form.Group>
                <Button
                  variant=""
                  type="submit"
                  className="WL_card_submitbtn"
                  disabled={
                    hourlyCheckoutDetails?.data?.user_details
                      ?.wallet_balance_points > 0
                      ? false
                      : true
                  }
                >
                  {pointsApplied
                    ? t("checkoutPage.remove")
                    : t("checkoutPage.apply")}
                </Button>
              </div>
            </Form>
          </div>
        )}
        {/* ///card details wrp end/// */}
        {/* ////////////////////Available balance wrp end/////////////// */}

        {/* ///Total summary wrp start/// */}
        <div className="WL_paymentwrp">
          <Accordion
            id="payment_summery_show"
            name="payment_summery_show"
            ref={specificSectionRef}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <h3>{t("checkoutPage.PaymentSummary.PaymentSummary")}</h3>
            </AccordionSummary>
            {/* Payment Summar Calculation Part */}
            <AccordionDetails>
              <div className="WL_payment_inerwrp ">
                <div className="WL_payment_iner_left">
                  {t("checkoutPage.PaymentSummary.Netrate")}:
                </div>
                <div className="WL_payment_iner_right">
                  {}

                  {applyHotelOffers !== null &&
                  "data" in applyHotelOffers &&
                  "net_rate" in applyHotelOffers.data
                    ? Number(
                        applyHotelOffers.data.net_rate *
                          currencyToShow.convertedRates
                      ).toFixed(2)
                    : ""}
                  {applyHotelOffers === null &&
                  hourlyCheckoutDetails &&
                  "data" in hourlyCheckoutDetails &&
                  "payment_summery_details" in hourlyCheckoutDetails.data &&
                  "net_rate" in
                    hourlyCheckoutDetails.data.payment_summery_details
                    ? Number(
                        hourlyCheckoutDetails.data.payment_summery_details
                          .net_rate * currencyToShow.convertedRates
                      ).toFixed(2)
                    : ""}
                  {" " + currencyToShow.current}
                </div>
              </div>

              <div className="WL_payment_inerwrp">
                <div className="WL_payment_iner_left">
                  {t("checkoutPage.PaymentSummary.vat15per")}
                  {applyHotelOffers != null
                    ? applyHotelOffers.data.is_tax_percentage
                      ? `(${applyHotelOffers.data.tax_amount}%)`
                      : null
                    : hourlyCheckoutDetails?.data.payment_summery_details
                        .is_tax_percentage
                    ? `(${hourlyCheckoutDetails?.data.payment_summery_details.tax_amount}%)`
                    : null}
                  :{" "}
                </div>
                <div className="WL_payment_iner_right">
                  {applyHotelOffers !== null &&
                  "data" in applyHotelOffers &&
                  "taxes" in applyHotelOffers.data
                    ? Number(
                        applyHotelOffers.data.taxes *
                          currencyToShow.convertedRates
                      ).toFixed(2)
                    : ""}
                  {applyHotelOffers === null &&
                  hourlyCheckoutDetails &&
                  "data" in hourlyCheckoutDetails &&
                  "payment_summery_details" in hourlyCheckoutDetails.data &&
                  "taxes" in hourlyCheckoutDetails.data.payment_summery_details
                    ? Number(
                        hourlyCheckoutDetails.data.payment_summery_details
                          .taxes * currencyToShow.convertedRates
                      ).toFixed(2)
                    : ""}
                  {" " + currencyToShow.current}
                </div>
              </div>

              {/******  municipal taxes**** */}

              <div className="WL_payment_inerwrp_municiplity">
                <div className="WL_payment_inerwrp ">
                  <div className="WL_payment_iner_left">
                    {t("checkoutPage.PaymentSummary.Municipality")}
                    {applyHotelOffers != null
                      ? applyHotelOffers.data.is_municipal_tax_percentage
                        ? `(${applyHotelOffers.data.municipal_tax_amount}%)`
                        : null
                      : hourlyCheckoutDetails?.data.payment_summery_details
                          .is_municipal_tax_percentage
                      ? `(${hourlyCheckoutDetails?.data.payment_summery_details.municipal_tax_amount}%)`
                      : null}
                    :{" "}
                  </div>

                  <div className="WL_payment_iner_right">
                    {/* Municipality tax  Mar 30 */}

                    {applyHotelOffers !== null &&
                    "data" in applyHotelOffers &&
                    "municipal_taxes" in applyHotelOffers.data
                      ? Number(
                          applyHotelOffers.data.municipal_taxes *
                            currencyToShow.convertedRates
                        ).toFixed(2)
                      : ""}
                    {applyHotelOffers === null &&
                    hourlyCheckoutDetails &&
                    "data" in hourlyCheckoutDetails &&
                    "payment_summery_details" in hourlyCheckoutDetails.data &&
                    "municipal_taxes" in
                      hourlyCheckoutDetails.data.payment_summery_details
                      ? Number(
                          hourlyCheckoutDetails.data.payment_summery_details
                            .municipal_taxes * currencyToShow.convertedRates
                        ).toFixed(2)
                      : ""}
                    {" " + currencyToShow.current}
                    {/* Municipality tax  Mar 30 */}
                  </div>
                </div>
                {/* Coupon code tax  Mar 30 */}

                {applyHotelOffers != null
                  ? applyHotelOffers?.data?.coupon_amount_applied && (
                      <div className="WL_payment_inerwrp">
                        <div className="WL_payment_iner_left">
                          <strong>
                            {t(
                              "checkoutPage.PaymentSummary.AppliedCouponAmount"
                            )}
                            :
                          </strong>{" "}
                        </div>
                        <div className="WL_payment_iner_right">
                          <strong>
                            {applyHotelOffers !== null &&
                            "data" in applyHotelOffers &&
                            "coupon_amount_applied" in applyHotelOffers.data
                              ? Number(
                                  applyHotelOffers.data.coupon_amount_applied *
                                    currencyToShow.convertedRates
                                ).toFixed(2)
                              : ""}

                            {" " + currencyToShow.current}
                          </strong>
                        </div>
                      </div>
                    )
                  : null}
              </div>
              {/******  municipal taxes**** */}

              <div className="WL_payment_inerwrp">
                <div className="WL_payment_iner_left">
                  {t("checkoutPage.PaymentSummary.Servicefee")}:
                </div>
                <div className="WL_payment_iner_right">
                  {applyHotelOffers !== null &&
                  "data" in applyHotelOffers &&
                  "service_fee" in applyHotelOffers.data
                    ? Number(
                        applyHotelOffers.data.service_fee *
                          currencyToShow.convertedRates
                      ).toFixed(2)
                    : ""}

                  {applyHotelOffers === null &&
                  hourlyCheckoutDetails &&
                  "data" in hourlyCheckoutDetails &&
                  "payment_summery_details" in hourlyCheckoutDetails.data &&
                  "service_fee" in
                    hourlyCheckoutDetails.data.payment_summery_details
                    ? Number(
                        hourlyCheckoutDetails.data.payment_summery_details
                          .service_fee * currencyToShow.convertedRates
                      ).toFixed(2)
                    : ""}
                  {" " + currencyToShow.current}
                </div>
              </div>

              {applyHotelOffers != null
                ? applyHotelOffers?.data?.wallet_balance_applied && (
                    <div className="WL_payment_inerwrp">
                      <div className="WL_payment_iner_left">
                        <strong>
                          {t(
                            "checkoutPage.PaymentSummary.AppliedWalletBalance"
                          )}
                        </strong>{" "}
                      </div>
                      <div className="WL_payment_iner_right">
                        <strong>
                          {applyHotelOffers !== null &&
                          "data" in applyHotelOffers &&
                          "wallet_balance_applied" in applyHotelOffers.data
                            ? Number(
                                applyHotelOffers.data.wallet_balance_applied *
                                  currencyToShow.convertedRates
                              ).toFixed(2)
                            : ""}

                          {" " + currencyToShow.current}
                        </strong>
                      </div>
                    </div>
                  )
                : null}
              {paymentModeValue == "cash" ? (
                <div className="WL_payment_inerwrp">
                  <div className="WL_payment_iner_left">
                    <strong>
                      {t("checkoutPage.PaymentSummary.AmountToBePaidNow")}
                    </strong>{" "}
                  </div>
                  <div className="WL_payment_iner_right">
                    <strong>
                      {applyHotelOffers !== null
                        ? Number(
                            (Number(
                              applyHotelOffers?.data.amount_to_be_paid_now
                            ) -
                              Number(applyHotelOffers?.data.service_fee)) *
                              currencyToShow.convertedRates
                          ).toFixed(2)
                        : Number(
                            (Number(
                              hourlyCheckoutDetails?.data
                                .payment_summery_details.amount_to_be_paid_now
                            ) -
                              Number(
                                hourlyCheckoutDetails?.data
                                  ?.payment_summery_details.service_fee
                              )) *
                              currencyToShow.convertedRates
                          ).toFixed(2)}
                      {" " + currencyToShow.current}

                      {/* Pay At hotem  Mar 30 */}
                    </strong>
                  </div>
                </div>
              ) : null}
            </AccordionDetails>
            {/* Wallet balance applied */}
          </Accordion>
          <div className="WL_payment_inerwrp total-payment">
            <div className="WL_payment_iner_left">
              <strong>{t("checkoutPage.PaymentSummary.Amount")}</strong>{" "}
            </div>
            <div className="WL_payment_iner_right">
              <strong>
                {paymentModeValue == "cash"
                  ? applyHotelOffers !== null
                    ? (
                        Number(applyHotelOffers?.data.service_fee) *
                        Number(currencyToShow.convertedRates)
                      ).toFixed(2)
                    : (
                        Number(
                          hourlyCheckoutDetails?.data?.payment_summery_details
                            .service_fee
                        ) * Number(currencyToShow.convertedRates)
                      ).toFixed(2)
                  : applyHotelOffers !== null
                  ? (
                      Number(applyHotelOffers?.data.amount_to_be_paid_now) *
                      Number(currencyToShow.convertedRates)
                    ).toFixed(2)
                  : (
                      Number(
                        hourlyCheckoutDetails?.data.payment_summery_details
                          .amount_to_be_paid_now
                      ) * Number(currencyToShow.convertedRates)
                    ).toFixed(2)}
                {" " + currencyToShow.current}
              </strong>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default MobileBookingDetails;
