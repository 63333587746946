import React, { useEffect, useState, useCallback, useRef } from "react";
import { Button, Form} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import validator from "validator";
import { connect } from "react-redux";

import { selectUserLoginData } from "./../../redux/user/user.selectors";
import {
  addCareerRequest,
  stateClearAfterTask,
} from "./../../redux/useraccount/useraccount.actions";
import {
  careerFileUploadRequest,
  resetFileStore,
} from "./../../redux/common/fileUpload.actions";
import {
  selectFileUploadData,
  selectFileLoading,
} from "./../../redux/common/fileUpload.selectors";

import {
  selectAddCareerData,
  selectCareerLoading,
} from "./../../redux/useraccount/useraccount.selectors";
import { createStructuredSelector } from "reselect";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Spinner from "react-bootstrap/Spinner";
import { BiUpload } from "react-icons/bi";
import SuccessDialog from "../common/dialogs/SuccessDialog";
import {
  MobileNumberValidator,
  NumberValidator,
  EmailValidator,
} from "../../constants/InputValidator";
import { useTranslation } from "react-i18next";

const CareerForm = ({
  addCareerRequest,
  careerData,
  stateClearAfterTask,
  careerFileUploadRequest,
  fileData,
  resetFileStore,
  fileLoading,
  careerLoading,
}) => {
  const [t] = useTranslation("common");
  const [fileName, setFileName] = useState("");

  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobileNo: "",
    subject: "",
    message: "",
    attachments: [],
  });

  const [userDataError, setuserDataError] = useState({
    firstnameErr: "",
    lastnameErr: "",
    emailErr: "",
    mobileNoErr: "",
    subjectErr: "",
    messageErr: "",
    termsmessage: "",
  });
  const [countryCode, setCountryCode] = React.useState("+966");
  const [countryCodeErr, setCountryCodeErr] = React.useState("");
  const [fileSize, setFileSize] = React.useState("");
  const [fileDatafileLink, setFileDatafileLink] = React.useState("");
  const [documentFileObj, setDocumentFileObj] = React.useState({
    document: "",
  });
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const translatedMessageObj = {
    MobileNumberValidatorMsgObj: {
      pleaseEnterYourMobileNumber: t(
        "validatorMsg.pleaseEnterYourMobileNumber"
      ),
      PleaseEnteronlyNumber: t("validatorMsg.PleaseEnteronlyNumber"),
      Atleast10DigitsRequired: t("validatorMsg.Atleast10DigitsRequired"),
      MobileNumberIsInvalid: t("validatorMsg.MobileNumberIsInvalid"),
    },
    EmailValidatorMsgObj: {
      PleaseEnterYourEmail: t("validatorMsg.PleaseEnterYourEmail"),
      PleaseEnteraValidEmailAddress: t(
        "validatorMsg.PleaseEnteraValidEmailAddress"
      ),
    },
    PleaseEnteronlyNumber: t("validatorMsg.PleaseEnteronlyNumber"),
  };

  const navigate = useNavigate();
  const inputRef = useRef();

  const handleChangeFile = (e) => {

    setFileName([e.target.files[0].name]);
    const fileUploadData = e.target.files[0];
    setFileSize(parseFloat(fileUploadData.size / 1048576).toFixed(1));
    const postedData = {
      document: fileUploadData,
    };
    setDocumentFileObj({
      ...documentFileObj,
      document: fileUploadData,
    });

    setuserDataError({
      ...userDataError,
      attachmentErr: "",
    });

    resetFileStore();
  };

  const uploadFile = () => {
    if (documentFileObj.document != "") {
      careerFileUploadRequest(documentFileObj);

      setuserDataError({
        ...userDataError,
        attachmentErr: "",
      });


    }
  };
  const removeFile = useCallback(() => {
    setDocumentFileObj({ document: "" });
    setFileName([]);
    setFileSize("");
    resetFileStore();
  }, [documentFileObj, fileName, fileSize]);

  /*** Country Code Selection   ***/
  const handleCountryCodeChange = (value, data, event, formattedValue) => {
    if (data.dialCode == "") {
      setCountryCode("");
    } else {
      setCountryCode(formattedValue);

      setCountryCodeErr("");
    }
  };

  const handleChange = (e) => {
    if (e.target.name == "firstname") {
      setuserDataError({
        ...userDataError,
        firstnameErr: "",
      });
    } else if (e.target.name == "lastname") {
      setuserDataError({
        ...userDataError,
        lastnameErr: "",
      });
    } else if (e.target.name == "email") {
      setuserDataError({
        ...userDataError,
        emailErr: "",
      });
    } else if (e.target.name == "mobileNo") {
      const isNumberError = MobileNumberValidator(
        e.target.value,
        translatedMessageObj.MobileNumberValidatorMsgObj
      );
      if (isNumberError) {
        setuserDataError({
          ...userDataError,
          mobileNoErr: isNumberError,
        });
      } else {
        setuserDataError({
          ...userDataError,
          mobileNoErr: "",
        });
      }
    } else if (e.target.name == "subject") {
      setuserDataError({
        ...userDataError,
        subjectErr: "",
      });
    } else {
      setuserDataError({
        ...userDataError,
        messageErr: "",
      });
    }
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    const mobileNoError = MobileNumberValidator(
      userData.mobileNo,
      translatedMessageObj.MobileNumberValidatorMsgObj
    );
    e.preventDefault();
    if (userData.firstname == "") {
      setuserDataError({
        ...userDataError,
        firstnameErr: t("CareersPage.form.validationMsg.nameErrMsg"),
      });
      return;
    } else if (userData.lastname == "") {
      setuserDataError({
        ...userDataError,
        lastnameErr: t("CareersPage.form.validationMsg.lastnameErrMsg"),
      });
      return;
    } else if (!validator.isEmail(userData.email)) {
      setuserDataError({
        ...userDataError,
        emailErr: t("CareersPage.form.validationMsg.emailErrMsg"),
      });
      return;
    } else if (countryCode == "") {
      setCountryCodeErr(t("CareersPage.form.validationMsg.counrtycodeErrMsg"));
      return;
    } else if (mobileNoError) {
      setuserDataError({
        ...userDataError,
        mobileNoErr: mobileNoError,
      });
      return;

    } else if (userData.subject == "") {
      setuserDataError({
        ...userDataError,
        subjectErr: t("CareersPage.form.validationMsg.subjectErrMsg"),
      });
      return;
    } else if (userData.message == "") {
      setuserDataError({
        ...userDataError,
        messageErr: t("CareersPage.form.validationMsg.commentErrMsg"),
      });
      return;
    } else if (fileName == "") {
      setuserDataError({
        ...userDataError,
        attachmentErr: t("CareersPage.form.validationMsg.attachmentErrMsg"),
      });
      return;
    } else {
      let formData = new FormData();
      formData.append(userData.attachments, fileName);
      const attachmentsFileArr = [];
      if (fileDatafileLink && fileDatafileLink?.data.file_link) {
        attachmentsFileArr.push(fileDatafileLink?.data.file_link);
      }

      if (!attachmentsFileArr.length) {
        setuserDataError({
          ...userDataError,
          attachmentErr: t("CareersPage.form.validationMsg.attachmentErrMsg"),
        });
        return;
      }

      const data = {
        first_name: userData.firstname,
        last_name: userData.lastname,
        email: userData.email,
        country_code: countryCode,
        contact_number: userData.mobileNo,
        subject: userData.subject,
        message: userData.message,
        attachments: attachmentsFileArr,
      };

      addCareerRequest(data);
      setFileName([]);
      setFileSize("");
      setUserData({
        firstname: "",
        lastname: "",
        email: "",
        mobileNo: "",
        subject: "",
        message: "",
        attachments: [],
      });
      setFileDatafileLink("");
      resetFileStore();
    }
  };

  useEffect(() => {
    if (careerData != null) {
      if (careerData.success == true) {

        setShowSuccessDialog(true);
      } else {
      }

    }
  }, [JSON.stringify(careerData)]);

  React.useEffect(() => {
    if (fileData != null) {
      if (fileData.success == true) {
        setFileDatafileLink(fileData);

        setFileSize("");

      } else {
      }

    }
  }, [JSON.stringify(fileData)]);

  const handleCloseDialog = () => {
    setShowSuccessDialog(false);

    stateClearAfterTask();

  };
  useEffect(() => {
    if (careerData == null) {
      setShowSuccessDialog(false);
    }
  }, [careerData]);

  return (
    <>
      <Form onSubmit={handleSubmit} className={"careerForm"}>
        <div className="form50 form100">
          <Form.Group controlId="formCareerFirstname">
            <Form.Label>{t("CareersPage.form.FirstName")} *</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("CareersPage.form.FirstName")}
              onChange={handleChange}
              value={userData.firstname}
              name="firstname"
            />
            <Form.Text className="text-muted">
              {userDataError.firstnameErr}
            </Form.Text>
          </Form.Group>
          <Form.Group className="lastname" controlId="formCareerLastname">
            <Form.Label>{t("CareersPage.form.LastName")} *</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("CareersPage.form.LastName")}
              name="lastname"
              onChange={handleChange}
              value={userData.lastname}
            />
            <Form.Text className="text-muted">
              {userDataError.lastnameErr}
            </Form.Text>
          </Form.Group>
        </div>
        <Form.Group controlId="formCareerEmail">
          <Form.Label>{t("CareersPage.form.Email")} *</Form.Label>
          <Form.Control
            type="email"
            placeholder={t("CareersPage.form.Emailaddress")}
            name="email"
            value={userData.email}
            onChange={handleChange}
          />
          <Form.Text className="text-muted">{userDataError.emailErr}</Form.Text>
        </Form.Group>
        <div className="form50">
          <Form.Group controlId="formCareerPhone">
            <Form.Label>{t("CareersPage.form.MobileNumberText")} *</Form.Label>
            <Form.Group
              controlId="formCheckoutPhone"
              className="phoneWithCountryCode"
            >
              <Form.Group controlId="formCheckoutCountryCode">
                <PhoneInput
                  autoFormat={false}
                  excludeCountries={[
                    "cu",
                    "ru",
                    "kp",
                    "ua",
                    "ir",
                    "sd",
                    "ss",
                    "sy",
                  ]}

                  enableSearch={true}
                  value={countryCode}
                  onChange={handleCountryCodeChange}
                  name="countryCode"
                  placeholder={"+91"}
                  className="checkoutcountryCodeInput"
                />
              </Form.Group>
              <Form.Group
                controlId="formCheckoutPoneNumber"
                className="checkoutPhoneNumber"
              >
                <Form.Control
                  type="text"
                  placeholder={t("CareersPage.form.MobileNumber")}
                  name="mobileNo"
                  value={userData.mobileNo}
                  onChange={handleChange}
                  maxLength={10}
                />
              </Form.Group>
            </Form.Group>
            <Form.Text className="text-muted">
              {userDataError.mobileNoErr} {countryCodeErr}
            </Form.Text>

          </Form.Group>
          <Form.Group controlId="formCareerSubject">
            <Form.Label>{t("CareersPage.form.Subject")} *</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("CareersPage.form.Subject")}
              name="subject"
              value={userData.subject}
              onChange={handleChange}

            />







            <Form.Text className="text-muted">
              {userDataError.subjectErr}
            </Form.Text>
          </Form.Group>
        </div>

        <Form.Group controlId="formCareerMessage">
          <Form.Label>{t("CareersPage.form.Message")} *</Form.Label>
          <Form.Control
            as="textarea"
            placeholder={t("CareersPage.form.Yourmessage")}
            value={userData.message}
            name="message"
            onChange={handleChange}
          />
          <Form.Text className="text-muted">
            {userDataError.messageErr}
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="formCareerattachment" className="WL-fileuplaod">
          <Form.Label>{t("CareersPage.form.Attachment")}</Form.Label>
          <Form.Control
            type="file"
            aria-label="Attachment"
            accept=".pdf,.PDF,.jpg,.jpeg,.png"
            // accept="image/jpeg,image/jpg,image/gif,image/png,application/pdf,image/x-eps"
            onChange={handleChangeFile}
            ref={inputRef}
            key={fileName}
          />
          <Form.Text className="text-muted">
            {userDataError.attachmentErr}
          </Form.Text>
        </Form.Group>

        <>
          
          <div className="fileFormat text-center width-100">
            {t("CareersPage.FileSizeUpToandType")}
          </div>
          <p>
            {fileName} &nbsp;&nbsp; {fileSize == "" ? "" : `${fileSize} MB`}
          </p>
          {/* )} */}
          {fileName.length > 0 ? (
            <div className="d-flex gap-1 careerBtnBox">
              {fileName.length > 0 && (
                <Button className="gen-btn remove" onClick={removeFile}>
                  {t("CareersPage.form.RemoveFile")}
                </Button>
              )}
              <Button
                className="gen-btn upload"
                onClick={uploadFile}
                disabled={
                  fileName.length > 0 && fileData == null ? false : true
                }
              >
                {fileLoading ? (
                  <Spinner animation="border" variant="light" />
                ) : (
                  <>
                    {fileName.length > 0 && fileData != null && (
                      <>
                        {" "}
                        <BiUpload className="uploadIcon" />
                        {t("CareersPage.form.FileUploaded")}
                      </>
                    )}
                    {fileName.length > 0 && fileData == null && (
                      <>
                        {" "}
                        <BiUpload className="uploadIcon" />
                        {t("CareersPage.form.UploadFile")}
                      </>
                    )}
                  </>
                )}
              </Button>
            </div>
          ) : (
            ""
          )}
        </>

        <Button variant="" type="submit" className="formsubmit">
          {careerLoading ? (
            <Spinner animation="border" variant="light" />
          ) : (
            t("CareersPage.form.Send")
          )}
        </Button>
      </Form>

      <SuccessDialog
        setShowSuccessDialog={setShowSuccessDialog}
        isOpen={showSuccessDialog}
        caption={careerData != null ? careerData?.message : ""}
        description={<></>}
        cancelButtonLabel={t("CareersPage.form.Done")}
 
        clearState={stateClearAfterTask}
        cancelButtonHandler={handleCloseDialog}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  careerData: selectAddCareerData,
  userAuthData: selectUserLoginData,
  fileData: selectFileUploadData,
  fileLoading: selectFileLoading,
  careerLoading: selectCareerLoading,
});

const mapDispatchToProps = (dispatch) => ({
  addCareerRequest: (data) => dispatch(addCareerRequest(data)),
  stateClearAfterTask: () => dispatch(stateClearAfterTask()),
  careerFileUploadRequest: (data) => dispatch(careerFileUploadRequest(data)),
  resetFileStore: () => dispatch(resetFileStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CareerForm);
