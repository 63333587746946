import React from "react";
import { useLocation } from "react-router-dom";
import { selectlanguageToShow } from "./../../redux/language/language.selectors";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import CMSLeftMenu from "../../components/cms/CMSLeftMenu";
import OffersCard from "../../components/Offers/OffersCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getCouponListRequest } from "../../redux/offers/offer.actions";
import {
  selectCouponListData,
  selectCouponListLoading,
} from "../../redux/offers/offer.selectors";
import { useTranslation } from "react-i18next";
import PaginationWithDisplay from "../../components/paginationWithDisplay/paginationWithDisplay";
import { Helmet } from 'react-helmet';

const OffersPage = ({
  languageToShow,
  getCouponListRequest,
  couponListData,
  couponListLoading,
}) => {
  const location = useLocation();
  const [isCopied, setIsCopied] = React.useState(false);
  const [shareCode, setShareCode] = React.useState("");
  const [selectedIndex, setSelectedIndex] = React.useState();
  const [t] = useTranslation("common");
  const [page, setPage] = React.useState({
    page_no: 1,
    per_page_limit: 10,
    total: 0,
  });

  React.useEffect(() => {
    var data = {
      language: languageToShow,
      skip: 1,
      limit: page.per_page_limit,
    };
    getCouponListRequest(data);
  }, [languageToShow]);

  React.useEffect(() => {
    if (couponListData != null) {
      setPage({
        ...page,
        total: couponListData.total_count,
      });
    }
  }, [couponListData]);

  const handleChangePage = (event, page, limit) => {
    // setPage(newPage);
    setPage({
      ...page,
      page_no: page,
      per_page_limit: limit,
    });

    let data = {
      languageToShow: languageToShow,
      skip: page,
      limit: limit,
    };
    getCouponListRequest(data);
  };

  var offers = {
    autoplay: false,
    autoplaySpeed: 2000,
    arrows: true,
    dots: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const onCopy = React.useCallback((value, cardIndex) => {
    setIsCopied(value);
    setSelectedIndex(cardIndex);
  }, []);

  const couponCodes = [
    "HOLISPECIAL",
    "BIRTHDAY",
    "SUMMERSPECIAL",
    "SPRINGPECIAL",
  ];

  return (
    <>
      {/* //////////Common page Section/////////// */}

      { /* For SEO */}
      <Helmet>
        <title>Amazing Offers and Discounts | wfrlee.com: Your Path to Savings and Enjoyment</title>
        <meta name="description" content="Explore exclusive offers and discounts on wfrlee.com. Don't miss out on savings. Discover deals for a great experience!" />
      </Helmet>

      <div className="commonpage">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <CMSLeftMenu location={location} />
            </div>
            <div className="col-md-9">
              <div className="offers-rightsection">
                <h1>{t("OffersPage.OffersHeading")}</h1>


                <div className="offers_slider">
                  {couponListData != null &&
                    couponListData?.data?.length > 0 ? (
                    couponListData?.data.map((data, index) => {
                      return (
                        <OffersCard
                          shareCode={data.code}
                          setShareCode={setShareCode}
                          onCopy={onCopy}
                          isCopied={selectedIndex == index ? isCopied : false}
                          setIsCopied={setIsCopied}
                          key={index}
                          cardIndex={index}
                          couponData={data}
                        />
                      );
                    })
                  ) : (
                    <div className="inner_mbox_offercard">
                      <div className="inner_offercardbox">
                        <div
                          className="inner_offerbox_image_text"
                          style={{ cursor: "pointer" }}
                        >
                          <h4>{t("OffersPage.NoData")}</h4>
                        </div>
                      </div>
                    </div>
                  )}

                </div>


                <PaginationWithDisplay
                  page={page}
                  handleChangePage={handleChangePage}

                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* //////////Common page Section/////////// */}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  languageToShow: selectlanguageToShow,
  couponListData: selectCouponListData,
  couponListLoading: selectCouponListLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getCouponListRequest: (data) => dispatch(getCouponListRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OffersPage);
