import  LanaguageActionTypes  from './languae.types';
const INITIAL_STATE = {
language:"en"
};

const lanaguaeReducer = ( state = INITIAL_STATE , action ) =>  {
switch(action.type) {
case LanaguageActionTypes.SELECT_DEFAULT_LANGUAGE: 
return {
 ...state, 
 language:action.payload
};
default: 
return state;
} 


}

export default lanaguaeReducer;