import { all, call, fork } from "redux-saga/effects";
import { userSagas } from "./user/user.saga";
import { cmsSagas } from "./cms/cms.saga";
import { useraccountSagas } from "./useraccount/useraccount.saga";
import { hotelSagas } from "./hotels/hotel.saga";
import { fileUploadSagas } from "./common/fileUpload.saga";
import { faqSagas } from './faq/faq.saga';
import { homeSagas } from "./home/home.saga";
import { offerSagas } from "./offers/offer.saga";



// import { watchLoginSaga } from "./sagas/login.saga";

export default function* rootSaga() {
  yield all([
    call(userSagas),
    call(cmsSagas),
    call(useraccountSagas),
    call(hotelSagas),
    call(fileUploadSagas),
    call(faqSagas),
    call(homeSagas),
    call(offerSagas),
  ]);
}
