import FAQActionTypes from './faq.types';

const INITIAL_STATE = {
faqData:null,
faqError:null,
loading:false
};

const faqReducer = ( state = INITIAL_STATE , action ) =>  {
switch(action.type) {
case FAQActionTypes.FAQ_GET_DATA_LOAD: 
return {
 ...state, 
 loading:true
};
case FAQActionTypes.FAQ_GET_DATA_SUCCESS: 
return {
...state,   
faqData:action.payload
}
case FAQActionTypes.FAQ_GET_DATA_FAILURE:
return {
...state,   
faqError:action.payload   
}
default: 
return state;
} 


}

export default faqReducer;