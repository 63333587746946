import React, { Suspense, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form } from "react-bootstrap";
import "./BidNowForms.css";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { BsPlusCircle } from "react-icons/bs";
import moment from "moment";

const AutocompleteReact = React.lazy(
  () => import("../rectInput-auto-complete/reactInput"),
);

const BidNowMobileForm = ({
  checkInOutValue,
  setCheckInOutValue,
  handleOpen,
  setIsOpen,
  handleIncrease,
  handleDecrease,
  guestRoomData,
  guestRomsPopup,
  guestAndRoom,
}) => {
  const ref = useRef(null);
  const [t] = useTranslation("common");

  return (
    <div className="bid_now__mobile_container">
      <div className="mobile__reservationForm__input_container">
        <label htmlFor="where" className="mobile__reservationForm__input_label">
          {t("home.search.WHERE")}
        </label>
        <Suspense fallback={<div>{t("home.search.Loading")}</div>}>
          <AutocompleteReact />
        </Suspense>
      </div>
      <div className="d-flex gap-2">
        <div className="mobile__reservationForm__input_container">
          <label
            htmlFor="where"
            className="mobile__reservationForm__input_label"
          >
            {t("home.search.CheckinLabel")}
          </label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              className="mobile__reservationForm__date__picker"
              value={checkInOutValue.checkInTime}
              onChange={(newValue) => {
                // setValue();
                moment(new Date(newValue)).format("YYYY-MM-DD HH:mm:ss");
                setCheckInOutValue((checkInOutValue) => ({
                  ...checkInOutValue,
                  checkInTime: newValue,
                }));
              }}
              disablePast
              renderInput={(params) => <TextField {...params} />}
              inputFormat="DD MMM YYYY"
              disableMaskedInput={true}
            />
          </LocalizationProvider>
        </div>
        <div className="mobile__reservationForm__input_container">
          <label
            htmlFor="where"
            className="mobile__reservationForm__input_label"
          >
            {t("home.search.CheckoutLabel")}
          </label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              className="mobile__reservationForm__date__picker"
              value={checkInOutValue.checkOutTime}
              onChange={(newValue) => {
                //setValue(moment(newValue).format("DD/MM/YYYY"));
                setCheckInOutValue((checkInOutValue) => ({
                  ...checkInOutValue,
                  checkOutTime: newValue,
                }));
              }}
              renderInput={(params) => <TextField {...params} />}
              disablePast
              minDate={new Date(checkInOutValue.checkInTime).setDate(
                new Date(checkInOutValue.checkInTime).getDate() + 1,
              )}
              inputFormat="DD MMM YYYY"
              disableMaskedInput={true}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div className="mobile__reservationForm__input_container">
        <label htmlFor="where" className="mobile__reservationForm__input_label">
          {t("home.search.RoomGuest")}
        </label>
        <input
          type="text"
          className="mobile__reservationForm__guests_input"
          value={`${guestRoomData.adults + guestRoomData.child} ${
            guestRoomData.adults + guestRoomData.child > 1
              ? `${t("home.search.Guests")}`
              : `${t("home.search.Guest")}`
          }  / ${guestRoomData.rooms} ${
            guestRoomData.rooms > 1
              ? `${t("home.search.Rooms")}`
              : `${t("home.search.Room")}`
          }`}
          onClick={guestAndRoom}
        />
        <div
          className="guestpop"
          ref={ref}
          style={guestRomsPopup ? { display: "block" } : { display: "none" }}
        >
          <h3>
            {/* Add Guest(s) And Room(s) */}
            {t("home.search.AddGuest(s)andRoom(s)")}
          </h3>
          <ul>
            <li key={24}>
              <h4>
                {/* Room (s) */}
                {t("home.search.Room(s)")}
              </h4>
              <div className="counting">
                <Button
                  type="button"
                  onClick={() => {
                    handleDecrease("room");
                  }}
                >
                  <AiOutlineMinusCircle />
                </Button>
                {guestRoomData.rooms}
                <Button
                  type="button"
                  onClick={() => {
                    handleIncrease("room");
                  }}
                >
                  <BsPlusCircle />
                </Button>
              </div>
            </li>
            <li key={25}>
              <h4>
                {/* Adult (s) */}
                {t("home.search.Adult(s)")}
              </h4>
              <div className="counting">
                <Button
                  onClick={() => {
                    handleDecrease("adult");
                  }}
                >
                  <AiOutlineMinusCircle />
                </Button>
                {guestRoomData.adults}
                <Button
                  onClick={() => {
                    handleIncrease("adult");
                  }}
                >
                  <BsPlusCircle />
                </Button>
              </div>
            </li>
            <li key={26}>
              <h4>
                {/* Children (s)  */}
                {t("home.search.Children(s)")}
                <span>
                  {/* Max 11 years */}
                  {t("home.search.MaxYears11")}
                </span>
              </h4>
              <div className="counting">
                <Button
                  onClick={() => {
                    handleDecrease("children");
                  }}
                >
                  <AiOutlineMinusCircle />
                </Button>
                {guestRoomData.child}
                <Button
                  onClick={() => {
                    handleIncrease("children");
                  }}
                >
                  <BsPlusCircle />
                </Button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BidNowMobileForm;
