import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "axios";
import  store from "./../../redux/store";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const SpecialSection = () => {
  const [t] = useTranslation("common");
  const [email,setEmail] = useState("");
  const [emailSbmError,setEmailSbmError] = useState("");
  const MySwal = withReactContent(Swal);
  const handleSubmit = (e) => {
    e.preventDefault();
  if(email == "") {
    setEmailSbmError(t("home.LetsExploreTheWorld.emailValidationErr.emailrequired"));
  } else {
  let regex = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");  
  if(!regex.test(email)){
  setEmailSbmError(t("home.LetsExploreTheWorld.emailValidationErr.validEmail"));
  } else {
  setEmailSbmError(""); 
  const backendUrlEndPoint = process.env.REACT_APP_BACKEND_API_ENDPOINT;
  axios.post(`${backendUrlEndPoint}/newsletter/add/`, {
   email:email,
  }, {  headers: {
    "Access-Control-Allow-Origin": "*",
    mobile: true,
    "Accept-Language": `${store.getState().language.language}`,
  }})
  .then((response) => {
    if(response.data.success) {
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
        text:response.data.message
      });  

    } else {
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
        text:response.data.message
      });  
    }
    setEmail("");

  });
  }
  }
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="getspecial">
              <h3>
                <span>
                  {/* Let’s Explore the World */}
                  {t("home.LetsExploreTheWorld.heading")}
                </span>
                {/* Get Special Offers in Your Inbox */}
                {t("home.LetsExploreTheWorld.text")}
              </h3>
              <Form onSubmit={handleSubmit}>
                <Form.Group
                  className="getspecial-form"
                >
                  <Form.Control
                  type="email"
                  placeholder={t("home.LetsExploreTheWorld.inputPlaceholder")}
                  onChange={(e) => { setEmail(e.target.value);setEmailSbmError("") }} 
                  name="email"
                  value={email}
                  />
                 
                  <Button type="submit" value="submit" className="planeicon">
                    <img src="./img/plane.svg" alt="" />
                  </Button>
                </Form.Group>
                <div style={{paddingLeft:"31px"}}><p style={{color:"red"}} >{emailSbmError}</p></div>
                
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpecialSection;
