import React from "react";
import { Link, useLocation } from "react-router-dom";
import CareerForm from "../../components/CareerForm/CareerForm";
import CMSLeftMenu from "../../components/cms/CMSLeftMenu";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const CareerPage = () => {
  const [t] = useTranslation("common");
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* for SEO purpose */}
      <Helmet>
        <title>Join Wfrlee: Explore Career Opportunities Now!</title>
        <meta name="description" content="Join Wfrlee and discover exciting career opportunities in a dynamic team environment. Start your future with us today!" />
      </Helmet>

      {/* //////////Common page Section/////////// */}
      <div className="commonpage">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <CMSLeftMenu location={location} />
            </div>
            <div className="col-md-9">
              <div className="WL_contactmwrp customform rightFormWidth">
                <h1>{t("CareersPage.careersHeading")}</h1>
                <p>
                  {t("CareersPage.FormHeadingCarrier")}</p>
                <CareerForm />

                {/* //////////call text Section start /////////// */}
                <div className="WL_contacttextwrp">
                  <div className="WL_innercontacttext">
                    <p className="WL_innercontacttextinner">
                      {t("CareersPage.CallUsat")}
                      <span>
                        <Link to="tel:+971 589-706-050">+971 589-706-050</Link>
                      </span>
                    </p>
                  </div>

                  <div className="WL_innercontacttext">
                    <p className="WL_innercontacttextinner">
                      {t("CareersPage.TextUsat")}
                      <span>
                        <a href="mailto:info@wfrlee.com">
                          {/* info@wfrlee.com */}
                          {t("CareersPage.companyMail")}
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
                {/* //////////call text Section end /////////// */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* //////////Common page Section/////////// */}
    </>
  );
};

export default CareerPage;
