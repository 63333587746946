import FAQActionTypes from './faq.types';

export const faqGetDataLoad = faq => ({
type:FAQActionTypes.FAQ_GET_DATA_LOAD,
payload:faq
}); 
    
export const faqGetDataSuccess = (data) => ({
type:FAQActionTypes.FAQ_GET_DATA_SUCCESS,
payload:data
});
    
export const faqGetDataFailure = error => ({
type:FAQActionTypes.FAQ_GET_DATA_FAILURE,
payload:error
});



