import React from "react";
import ResetPasswordAfterForgot from "./../../components/reset-password-forgot/reset-password-forgot"
import { createStructuredSelector } from "reselect";
import { selectSocialLoginUser } from "./../../redux/user/user.selectors";
import { connect } from "react-redux";


const ForgotPasswordPage = () => {

  return (
    <>
      {/* Header Section sssss*/}
      {/* //////////loginSection/////////// */}
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="logtotal customform">

              <ResetPasswordAfterForgot />
              <p className="text-center">

              </p>



            </div>
          </div>
        </div>
      </div>

      {/* //////////Footer Section/////////// */}
      {/* <Footer /> */}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  userData: selectSocialLoginUser,
});

export default connect(mapStateToProps)(ForgotPasswordPage);
