//User
export const setUserLocal = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
};


export const getUserFromLocalStorage = () => {
  const user = localStorage.getItem('user');
  if (user !== undefined) {
    return JSON.parse(String(user));
  } else return null;
};

// token
export const setTokenLocal = (token) => {
  localStorage.setItem('token', token);
};

export const getTokenFromLocalStorage = () => {
  const token = localStorage.getItem('token');
  if (token !== undefined) {
    return JSON.parse(String(token));
  } else return null;
};
