import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { forwardRef, Suspense, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { Box, Typography } from "@mui/material";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { BsPlusCircle } from "react-icons/bs";
import HourlyStayMobileForm from "./HourlyStayMobileForm";
const AutocompleteReact = React.lazy(() =>
  import("./../../components/rectInput-auto-complete/reactInput")
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

/**
 * Sets an item in the sessionStorage with an expiration time.
 *
 * @param {string} key - The key to store the item under.
 * @param {any} value - The value to store.
 * @param {number} ttl - The time to live in milliseconds.
 * @return {void} This function does not return a value.
 */
const setItemWithExpiry = (key, value, ttl) => {
  const now = new Date();

  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  sessionStorage.setItem(key, JSON.stringify(item));
};

const getItemWithExpiry = (key) => {
  const itemStr = sessionStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    sessionStorage.removeItem(key);
    return null;
  }
  return item.value;
};

const HourlyStayForm = forwardRef(
  (
    {
      longaddress,
      setIsOpen,
      isOpen,
      checkInOutValue,
      setCheckInOutValue,
      handleOpen,
      open,
      handleHourscollect,
      getHours,
      guestRoomData,
      guestAndRoom,
      guestRomsPopup,
      handleDecrease,
      handleIncrease,
      closeGuestPopup,
    },
    ref3
  ) => {
    const ref = useRef(null);
    const [t] = useTranslation("common");

    if (window.innerWidth <= 768)
      return (
        <HourlyStayMobileForm
          checkInOutValue={checkInOutValue}
          setCheckInOutValue={setCheckInOutValue}
          setIsOpen={setIsOpen}
          handleOpen={handleOpen}
          guestRoomData={guestRoomData}
          guestAndRoom={guestAndRoom}
          handleDecrease={handleDecrease}
          handleIncrease={handleIncrease}
          guestRomsPopup={guestRomsPopup}
          closeGuestPopup={closeGuestPopup}
          getHours={getHours}
          handleHourscollect={handleHourscollect}
          open={open}
        />
      );

    return (
      <div
        className="left"
        style={{
          ...(window.innerWidth <= 768 && {
            backgroundColor: "#f1f1f1",
            borderRadius: "0px 25px 25px 25px",
          }),
        }}
      >
        <Form.Label className={`${longaddress === "" ? "add-width" : ""}`}>
          {longaddress === "" ? `${t("home.search.WHERE")}` : null}
        </Form.Label>
        <Form.Group className="form-box location-drop">
          <div>
            <Suspense fallback={<div>{t("home.search.Loading")}</div>}>
              <AutocompleteReact
                focusCheckinDate={() => {
                  if (!getItemWithExpiry("@guide_checkin")) {
                    setIsOpen(true);
                    setItemWithExpiry("@guide_checkin", true, 3600000);
                  }
                }}
              />
            </Suspense>
          </div>
        </Form.Group>
        <Form.Group className="form-box checkin">
          {!isMobile ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="datepickercheck-in"
                style={{ cursor: "pointer" }}
                label={`${t("home.search.CheckinLabel")}`}
                value={checkInOutValue.checkInTime}
                onChange={(newValue) => {
                  setCheckInOutValue((checkInOutValue) => ({
                    ...checkInOutValue,
                    checkInTime: newValue,
                  }));
                }}
                disablePast
                renderInput={(params) => <TextField {...params} />}
                onClose={() => setIsOpen(false)}
                KeyboardButtonProps={{
                  onClick: (e) => {
                    setIsOpen(true);
                  },
                }}
                InputProps={{
                  onClick: () => {
                    setIsOpen(true);
                  },
                }}
                open={isOpen}
                inputFormat="DD MMM YYYY"
                disableMaskedInput={true}
              />
            </LocalizationProvider>
          ) : (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                label={`${t("home.search.CheckinLabel")}`}
                value={checkInOutValue.checkInTime}
                onChange={(newValue) => {
                  setCheckInOutValue((checkInOutValue) => ({
                    ...checkInOutValue,
                    checkInTime: newValue,
                  }));
                }}
                onClose={() => {
                  setIsOpen(false);
                  handleOpen();
                }}
                disablePast
                renderInput={(params) => <TextField {...params} />}
                inputFormat="DD MMM YYYY"
                disableMaskedInput={true}
              />
            </LocalizationProvider>
          )}
          {!isMobile ? (
            <div
              className="pophours"
              ref={ref3}
              style={open ? { display: "block" } : { display: "none" }}
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2 }}
                  className="hourlybook"
                  onChange={handleHourscollect}
                >
                  {t("home.search.BookFor")}
                  <Form.Select aria-label="Default select example">
                    <option>
                      {/* Hours */}
                      {t("home.search.Hours")}
                    </option>

                    <option value="3">{t("home.search.Hour3")}</option>
                    <option value="6">{t("home.search.Hours6")}</option>
                    <option value="12">{t("home.search.Hours12")}</option>
                  </Form.Select>
                </Typography>
              </Box>
            </div>
          ) : (
            <div
              className="pophours"
              style={open ? { display: "block" } : { display: "none" }}
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2 }}
                  className="hourlybook"
                  onChange={handleHourscollect}
                >
                  {t("home.search.BookFor")}
                  <Form.Select aria-label="Default select example">
                    <option>
                      {/* Hours */}
                      {t("home.search.Hours")}
                    </option>

                    <option value="3">{t("home.search.Hour3")}</option>
                    <option value="6">{t("home.search.Hours6")}</option>
                    <option value="12">{t("home.search.Hours12")}</option>
                  </Form.Select>
                </Typography>
              </Box>
            </div>
          )}

          {getHours !== null && getHours > 0 ? (
            <span className="hourlable">
              {getHours === "3"
                ? t("home.search.Hour3")
                : getHours === "6"
                ? t("home.search.Hours6")
                : t("home.search.Hours12")}
              {/* {getHours} Hours */}
            </span>
          ) : null}
        </Form.Group>
        <Form.Group className="form-box pop-form">
          <Form.Control
            type="text"
            className="room"
            placeholder={`${guestRoomData.adults + guestRoomData.child} ${
              guestRoomData.adults + guestRoomData.child > 1
                ? `${t("home.search.Guests")}`
                : `${t("home.search.Guest")}`
            }  / ${guestRoomData.rooms} ${
              guestRoomData.rooms > 1
                ? `${t("home.search.Rooms")}`
                : `${t("home.search.Room")}`
            }`}
            onClick={guestAndRoom}
          />
          <div
            className="guestpop"
            ref={ref}
            style={guestRomsPopup ? { display: "block" } : { display: "none" }}
          >
            <h3>
              {/* Add Guest(s) And Room(s) */}
              {t("home.search.AddGuest(s)andRoom(s)")}
            </h3>
            <ul>
              <li key={24}>
                <h4>
                  {/* Room (s) */}
                  {t("home.search.Room(s)")}
                </h4>
                <div className="counting">
                  <Button
                    type="button"
                    onClick={() => {
                      handleDecrease("room");
                    }}
                  >
                    <AiOutlineMinusCircle />
                  </Button>
                  {guestRoomData.rooms}
                  <Button
                    type="button"
                    onClick={() => {
                      handleIncrease("room");
                    }}
                  >
                    <BsPlusCircle />
                  </Button>
                </div>
              </li>
              <li key={25}>
                <h4>
                  {/* Adult (s) */}
                  {t("home.search.Adult(s)")}
                </h4>
                <div className="counting">
                  <Button
                    onClick={() => {
                      handleDecrease("adult");
                    }}
                  >
                    <AiOutlineMinusCircle />
                  </Button>
                  {guestRoomData.adults}
                  <Button
                    onClick={() => {
                      handleIncrease("adult");
                    }}
                  >
                    <BsPlusCircle />
                  </Button>
                </div>
              </li>
              <li key={26}>
                <h4>
                  {/* Children (s)  */}
                  {t("home.search.Children(s)")}
                  <span>
                    {/* Max 11 years */}
                    {t("home.search.MaxYears11")}
                  </span>
                </h4>
                <div className="counting">
                  <Button
                    onClick={() => {
                      handleDecrease("children");
                    }}
                  >
                    <AiOutlineMinusCircle />
                  </Button>
                  {guestRoomData.child}
                  <Button
                    onClick={() => {
                      handleIncrease("children");
                    }}
                  >
                    <BsPlusCircle />
                  </Button>
                </div>
              </li>
            </ul>
          </div>
        </Form.Group>
      </div>
    );
  }
);

export default HourlyStayForm;
