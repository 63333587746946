import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/DubaiRegular/Dubai-Regular.ttf";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import { persistor } from "./redux/store";
import AOS from "aos";
import "aos/dist/aos.css";
import common_ar from "./translations/ar/arabic.lang.json";
import common_en from "./translations/en/english.lang.json";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { BrowserRouter as Router, Routes } from "react-router-dom";
import NotFoundScreen from "./pages/NotFoundScreen";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { GoogleOAuthProvider } from "@react-oauth/google";
import SmartBanner from "react-smartbanner";
import "react-smartbanner/dist/main.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "en", // language to use
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    ar: {
      common: common_ar,
    },
  },
});
root.render(
  // <React.StrictMode>
  <Router>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENTID}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ToastContainer />
          <I18nextProvider i18n={i18next}>
            <SmartBanner title={"Wfrlee.com"} />
            <App />
          </I18nextProvider>
        </PersistGate>
      </Provider>
    </GoogleOAuthProvider>
  </Router>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
