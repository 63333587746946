import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReactComponent as FavouriteIcon } from "../../assets/images/icon-list-favourite.svg";
import {
  Link,
  useLocation,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import {
  getAllFavouriteHotelListRequest,
  removeFavouriteHotelRequest,
} from "../../redux/hotels/hotel.actions";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  selectFavouriteHotelList,
  selectFavouriteHotel,
} from "../../redux/hotels/hotel.selectors";
import { selectUserLoginData } from "../../redux/user/user.selectors";
import { selectlanguageToShow } from "./../../redux/language/language.selectors";
import moment from "moment";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MyFavouriteHotelList = ({
  myFavouriteHotelList,
  favouriteHotel,
  getAllFavouriteHotelListRequest,
  removeFavouriteHotelRequest,
  languageToShow,
  userAuthData,
  favouritesListRequestData,
}) => {
  const [t] = useTranslation("common");
  const location = useLocation();
  const navigate = useNavigate();
  const [isAddedFavourite, setIsAddedFavourite] = React.useState();
  const MySwal = withReactContent(Swal);
  var collecSlider = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const gotoHotelDetails = (slug, cityname) => {
    var checkinDate = new Date();
    checkinDate.setDate(checkinDate.getDate() + 1);
    var checkoutDate = new Date();
    checkoutDate.setDate(checkoutDate.getDate() + 2);

    let dateObj = new Date();
    let month = dateObj.getUTCMonth() + 1; //months from 1-12
    let day = dateObj.getUTCDate();
    let year = dateObj.getUTCFullYear();
    if (location.pathname == `/hotel-details/${slug}`) {
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          search_type: "bid",
          city: cityname,
          check_in_date: moment(new Date(checkinDate)).format("DD/MM/YYYY"),
          check_out_date: moment(new Date(checkoutDate)).format("DD/MM/YYYY"),
          adults: "1",
          children: "0",
          rooms: "1",
        }).toString(),
      });
    } else {
      navigate(
        {
          pathname: `/hotel-details/${slug}`,
          search: createSearchParams({
            search_type: "bid",
            city: cityname,
            check_in_date: moment(new Date(checkinDate)).format("DD/MM/YYYY"),
            check_out_date: moment(new Date(checkoutDate)).format("DD/MM/YYYY"),
            adults: "1",
            children: "0",
            rooms: "1",
          }).toString(),
        },
        { replace: false }
      );
    }
  };

  const handleFavourite = (hotel_id) => {
    const data = {
      languageToShow: languageToShow,
      token: userAuthData != null ? userAuthData.token : "",
    };
    const postData = {
      hotel_id: hotel_id,
    };

    const hotelFavouritesParam = {
      postData,
      languageToShow: languageToShow,
      token: userAuthData != null ? userAuthData.token : "",
    };

    if (userAuthData != null) {
      if (hotel_id) {
        removeFavouriteHotelRequest(hotelFavouritesParam);
        setIsAddedFavourite(!isAddedFavourite);
        
      }
    } else {
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
        text: t("MyAccount.MyFavouritesPage.Pleaseloginfirst")
      });
     
    }
  };

  React.useEffect(() => {
    if (favouriteHotel != null) {
      if (favouriteHotel.success == true) {
        getAllFavouriteHotelListRequest(favouritesListRequestData);
      } else {
      }
    }
  }, [favouriteHotel]);

  return (
    <div className="WL_right_rivewbox">
      {/* ///left section start/// */}
      <div className="WL_collection_left WL_collection_myfavlist_left">
        <div className="WL_review_mwrp">
          {/* ///slider start/// */}
          <div className="WL_coll_slider myFavColl_Slider">
            <Slider {...collecSlider}>
              {myFavouriteHotelList.images.length > 0 ? (
                myFavouriteHotelList.images.map((image, index) => (
                  <img
                    src={image}
                    alt=""
                    className="WL_coll_slider_img"
                    key={index}
                  />
                ))
              ) : (
                <img
                  src="./img/collec_img1.png"
                  alt=""
                  className="WL_coll_slider_img"
                />
              )}
            </Slider>
            <span className="favourite myfavlist_heartIcon">
              {/* <img src="./img/favwhite.svg" alt="" /> */}
              <Button
                className={
                  isAddedFavourite == true
                    ? "activeFavourite onClickBtn"
                    : "activeFavourite onClickBtn"
                }
                onClick={() => handleFavourite(myFavouriteHotelList?.hotel_id)}
              >
                <FavouriteIcon />
              </Button>
            </span>
          </div>
          {/* ///slider end/// */}
          {/* ///review text container Start/// */}
          <div className="WL_inner_review_cont">
            {/* ///review text Start/// */}
            <div className="WL_reviewtext_cont favreviewtext_cont myFavTextmiddle">
              <h3
                onClick={() => {
                  gotoHotelDetails(
                    myFavouriteHotelList.slug,
                    myFavouriteHotelList.address.city_village
                  );
                }}
              >
                {myFavouriteHotelList.name}
              </h3>
              <div className="address">
                <div className="icon" style={{width:'50px'}} >
                  <img src="./img/icon-address.svg" alt="" />
                </div>
                
                <p>
                  <span>{myFavouriteHotelList.address.address_line1}</span>,{" "}
                  {myFavouriteHotelList.address.address_line2 && (
                    <span>{myFavouriteHotelList.address.address_line2}, </span>
                  )}{" "}
                  {myFavouriteHotelList.address.city_village && (
                    <span>{myFavouriteHotelList.address.city_village} -</span>
                  )}
                  {myFavouriteHotelList.address.pincode}
                  
                  <br />
                  {myFavouriteHotelList.address.country}
                </p>
              </div>
            </div>
            {/* ///review text end/// */}
          </div>
          {/* ///review text container end/// */}
        </div>
      </div>
      {/* ///left section end/// */}
      {/* ///right section start/// */}
      <div className="WL_collection_right favright myFavRight">
        <div className="reviewstar">
          {[...Array(myFavouriteHotelList.star)].map((e, i) => (
            <img src="./img/star.svg" key={i} alt="" />
          ))}
        </div>
        <div className="favguest d-flex">
          <div className="WL_revw_left">
            <p className="WL_revw_text1">
              {myFavouriteHotelList?.review?.feedback_type}
            </p>
            <p className="WL_revw_text2">
              {myFavouriteHotelList?.review?.guests} {t("MyAccount.MyFavouritesPage.GuestReviews")}
            </p>
          </div>
          <div className="WL_revw_right">
            {Number(myFavouriteHotelList?.review?.rating).toFixed(1)}
          </div>
        </div>
      </div>
      {/* ///right section end/// */}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  languageToShow: selectlanguageToShow,
  selectFavouriteHotelList: selectFavouriteHotelList,
  favouriteHotel: selectFavouriteHotel,
  userAuthData: selectUserLoginData,
});
const mapDispatchToProps = (dispatch) => ({
  getAllFavouriteHotelListRequest: (data) =>
    dispatch(getAllFavouriteHotelListRequest(data)),
  removeFavouriteHotelRequest: (data) =>
    dispatch(removeFavouriteHotelRequest(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyFavouriteHotelList);
