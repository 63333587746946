import OfferActionTypes from "./offer.types";

/**** COUPON LIST Reducer */
export const getCouponListRequest = (couponListData) => ({
  type: OfferActionTypes.GET_COUPON_LIST_REQUEST,
  payload: couponListData,
});

export const getCouponListSuccess = (couponListData) => ({
  type: OfferActionTypes.GET_COUPON_LIST_SUCCESS,
  payload: couponListData,
});

export const getCouponListFailure = (error) => ({
  type: OfferActionTypes.GET_COUPON_LIST_FAILED,
  payload: error,
});

/**** END COUPON LIST Reducer */
