import UserActionTypes from "./user.types";

// const initialState = {
//   isLoggedIn: false,
//   user: {
//     first_name: "",
//     last_name: "",
//     email: "",
//     social_platform: "",
//     social_login_id: null,
//     social_login_token: null,
//   },
//   userData: null,
//   errors: {},
//   loading: false,
// };
const INITIAL_STATE = {
  currentUser: null,
  signuploading: false,
  signinLoading: false,
  error: null,
  signUpError: null,
  authLoading: false,
  forgotpasswordLoading: false,
  forgotPasswordSuccessMsg:false,
  signUpMessage: null,
  emailVerifacation: null,
  forgotResetPasswordMsg: null,
  isLoggedIn: false,
  imageUploadMsg:null,
  user: {
    first_name: "",
    last_name: "",
    email: "",
    social_platform: "",
    social_login_id: null,
    social_login_token: null,
  },
  userData: null,
  loading: false,
  isProfileUpdateLoading: false,
  userProfileDataUpdateMsg: null,
  saveGuestUserCheckoutData: null,
  resetPasswordData: null,
  validateUserEmail: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SOCIAL_LOGIN_REQUEST:
      return {
        ...state,
        isLoggedIn: false,
        user: action.payload.user,
        error: null,
        loading: false,
      };
    case UserActionTypes.SOCIAL_LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        error: null,
        userData: action.payload,
        currentUser: action.payload,
      };
    case UserActionTypes.SOCIAL_LOGIN_FAILED:
      return {
        ...state,
        isLoggedIn: false,
        error: action.payload.errors,
      };
    case UserActionTypes.EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        emailVerifacation: action.payload,
      };
    case UserActionTypes.EMAIL_VERIFICATION_FAILURE:
      return {
        ...state,
        emailVerifacation: action.payload,
      };
    case UserActionTypes.EMAIL_SIGN_IN_START:
      return {
        ...state,
        signinLoading: true,
      };
    case UserActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        signinLoading: false,
        error: null,
        userData: action.payload,
      };
    case UserActionTypes.SIGN_OUT_SUCCESS:
      return {
        ...state,
        currentUser: null,
        error: null,
        userData: null,
      };
    case UserActionTypes.SIGN_IN_FAILURE:
    case UserActionTypes.SIGN_OUT_FAILURE:
      return {
        ...state,
        error: action.payload,
        signinLoading: false,
      };
    case UserActionTypes.SIGN_UP_START:
      return {
        signuploading: true,
      };
    case UserActionTypes.SIGN_UP_SUCCESS:
      return {
        ...state,
        signuploading: false,
        signUpMessage: action.payload,
        currentUser: action.payload.data,
      };
    case UserActionTypes.SIGN_UP_FAILURE:
      return {
        ...state,
        signuploading: false,
        signUpError: action.payload,
      };
    case UserActionTypes.USER_LOGOUT_START:
      return {
        ...state,
        authLoading: true,
      };
    case UserActionTypes.USER_LOGOUT_SUCCESS:
      return {
        ...state,
        authLoading: false,
        currentUser: null,
        userData: null,
      };
    case UserActionTypes.USER_LOGOUT_FAILURE:
      return {
        ...state,
        authLoading: false,
        error: action.payload,
      };
    case UserActionTypes.FORGOT_PASSWORD_RESET:
        return {
          ...state,
          forgotPasswordSuccessMsg:false
    };
    case UserActionTypes.FORGOT_PASSWORD_START:
      return {
        ...state,
        forgotpasswordLoading: true,
        forgotPasswordSuccessMsg:false
      };
    case UserActionTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotpasswordLoading: false,
        forgotPasswordSuccessMsg:true
      };
    case UserActionTypes.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotpasswordLoading: false,
        error: action.payload,
      };
    case UserActionTypes.RESET_PASSWORD_START:
      return {
        ...state,
        authLoading: true,
      };
    case UserActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        authLoading: false,
        resetPasswordData: action.payload,
      };
    case UserActionTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        authLoading: false,
        error: action.payload,
        resetPasswordData: action.payload,
      };
    case UserActionTypes.RESET_PASSWORD_AFTER_FORGOT_START:
      return {
        ...state,
        authLoading: true,
      };
    case UserActionTypes.RESET_PASSWORD_AFTER_FORGOT_SUCCESS:
      return {
        ...state,
        authLoading: false,
        forgotResetPasswordMsg: action.payload,
      };
    case UserActionTypes.RESET_PASSWORD_AFTER_FORGOT_FAILURE:
      return {
        ...state,
        authLoading: false,
        error: action.payload,
      };
    case UserActionTypes.USER_PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        isProfileUpdateLoading: true,
      };
    case UserActionTypes.USER_PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        userProfileDataUpdateMsg: action.payload,
        //  forgotResetPasswordMsg:action.payload
      };
    case UserActionTypes.USER_PROFILE_IMAGE_UPLOAD_SUCCESS:
    return {
      ...state,
      imageUploadMsg:action.payload,
    }  
    case UserActionTypes.USER_PROFILE_IMAGE_UPLOAD_FAILURE:
    return {
      ...state,
    error: action.payload
    }    
    case UserActionTypes.USER_PROFILE_IMAGE_UPLOAD_MESSAGE_CLEAR: 
    return {
    ...state,   
    imageUploadMsg:null
    }
    case UserActionTypes.USER_PROFILE_UPDATE_FAILURE:
      return {
        ...state,
        isProfileUpdateLoading: false,
        error: action.payload,
      };
    case UserActionTypes.USER_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        isProfileUpdateLoading: false,
        currentUser: action.payload,
      };
      case UserActionTypes.USER_PROFILE_DATA_UPDATE_THROUGH_PROFILE:
      return {
      ...state,
      currentUser: action.payload,
      };
          
    // case UserActionTypes.USER_PROFILE_IMAGE_UPLOAD_REQUEST:
    // return {
    // ...state,
    // isProfileUpdateLoading: false,
    // };

    case UserActionTypes.STATE_CLEAR_AFTER_TASK:
      return {
        ...state,
        error: null,
        signUpError: null,
        signUpMessage: null,
        emailVerifacation: null,
        forgotResetPasswordMsg: null,
        userProfileDataUpdateMsg: null,
        validateUserEmail: null
      };

    /***** Save checkout guest User Form Data   ***/
    case UserActionTypes.SAVE_GUEST_USER_CHECKOUT_DATA:
      return {
        ...state,
        saveGuestUserCheckoutData: action.payload,
      };

    /************ VALIDATE USER EMAIL  *************** */
    case UserActionTypes.VALIDATE_USER_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UserActionTypes.VALIDATE_USER_EMAIL_SUCCESS:
      return {
        ...state,
        validateUserEmail: action.payload,
        loading: false,
      };
    case UserActionTypes.VALIDATE_USER_EMAIL_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
      case UserActionTypes.VALIDATE_EMAIL_STATE_CLEAR_AFTER_TASK:
        return {
          ...state,
          error: null,
          loading: false,
          validateUserEmail: null
        };

    default:
      return state;
  }
};

export default userReducer;
