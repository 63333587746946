import { createSelector } from "reselect";

const selectHotel = (state) => state.hotel;

export const selectHotelData = createSelector(
  [selectHotel],
  (hotel) => hotel.hotelData
);

export const selectHotelDetailsLoading = createSelector(
[selectHotel],
(hotel) => hotel.hoteldetailsLoading
);

export const selectHotelDataList = createSelector(
  [selectHotel],
  (hotel) => hotel.hotelListData
);

export const hotelListDataLoading = createSelector(
  [selectHotel],
  (hotel) => hotel.hotelListDataLoading
);

export const selectHotelAminityData = createSelector(
  [selectHotel],
  (hotel) => hotel.hotelAminityData
);

export const selectFavouriteHotel = createSelector(
  [selectHotel],
  (hotel) => hotel.favouriteHotel
);

export const selectFavouriteHotelList = createSelector(
  [selectHotel],
  (hotel) => hotel.favouriteHotelListData
);

export const selectFavouriteHotelSearchData = createSelector(
  [selectHotel],
  (hotel) => hotel.hotelSearchData
);

export const handleSearchRequestData = createSelector(
  [selectHotel],
  (hotel) => hotel.hotelsearchhandle
);

export const searchFilterData = createSelector(
  [selectHotel],
  (hotel) => hotel.hotelFilterSearch
);

/********* TOTAL HOTEL PAGE COUNT ************/
export const TotalHotelPageCount = createSelector(
  [selectHotel],
  (hotel) => hotel.totalHotelCount
);
/********* TOTAL HOTEL PAGE COUNT *********/

/******* Filter Add Or Not  *********/
export const searchFilterAddOrNot = createSelector(
  [selectHotel],
  (hotel) => hotel.filteraddornot
);

/******* Filter Add Or Not  *********/

/************ Bid Checkout Details     *************** */
export const selectBidCheckoutDetails = createSelector(
  [selectHotel],
  (hotel) => hotel.bidCheckoutDetails
);

/************ Hourly Checkout Details     *************** */
export const selectHourlyCheckoutDetails = createSelector(
  [selectHotel],
  (hotel) => hotel.hourlyCheckoutDetails
);

/************ Hotel Booking     *************** */
export const selectHotelBooking = createSelector(
  [selectHotel],
  (hotel) => hotel.hotelBooking
);



/********* HOTEL Weather API ***********/
export const selectHotelWhetherData = createSelector(
  [selectHotel],
  (hotel) => hotel.hotelWhetherData
);
/********* HOTEL Weather API ***********/

export const selectHotelBookingHistoryLoading = createSelector(
  [selectHotel],
  (hotel) => hotel.hotelUserBookingListLoading
);

export const selectHotelBookingHistoryData = createSelector(
  [selectHotel],
  (hotel) => hotel.hotelUserBookingListData
);
/************ Apply Wallet     *************** */
export const selectApplyWallet = createSelector(
  [selectHotel],
  (hotel) => hotel.applyWallet
);

/************ Apply Promo Code     *************** */
export const selectApplyPromoCode = createSelector(
  [selectHotel],
  (hotel) => hotel.applyPromoCode
);

/************ Bid Your Amount     *************** */
export const selectBidYourAmount = createSelector(
  [selectHotel],
  (hotel) => hotel.bidYourAmount
);

export const selectDataLoading = createSelector(
  [selectHotel],
  (hotel) => hotel.loading
);

/************ Apply Hotel Offerst     *************** */
export const selectApplyHotelOffers = createSelector(
  [selectHotel],
  (hotel) => hotel.applyHotelOffers
);

export const selectApplyHotelOffersFailedData = createSelector(
  [selectHotel],
  (hotel) => hotel.applyHotelOffersFailedData
);

/****** Hotel Search ******/
export const selectHotelCity = createSelector(
  [selectHotel],
  (hotel) => hotel.hotelplacedata
);

/***** Save checkout guest User Form Data   ***/
export const selectSaveGuestUserCheckoutData = createSelector(
  [selectHotel],
  (hotel) => hotel.saveGuestUserCheckoutData
);

/************ HOTEL RE-SEND CONFIRMATION  ****************/

export const selectHotelResendConfirmationData = createSelector(
  [selectHotel],
  (hotel) => hotel.hotelResendConfirmation
);

/************ HOTEL Listing Bid Now and Hotel Deatils Route Handle ****************/
export const selectHotelBidNowPageRouteData = createSelector(
  [selectHotel],
  (hotel) => hotel.currentUrl
);
/************ HOTEL Listing Bid Now and Hotel Deatils Route Handle ****************/

/************ HOTEL Listing Bid Now and Hotel Deatils Route Handle ****************/
export const selectHotelDetailsPageRouteData = createSelector(
  [selectHotel],
  (hotel) => hotel.pastUrl
);

export const selectPlaceDataHandleData = createSelector(
[selectHotel],
(hotel) => hotel.placeHandle  
);
/************ HOTEL Listing Bid Now and Hotel Deatils Route Handle ****************/

/************ HOTEL Listing Bid Now PERFERENCE  ****************/
export const selectPreferenceData = createSelector(
[selectHotel],
(hotel) => hotel.preferenceList
);
/************ HOTEL Listing Bid Now PERFERENCE  ****************/

  /************ HOTEL Geo Location Uploaded  ****************/
  export const selectGeolocationData = createSelector(
  [selectHotel],
  (hotel) => hotel.geolocationadded
  );
  /************ HOTEL Geo Location Uploaded  ****************/


  /***** Hotel booking loader **/
  export const selectHotelBookingLoading = createSelector(
    [selectHotel],
    (hotel) => hotel.hotelBookingLoading
  );
/***** Apply Hotel Offers loader **/
export const selectApplyHotelOffersLoading = createSelector(
  [selectHotel],
  (hotel) => hotel.applyHotelOffersLoading
);

/******* Resend-Confirmation loader **** */
export const selectResendConfirmationLoading = createSelector(
  [selectHotel],
  (hotel) => hotel.resendConfirmationLoading
);

/*********** ADD Review Message **********/
export const selectHotelAddReviewMessae = createSelector(
[selectHotel],
(hotel) => hotel.afterAddReviewMessage
);  


/********* Booking Details  ************/
export const selectHotelBookingDetails = createSelector(
[selectHotel],
(hotel) => hotel.bookingdetails
);  


/******** Hotel cancelation Bidding ********/

export const selectHotelBookingCancellationData = createSelector(
[selectHotel],
(hotel) => hotel.cancelBidding
);


export const selectHotelStarRatingFilterStarSelect = createSelector(
[selectHotel],
(hotel) => hotel.fiterStar
);
