import { takeLatest, put, all, call } from "redux-saga/effects";
import axios from "axios";
import store from "./../store";

import UserAccountActionTypes from "./useraccount.types";
import {
  errorToast,
  successToast,
  warningToast,
} from "../../utils/toastHelper";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import {
  bidingGetDataSuccess,
  bidingGetDataFailure,
  addCareerSuccess,
  addCareerFailed,
  addPartnerSuccess,
  addPartnerFailed,
  getMyReferralsDetailsSuccess,
  getMyReferralsDetailsFailure,
  getMyReferralsListSuccess,
  getMyReferralsListFailure,
  getMyWalletPointsDataSuccess,
  getMyWalletPointsDataFailure,
  getMyWalletListDataSuccess,
  getMyWalletListDataFailure,
  buyWalletPointsSuccess,
  buyWalletPointsFailure,
  getAllLoyaltyPointsSuccess,
  getAllLoyaltyPointsFailure,
  getAllNotificationsSuccess,
  getAllNotificationsFailure,
  cancelBookingSuccess,
  cancelBookingFailure,
  addContactUsSuccess,
  addContactUsFailed,
} from "./useraccount.actions";
var MySwal = withReactContent(Swal);
/*** GET ALL BIDINGS SAGA ASYNC  **/
var backendUrlEndPoint = process.env.REACT_APP_BACKEND_API_ENDPOINT;
export function* getAllBidingDataAsync({ payload: data }) {
  try {
    const response = yield axios.get(
      `${backendUrlEndPoint}/biding-request/get-all?skip=${data.skip}&limit=${data.limit}`,

      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${data.languageToShow}`,
          Authorization:
            store.getState().user.currentUser != null
              ? "Bearer " + store.getState().user.currentUser.token + ""
              : "",
        },
      }
    );

    if (response.data.success === true) {
      yield put(bidingGetDataSuccess(response.data));
      // successToast(response.data.message);
    } else {
      yield put(bidingGetDataFailure(response.data));
      // errorToast(response.data.message);
    }
  } catch (error) {
    // console.log("Network Error", error);
  }
}

/***ADD CAREER SAGA ASYNC * */
export function* addCareerAsync({ payload: data }) {
  try {
    const careerResponse = yield axios.post(
      `${backendUrlEndPoint}/career/add/`,
      data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            mobile: true,
            "Accept-Language": `${store.getState().language.language}`,

          },
        }
      
    );
    if (careerResponse.data.success) {
      // successToast(careerResponse.data.message);
      yield put(addCareerSuccess(careerResponse.data));
    } else {
    
      MySwal.fire({
      confirmButtonText: 'OK',
      confirmButtonColor: '#296da9',
      text:careerResponse.data.message
      });         
     // errorToast(careerResponse.data.message);
      yield put(addCareerFailed(careerResponse.data));
    }
  } catch (error) {
    // console.log(error.message);
  }
}

/***ADD PARTNER SAGA ASYNC * */
export function* addPartnerAsync({ payload: data }) {
  try {
    const paertnerResponse = yield axios.post(
      `${backendUrlEndPoint}/partner/add/`,
      data,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${store.getState().language.language}`,

        },
      }
    );
    if (paertnerResponse.data.success) {
      // successToast(paertnerResponse.data.message);
      yield put(addPartnerSuccess(paertnerResponse.data));
    } else {
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
         text:paertnerResponse.data.message
        });  
    //  errorToast(paertnerResponse.data.message);
      yield put(addPartnerFailed(paertnerResponse.data));
    }
  } catch (error) {
    // console.log(error.message);
  }
}

/***GET WALLET POINTS SAGA ASYNC * */
export function* getMyWalletPointsAsync({ payload: data }) {
  try {
    const pointsResponse = yield axios.get(
      `${backendUrlEndPoint}/wallet/get-points`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${data.languageToShow}`,
          Authorization: data.token ? "Bearer " + data.token : "",
        },
      }
    );
    if (pointsResponse.data.success) {
      // successToast(pointsResponse.data.message);
      yield put(getMyWalletPointsDataSuccess(pointsResponse.data));
    } else {
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
         text:pointsResponse.data.message
        });  
     // errorToast(pointsResponse.data.message);
      yield put(getMyWalletPointsDataFailure(pointsResponse.data));
    }
  } catch (error) {
    // console.log(error.message);
  }
}

/***MY WALLET LIST SAGA ASYNC * */
export function* getMyWalletListAsync({ payload: data }) {
  try {
    const walletListResponse = yield axios.get(
      `${backendUrlEndPoint}/wallet/get-wallet`,
      // `${backendUrlEndPoint}/wallet/get-wallet?type=${data.type}`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${data.languageToShow}`,
          Authorization: data.token ? "Bearer " + data.token : "",
        },
        params: {
          type: data.type,
          skip: data.skip,
          limit: data.limit,
        },
      }
    );
    if (walletListResponse.data.success) {
      // successToast(walletListResponse.data.message);
      yield put(getMyWalletListDataSuccess(walletListResponse.data));
    } else {
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
         text:walletListResponse.data.message
        });  
     // errorToast(walletListResponse.data.message);
      yield put(getMyWalletListDataFailure(walletListResponse.data));
    }
  } catch (error) {
    // console.log(error.message);
  }
}

/***ALL LOYALTY POINTS SAGA ASYNC * */
export function* getAllLoyaltyPointsAsync({ payload: data }) {
  try {
    const loyaltyPointsResponse = yield axios.get(
      `${backendUrlEndPoint}/loyalty-point/get-all`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${data.languageToShow}`,
          Authorization: data.token ? "Bearer " + data.token : "",
        },
        params: {
          skip: data.skip,
          limit: data.limit,
        },
      }
    );
    if (loyaltyPointsResponse.data.success) {
      // successToast(loyaltyPointsResponse.data.message);
      yield put(getAllLoyaltyPointsSuccess(loyaltyPointsResponse.data));
    } else {
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
         text:loyaltyPointsResponse.data.message
        });  
   //   errorToast(loyaltyPointsResponse.data.message);
      yield put(getAllLoyaltyPointsFailure(loyaltyPointsResponse.data));
    }
  } catch (error) {
    // console.log(error.message);
  }
}

/***BUY WALLET POINTS SAGA ASYNC * */
export function* buyWalletPointsAsync({ payload: data }) {
  try {
    const buyPonitsResponse = yield axios.post(
      `${backendUrlEndPoint}/wallet/add/`,
      data.postData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${data.languageToShow}`,
          Authorization: data.token ? "Bearer " + data.token : "",
        },
      }
    );
    if (buyPonitsResponse.data.success) {
    //  successToast(buyPonitsResponse.data.message);
     yield put(buyWalletPointsSuccess(buyPonitsResponse.data));
    } else {
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
         text:buyPonitsResponse.data.message
        });  
   //   errorToast(buyPonitsResponse.data.message);
      yield put(buyWalletPointsFailure(buyPonitsResponse.data));
    }
  } catch (error) {
    // console.log(error.message);
  }
}

/***MY Referrals Details SAGA ASYNC * */
export function* getMyReferralsDetailsAsync({ payload: data }) {
  try {
    const referralsDetailsResponse = yield axios.get(
      `${backendUrlEndPoint}/user/get-referral-details`,

      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${data.languageToShow}`,
          Authorization: data.token ? "Bearer " + data.token : "",
        },
      }
    );

    if (referralsDetailsResponse.data.success) {
      // successToast(referralsDetailsResponse.data.message);
      yield put(getMyReferralsDetailsSuccess(referralsDetailsResponse.data));
    } else {
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
         text:referralsDetailsResponse.data.message
        });  
    //  errorToast(referralsDetailsResponse.data.message);
      yield put(getMyReferralsDetailsFailure(referralsDetailsResponse.data));
    }
  } catch (error) {
    // console.log(error.message);
  }
}

/***MY Referrals List SAGA ASYNC * */
export function* getMyReferralsListAsync({ payload: data }) {
  try {
    const referralsListResponse = yield axios.get(
      // "http://demoyourprojects.com:5083/api/v1/user/get-referred-list",
      `${backendUrlEndPoint}/user/get-referral`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${data.languageToShow}`,
          Authorization: data.token ? "Bearer " + data.token : "",
        },
        params: {
          skip: data.skip,
          limit: data.limit,
        },
      }
    );

    if (referralsListResponse.data.success) {
      // successToast(referralsListResponse.data.message);
      yield put(getMyReferralsListSuccess(referralsListResponse.data));
    } else {
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
         text:referralsListResponse.data.message
        });  
    //  errorToast(referralsListResponse.data.message);
      yield put(getMyReferralsListFailure(referralsListResponse.data));
    }
  } catch (error) {
    // console.log(error.message);
  }
}

/***ALL Notifications SAGA ASYNC * */
export function* getAllNotificationsAsync({ payload: data }) {
  try {
    const notificationsResponse = yield axios.get(
      `${backendUrlEndPoint}/notification/get-all`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${data.languageToShow}`,
          Authorization: data.token ? "Bearer " + data.token : "",
        },
        params: {
          skip: data.skip,
          limit: data.limit,
        },
      }
    );
    if (notificationsResponse.data.success) {
      // successToast(notificationsResponse.data.message);
      yield put(getAllNotificationsSuccess(notificationsResponse.data));
    } else {
      // errorToast(notificationsResponse.data.message);
      yield put(getAllNotificationsFailure(notificationsResponse.data));
    }
  } catch (error) {
    // console.log(error.message);
  }
}

/************ CANCEL BOOKING  *************** */
export function* cancelBookingAsync({ payload: data }) {
  try {
    const response = yield axios.post(
      `${backendUrlEndPoint}/booking/update/`,
      data.postData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${data.languageToShow}`,
          Authorization:
            store.getState().user.currentUser != null
              ? "Bearer " + store.getState().user.currentUser.token + ""
              : "",
        },
      }
    );

    if (response.data.success === true) {
      yield put(cancelBookingSuccess(response.data));
     // successToast(response.data.message);
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
         text:response.data.message
        });  
    } else {
      yield put(cancelBookingFailure(response.data));
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
         text:response.data.message
        });  
     // errorToast(response.data.message);
    }
  } catch (error) {
    // console.log("Network Error", error);
  }
}

/***ADD CONTACT SAGA ASYNC * */
export function* addContactUsAsync({ payload: data }) {
  try {
    const response = yield axios.post(
      `${backendUrlEndPoint}/contact/add/`,
      data,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${store.getState().language.language}`,
        
        },
      
      }
     
    );
    if (response.data.success) {
      // successToast(response.data.message);
      yield put(addContactUsSuccess(response.data));
    } else {
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
         text:response.data.message
        });  
   //  errorToast(response.data.message);
      yield put(addContactUsFailed(response.data));
    }
  } catch (error) {
    // console.log(error.message);
  }
}

export function* UserProfileDataFetchAsync({ payload: data }) {}

/*** GET ALL BIDDINGS Fetch  **/
export function* userAccountBidingDataFetchStart() {
  yield takeLatest(
    UserAccountActionTypes.BIDING_GET_DATA_REQUEST,
    getAllBidingDataAsync
  );
}

/***ADD CAREER Fetch   * */
export function* addCareerFetchStart() {
  yield takeLatest(UserAccountActionTypes.ADD_CAREER_REQUEST, addCareerAsync);
}

/***ADD PARTNER Fetch * */
export function* addPartnerFetchStart() {
  yield takeLatest(UserAccountActionTypes.ADD_PARTNER_REQUEST, addPartnerAsync);
}

/***GET WALLET POINTS Fetch * */
export function* getMyWalletPointsFetchStart() {
  yield takeLatest(
    UserAccountActionTypes.GET_MY_WALLET_POINTS_REQUEST,
    getMyWalletPointsAsync
  );
}

/***MY WALLET LIST Fetch * */
export function* getMyWalletListFetchStart() {
  yield takeLatest(
    UserAccountActionTypes.GET_MY_WALLET_LIST_REQUEST,
    getMyWalletListAsync
  );
}

/***ALL LOYALTY POINTS Fetch * */
export function* getAllLoyaltyPointsFetchStart() {
  yield takeLatest(
    UserAccountActionTypes.GET_ALL_LOYALTY_POINTS_REQUEST,
    getAllLoyaltyPointsAsync
  );
}

/***BUY WALLET POINTS Fetch * */
export function* buyWalletPointsFetchStart() {
  yield takeLatest(
    UserAccountActionTypes.BUY_WALLET_POINTS_REQUEST,
    buyWalletPointsAsync
  );
}

/***MY Referrals Details Fetch * */
export function* getMyReferralsDetailsFetchStart() {
  yield takeLatest(
    UserAccountActionTypes.GET_MY_WALLET_POINTS_REQUEST,
    getMyReferralsDetailsAsync
  );
}

/***MY Referrals List Fetch * */
export function* getMyReferralsListFetchStart() {
  yield takeLatest(
    UserAccountActionTypes.GET_MY_REFERRALS_LIST_REQUEST,
    getMyReferralsListAsync
  );
}

/***All Notifications  Fetch * */
export function* getAllNotificationsFetchStart() {
  yield takeLatest(
    UserAccountActionTypes.GET_ALL_NOTIFICATIONS_REQUEST,
    getAllNotificationsAsync
  );
}

/*** CANCEL BOOKING  Fetch * */
export function* cancelBookingFetchStart() {
  yield takeLatest(
    UserAccountActionTypes.CANCEL_BOOKING_REQUEST,
    cancelBookingAsync
  );
}
export function* onProfileDataFetchingSTart() {
  yield takeLatest(
    UserAccountActionTypes.SET_MY_PROFILE_CURRENT_USER_DATA_START,
    UserProfileDataFetchAsync
  );
}

/** Contact-us Fetch** */
export function* addContactUsFetchStart() {
  yield takeLatest(
    UserAccountActionTypes.ADD_CONTACT_US_REQUEST,
    addContactUsAsync
  );
}

export function* useraccountSagas() {
  yield all([
    call(userAccountBidingDataFetchStart),
    call(onProfileDataFetchingSTart),
    call(addCareerFetchStart),
    call(addPartnerFetchStart),
    call(getMyReferralsDetailsFetchStart),
    call(getMyReferralsListFetchStart),
    call(getMyReferralsDetailsFetchStart),
    call(getMyWalletListFetchStart),
    call(buyWalletPointsFetchStart),
    call(getMyWalletPointsFetchStart),
    call(getAllLoyaltyPointsFetchStart),
    call(getAllNotificationsFetchStart),
    call(cancelBookingFetchStart),
    call(addContactUsFetchStart),
  ]);
}
