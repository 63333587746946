import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/main.style.css";
import "./assets/css/responsive.syle.css";
import "./assets/css/main-arabic.style.css";
import "./assets/css/responsive-arabic.syle.css";
import "./assets/css/country-code.style.css";
import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/material.css";
import "./assets/css/loader.style.css";
import HomePage from "./pages/home/home";
import LoginPage from "./pages/Login/Login";
import LoginRegistration from "./testing/Login-registration";
import AboutPage from "./pages/Aboutus/Aboutus";
import MyprofilePage from "./pages/Myaccount/Myprofile";
import MybookingPage from "./pages/Myaccount/Mybooking";
import MybiddingsPage from "./pages/Myaccount/Mybiddings";
import MypointsPage from "./pages/Myaccount/Mypoints";
import EmailVerification from "./pages/emailVerification/emailVerification";
import MywalletPage from "./pages/Myaccount/Mywallet";
import MyreferralsPage from "./pages/Myaccount/Myreferrals";
import MynotificationPage from "./pages/Myaccount/Mynotification";
import MyfavoritesPage from "./pages/Myaccount/Myfavorites";
import ListingbidnowPage from "./pages/Listing-bid-now/Listingbidnow";
import ListingdetailsPage from "./pages/Listing-details/Listingdetails";
import BidGuestUser from "./pages/checkout/Checkout-day-book-bid-guest-user";
import HourlyGuestUser from "./pages/checkout/Checkout-day-book-hourly-guest-user";
import { Link, Route, Routes } from "react-router-dom";
import TermsAndConditionPage from "./pages/terms-conditions/terms-condition";
import Header from "./components/Layouts/Header/Header";
import Footer from "./components/Layouts/Footer/Footer";
import FaqPage from "./pages/Faq/faq";
import ContactPage from "./pages/Contactus/Contactus";
import CareerPage from "./pages/Career/Career";
import PartnersWithWfrleePage from "./pages/PartnersWithWfrlee/PartnersWithWfrlee";
import ForgotPassword from "./pages/forgot-password/forgot-pasword";
import ForgotPasswordPage from "./pages/forgot-reset-password/forgot-reset-password";
import {
  selectUserLoginData,
  selectSocialLoginUser,
} from "./redux/user/user.selectors";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectlanguageToShow } from "./redux/language/language.selectors";
import AuthGuard from "./guards/AuthGuard";
import NotFoundScreen from "./pages/NotFoundScreen";
import OffersPage from "./pages/Offers/OffersPage";
import PaymentGatewayDemo from "./pages/payment-gateway-demo/PaymentGatewayDemo";
import OrderConfirmedPage from "./pages/orderConfirmed/OrderConfirmed";
import BiddingFailed from "./pages/Bidding-failed/biddingFailed";
import BookingFailed from "./pages/BookingFailed";
import RatingAndReview from "./pages/ratingandReview/rating-and-review";
import ChatBot from "./components/chat/index";

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-5GGQ8Z2X', // Replace GTM-XXXXXX with your GTM container ID
};

TagManager.initialize(tagManagerArgs);

function App() {


  return (
    <>
      <div id="23333244">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/testing" element={<LoginRegistration />} />
          <Route path="/terms&conditions" element={<TermsAndConditionPage />} />
          <Route path="/privacy-policy" element={<TermsAndConditionPage />} />
          <Route path="/safety-security" element={<TermsAndConditionPage />} />
          <Route path="/referral" element={<TermsAndConditionPage />} />
          <Route path="/rating-and-review" element={<RatingAndReview />} />

          <Route
            path="/secure-panel/email-verification/:token"
            element={<EmailVerification />}
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />

          <Route
            path="/myaccount"
            element={
              <AuthGuard>
                <MyprofilePage />
              </AuthGuard>
            }
          />
          <Route
            path="/mybooking"
            element={
              <AuthGuard>
                <MybookingPage />
              </AuthGuard>
            }
          />
          <Route
            path="/mybidding"
            element={
              <AuthGuard>
                <MybiddingsPage />
              </AuthGuard>
            }
          />
          <Route
            path="/mypoints"
            element={
              <AuthGuard>
                <MypointsPage />
              </AuthGuard>
            }
          />
          <Route
            path="/mywallet"
            element={
              <AuthGuard>
                <MywalletPage />
              </AuthGuard>
            }
          />
          <Route
            path="/myreferrals"
            element={
              <AuthGuard>
                <MyreferralsPage />
              </AuthGuard>
            }
          />
          <Route
            path="/favorites"
            element={
              <AuthGuard>
                <MyfavoritesPage />
              </AuthGuard>
            }
          />
          <Route
            path="/notification"
            element={
              <AuthGuard>
                <MynotificationPage />
              </AuthGuard>
            }
          />
          <Route path="/listingbidnow" element={<ListingbidnowPage />} />
          <Route path="/hotel-details/:slug" element={<ListingdetailsPage />} />
          <Route path="/bidguestuser" element={<BidGuestUser />} />
          <Route path="/hourlyguestuser" element={<HourlyGuestUser />} />

          <Route
            path="/secure-panel/update-password/:token"
            element={<ForgotPasswordPage />}
          />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/sign-up" element={<LoginPage />} />
          <Route path="/sign-up/:refercode" element={<LoginPage />} />
          <Route path="/aboutus" element={<AboutPage />} />

          <Route path="/faq" element={<FaqPage />} />
          <Route path="/contactus" element={<ContactPage />} />
          <Route path="/career" element={<CareerPage />} />
          <Route
            path="/partnerswithwfrlee"
            element={<PartnersWithWfrleePage />}
          />
          <Route path="/offers" element={<OffersPage />} />


          <Route path="/*" element={<NotFoundScreen />} />
          <Route
            path="/point-purchase-failed"
            element={<BookingFailed />}
          />
          <Route path="/order-confirmed" element={<OrderConfirmedPage />} />
          <Route path="/order-failed" element={<OrderConfirmedPage />} />
          <Route path="/bidding-failed" element={<BiddingFailed />} />
        </Routes>

        <ChatBot />

        <Footer />
      </div>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  languageToShow: selectlanguageToShow,
  userAuthData: selectUserLoginData,
  userSocialAuthData: selectSocialLoginUser,
});

export default connect(mapStateToProps)(App);
