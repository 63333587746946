import { takeLatest , put , all , call } from 'redux-saga/effects';
import axios from 'axios';

import CmsActionTypes from './cms.types';

import {   cmsGetDataSuccess , cmsGetDataFailure  }  from "./cms.actions";
var backendUrlEndPoint = process.env.REACT_APP_BACKEND_API_ENDPOINT;
export function* cmsDataFetchAboutUs({ payload : data }) {
  try {
    const url = data.slug !== 'random-rating-review-list' ? `${backendUrlEndPoint}/cms-page/get/${data.slug}` : `${backendUrlEndPoint}/hotel/${data.slug}`;
  const cmsdata = yield axios.get(url,{
    headers: {
      'mobile': 'true',
      'Accept-Language':`${data.language}`
    }
  })
  yield put(cmsGetDataSuccess(cmsdata.data.data)) 
  } catch (error) {
    yield put(cmsGetDataFailure(error))  
  }
}


export function* onCmsDataFetchStart() {
yield takeLatest(CmsActionTypes.ABOUT_US_GET_DATA_START,cmsDataFetchAboutUs)
} 

export  function*  cmsSagas() {
yield  all([call(onCmsDataFetchStart)]) 
    
} 