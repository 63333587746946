import { createSelector } from "reselect";

const selectUser = (state) => state.user;

export const selectCurrentUser = createSelector(
  [selectUser],
  (user) => user.currentUser
);
export const selectSignupLoader = createSelector(
  [selectUser],
  (user) => user.signuploading
);

export const selectSignInError = createSelector(
  [selectUser],
  (user) => user.error
);

export const selectSignUpMessage = createSelector(
  [selectUser],
  (user) => user.signUpMessage
);

export const selectSinInLoading = createSelector(
  [selectUser],
  (user) => user.signinLoading
);

export const selectorForgotPasswordLoading = createSelector(
  [selectUser],
  (user) => user.forgotpasswordLoading
);

export const selectSocialLoginUser = createSelector(
  [selectUser],
  (user) => user.userData
);

export const selectEmailVerificationMessage = createSelector(
  [selectUser],
  (user) => user.emailVerifacation
);

export const selectUserLoginData = createSelector(
  [selectUser],
  (user) => user.currentUser
);
/** Reset password * */

export const selectUserResetPassword = createSelector(
    [selectUser],
    (user) => user.resetPasswordData
    );

export const selectUserForgotPasswordMsg = createSelector(
  [selectUser],
  (user) => user.forgotResetPasswordMsg
);

export const selectUserDataLoading = createSelector(
  [selectUser],
  (user) => user.authLoading
);

export const selectUserProfileLoading = createSelector(
  [selectUser],
  (user) => user.isProfileUpdateLoading
);

export const selectUserProfileMessage = createSelector(
  [selectUser],
  (user) => user.userProfileDataUpdateMsg
);

/***** Save checkout guest User Form Data   ***/

export const selectSaveGuestUserCheckoutData = createSelector(
  [selectUser],
  (user) => user.saveGuestUserCheckoutData
);

/***** VALIDATE USER EMAIL   ***/

export const selectValidateUserEmailData = createSelector(
  [selectUser],
  (user) => user.validateUserEmail
);

/*** Forgot Password Success ***/
export const selectForgotPasswordMessage = createSelector(
  [selectUser],
  (user) => user.forgotPasswordSuccessMsg
);

/*** Forgot Password Success ***/

/*** Image Upload Success Msg ***/
export const selectImageUploadMessage = createSelector(
  [selectUser],
  (user) => user.imageUploadMsg
);
/*** Image Upload Success Msg ***/

