import { Button, Form } from "react-bootstrap";
import React, { Suspense, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";
import TextField from "@mui/material/TextField";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { BsPlusCircle } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import BidNowMobileForm from "./BidNowMobileForm";
const AutocompleteReact = React.lazy(
  () => import("../rectInput-auto-complete/reactInput"),
);

const BidNowForm = ({
  longaddress,
  checkInOutValue,
  setCheckInOutValue,
  guestRoomData,
  handleIncrease,
  handleDecrease,
  setIsOpen,
  handleOpen,
  guestRomsPopup,
  guestAndRoom,
}) => {
  const [t] = useTranslation("common");

  const [checkoutTime, setCheckoutTime] = useState(false);
  const [checkIn, setCheckIn] = useState(false);
  const [guestRomsPopupsecond, setGuestRoomsPopupsecond] = useState(false);
  const ref1 = useRef(null);
  const datePickerRef = useRef(null);

  const guestAndRoomsecond = () => {
    setGuestRoomsPopupsecond((prev) => !prev);
  };

  const hendleDatePickercheckInClose = () => {
    setCheckIn(false);
    setCheckoutTime(true);
  };

  if (window.innerWidth <= 768) {
    return (
      <BidNowMobileForm
        setCheckInOutValue={setCheckInOutValue}
        setIsOpen={setIsOpen}
        handleOpen={handleOpen}
        checkInOutValue={checkInOutValue}
        guestRoomData={guestRoomData}
        handleIncrease={handleIncrease}
        handleDecrease={handleDecrease}
        guestRomsPopup={guestRomsPopup}
        guestAndRoom={guestAndRoom}
      />
    );
  }

  return (
    <div className="left">
      <Form.Label className={`${longaddress === "" ? "add-width" : ""}`}>
        {longaddress === "" ? `${t("home.search.WHERE")}` : null}
      </Form.Label>
      <Form.Group className="form-box location-drop">
        <Suspense fallback={<div>{t("home.search.Loading")}</div>}>
          <AutocompleteReact />
        </Suspense>
      </Form.Group>
      <div className="bidchecking">
        <Form.Group className="form-box ">
          {!isMobile ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                ref={datePickerRef}
                className="datepickercheck-in check-in"
                label={`${t("home.search.CheckinLabel")}`}
                value={checkInOutValue.checkInTime}
                onChange={(newValue) => {
                  // setValue();
                  moment(new Date(newValue)).format("YYYY-MM-DD HH:mm:ss");
                  setCheckInOutValue((checkInOutValue) => ({
                    ...checkInOutValue,
                    checkInTime: newValue,
                  }));
                }}
                disablePast
                renderInput={(params) => <TextField {...params} />}
                onClose={() => {
                  hendleDatePickercheckInClose();
                }}
                KeyboardButtonProps={{
                  onClick: (e) => {
                    setCheckIn(true);
                  },
                }}
                InputProps={{
                  onClick: () => {
                    setCheckIn(true);
                  },
                }}
                open={checkIn}
                inputFormat="DD MMM YYYY"
                disableMaskedInput={true}
              />
            </LocalizationProvider>
          ) : (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                className="datepickercheck-in check-in"
                label={`${t("home.search.CheckinLabel")}`}
                value={checkInOutValue.checkInTime}
                onChange={(newValue) => {
                  // setValue();
                  moment(new Date(newValue)).format("YYYY-MM-DD HH:mm:ss");
                  setCheckInOutValue((checkInOutValue) => ({
                    ...checkInOutValue,
                    checkInTime: newValue,
                  }));
                }}
                disablePast
                renderInput={(params) => <TextField {...params} />}
                inputFormat="DD MMM YYYY"
                disableMaskedInput={true}
              />
            </LocalizationProvider>
          )}
        </Form.Group>
        <Form.Group className="form-box check-out">
          {!isMobile ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="datepickercheck-in"
                label={`${t("home.search.CheckoutLabel")}`}
                value={checkInOutValue.checkOutTime}
                onChange={(newValue) => {
                  //setValue(moment(newValue).format("DD/MM/YYYY"));
                  setCheckInOutValue((checkInOutValue) => ({
                    ...checkInOutValue,
                    checkOutTime: newValue,
                  }));
                }}
                renderInput={(params) => <TextField {...params} />}
                onClose={() => setCheckoutTime(false)}
                KeyboardButtonProps={{
                  onClick: (e) => {
                    setCheckoutTime(true);
                  },
                }}
                minDate={new Date(checkInOutValue.checkInTime).setDate(
                  new Date(checkInOutValue.checkInTime).getDate() + 1,
                )}
                disablePast
                InputProps={{
                  onClick: () => {
                    setCheckoutTime(true);
                  },
                }}
                open={checkoutTime}
                inputFormat="DD MMM YYYY"
                disableMaskedInput={true}
              />
            </LocalizationProvider>
          ) : (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                className="datepickercheck-in"
                label={`${t("home.search.CheckoutLabel")}`}
                value={checkInOutValue.checkOutTime}
                onChange={(newValue) => {
                  //setValue(moment(newValue).format("DD/MM/YYYY"));
                  setCheckInOutValue((checkInOutValue) => ({
                    ...checkInOutValue,
                    checkOutTime: newValue,
                  }));
                }}
                renderInput={(params) => <TextField {...params} />}
                disablePast
                minDate={new Date(checkInOutValue.checkInTime).setDate(
                  new Date(checkInOutValue.checkInTime).getDate() + 1,
                )}
                inputFormat="DD MMM YYYY"
                disableMaskedInput={true}
              />
            </LocalizationProvider>
          )}
        </Form.Group>
      </div>
      <Form.Group className="form-box pop-form">
        <Form.Control
          type="text"
          className="room"
          placeholder={`${guestRoomData.adults + guestRoomData.child} ${
            guestRoomData.adults + guestRoomData.child > 1
              ? t("home.search.Guests")
              : t("home.search.Guest")
          } / ${guestRoomData.rooms} ${
            guestRoomData.rooms > 1
              ? t("home.search.Rooms")
              : t("home.search.Room")
          }`}
          onClick={guestAndRoomsecond}
        />
        <div
          className="guestpop"
          ref={ref1}
          style={
            guestRomsPopupsecond ? { display: "block" } : { display: "none" }
          }
        >
          <h3>
            {/* Add Guest(s) And Room(s) */}
            {t("home.search.AddGuest(s)andRoom(s)")}
          </h3>
          <ul>
            <li>
              <h4>
                {/* Room (s) */}
                {t("home.search.Room(s)")}
              </h4>
              <div className="counting">
                <div>
                  <Button
                    onClick={() => {
                      handleDecrease("room");
                    }}
                  >
                    <AiOutlineMinusCircle />
                  </Button>
                </div>
                {guestRoomData.rooms}
                <div>
                  <Button
                    onClick={() => {
                      handleIncrease("room");
                    }}
                  >
                    <BsPlusCircle />
                  </Button>
                </div>
              </div>
            </li>
            <li>
              <h4>
                {/* Adult (s)  */}
                {t("home.search.Adult(s)")}
              </h4>
              <div className="counting">
                <Button
                  onClick={() => {
                    handleDecrease("adult");
                  }}
                >
                  <AiOutlineMinusCircle />
                </Button>
                {guestRoomData.adults}
                <Button
                  onClick={() => {
                    handleIncrease("adult");
                  }}
                >
                  <BsPlusCircle />
                </Button>
              </div>
            </li>
            <li>
              <h4>
                {/* Children (s)  */}
                {t("home.search.Children(s)")}

                <span>
                  {/* Max 11 years */}
                  {t("home.search.MaxYears11")}
                </span>
              </h4>
              <div className="counting">
                <Button
                  onClick={() => {
                    handleDecrease("children");
                  }}
                >
                  <AiOutlineMinusCircle />
                </Button>
                {guestRoomData.child}
                <Button
                  onClick={() => {
                    handleIncrease("children");
                  }}
                >
                  <BsPlusCircle />
                </Button>
              </div>
            </li>
          </ul>
        </div>
      </Form.Group>
    </div>
  );
};

export default BidNowForm;
