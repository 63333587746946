import  CmsActionTypes  from './cms.types';
const INITIAL_STATE = {
cmsData:null,
cmsError:null,
loading:false
};

const cmsReducer = ( state = INITIAL_STATE , action ) =>  {
switch(action.type) {
case CmsActionTypes.ABOUT_US_GET_DATA_START: 
return {
 ...state, 
 loading:true
};
case CmsActionTypes.ABOUT_US_GET_DATA_SUCCESS: 
return {
...state,   
cmsData:action.payload
}
case CmsActionTypes.ABOUT_US_GET_DATA_FAILURE:
return {
...state,   
cmsError:action.payload   
}
default: 
return state;
} 


}

export default cmsReducer;