import React, { useEffect } from "react";
import { Button, Dropdown, Form, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FiLogIn } from "react-icons/fi";
import { BiUserCircle } from "react-icons/bi";
import { GrFacebookOption } from "react-icons/gr";
import { GrTwitter } from "react-icons/gr";
import { GrLinkedinOption } from "react-icons/gr";
import { GrInstagram } from "react-icons/gr";
import { GrYoutube } from "react-icons/gr";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import ForgotPassword from "./../../components/forot-password/forgotpassword";
import SocialLogin from "../../components/SocialLogin/SocialLogin";
import { useSelector } from "react-redux";
import profilePic from "../../assets/images/ProfilePic.png";
import Footer from "../../components/Layouts/Footer/Footer";
import { createStructuredSelector } from "reselect";
import { ForgotPasswordReset } from "./../../redux/user/user.actions";
import { selectSocialLoginUser, selectForgotPasswordMessage } from "./../../redux/user/user.selectors";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";



const ForgotPasswordPage = ({ userData, forgotpasswordmsg, ForgotPasswordReset }) => {
  const stateData = useSelector((state) => state.userReducer);
  const navigate = useNavigate();
  const movetoLogin = () => { navigate("/login"); }

  useEffect(() => {
    if (forgotpasswordmsg) {
      ForgotPasswordReset();
      setTimeout(movetoLogin, 1000);

    }
  }, [forgotpasswordmsg])

  return (
    <>

      {/* for SEO purpose */}
      <Helmet>
        <title>Reset Your Password - Wfrlee Account Recovery</title>
        <meta name="description" content="Forgot your Wfrlee password? Quickly reset it here and regain access to your account securely and efficiently." />
      </Helmet>

      {/* Header Section sssss*/}
      {/* //////////loginSection/////////// */}
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="logtotal customform">

              <ForgotPassword />
              <p className="text-center">

              </p>



            </div>
          </div>
        </div>
      </div>

      {/* //////////Footer Section/////////// */}
      {/* <Footer /> */}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  userData: selectSocialLoginUser,
  forgotpasswordmsg: selectForgotPasswordMessage
});
const mapDispatchToProps = (dispatch) => ({
  ForgotPasswordReset: () => dispatch(ForgotPasswordReset()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
